import React, { useEffect, useState } from "react";

import axios from "../../../axios-instance";

import "./PartizanOpenSelling.sass";
import { seasonPassPersonalInfoLangs } from "../../../constants/seasonPassPersonalInfoLangs";

const renderSeatsIoTicket = (
  { currency, category: { label }, labels: { section, parent, own }, pricing },
  i
) => {
  const [area, side] = section.split("-");
  const price = pricing.price || null;

  return (
    <div key={i} className="partizan-open-selling__selected-tickets-item">
      <span className="partizan-open-selling__text-normal">
        {label} {area}, Row {parent} Seat {own} {() => side ? side : ''}
      </span>
      <span className="partizan-open-selling__text-bold">
        {price} {currency}
      </span>
    </div>
  );
};

const renderTicket = ({ name, price, currency, count, couponData }, i) => (
  <div key={i} className="partizan-open-selling__selected-tickets-item">
    <span className="partizan-open-selling__text-normal">{name}</span>
    <span className="partizan-open-selling__text-bold">
      {price * count -
        (couponData && i == 0
          ? (price * (count > 2 ? 2 : count) * couponData.discount) / 100
          : 0)}{" "}
      {currency}
    </span>
  </div>
);

export const SelectedTickets = ({
  tickets = [],
  setCouponHandler,
  coupon,
  currency,
  total,
  feeRate,
  hasSeats,
  mixedTickets,
  event,
  allowEnterCouponCode = true,
  autoHeight = false,
  pointsForDiscount = 0,
  langCode = 'en'
}) => {
  const txt = seasonPassPersonalInfoLangs[langCode];

  const [couponCode, setCouponCode] = useState(coupon ? coupon.barcode : "");
  const [couponData, setCouponData] = useState(coupon);

  useEffect(() => {
    console.log("coupon changed");
    setCouponData(coupon);
    setCouponCode(coupon ? coupon.barcode : "");
  }, [coupon]);

  const handleCouponChanges = async (e) => {
    // e.preventDefault();
    // debounce(setSearch(e.target.value.toUpperCase()), 500)// debounce 500 milliseconds
    // console.log(couponCode);
    // console.log(event);

    const code = e.target.value.toLowerCase();

    setCouponCode(code);

    try {
      var response = await axios.get(
        "/Transaction/couponDetails?couponCode=" +
        code +
        "&eventId=" +
        event.eventId,
        {}
      );

      if (response.data && response.data.isSuccess) {
        setCouponData(response.data.result);
        setCouponHandler(response.data.result);
      }
    } catch (ex) {
      console.log(ex);
    }
  };

  if (!tickets.length) {
    return (
      <div className="partizan-open-selling__empty-tickets">
        <img src="/images/img-empty-events.svg" alt="Select Ticket" />
        <p>
          {hasSeats
            ? "Select a ticket on the map"
            : "Add a ticket from the list"}
        </p>
      </div>
    );
  }

  const hasSeat = (ticket) => {
    return ticket['hasSeats'] === undefined;
  }

  const renderTickets = () => {

    if (mixedTickets) {

      return tickets.filter(t => hasSeat(t)).map((ticket, i) =>
        renderSeatsIoTicket({ ...ticket, currency }, i)
      ).concat(tickets.filter(t => !hasSeat(t)).map((ticket, i) =>
        renderTicket({ ...ticket, currency, couponData }, i)
      ));
    }

    switch (hasSeats) {
      case true:
        return tickets.map((ticket, i) =>
          renderSeatsIoTicket({ ...ticket, currency }, i)
        );
      case false:
        return tickets.map((ticket, i) =>
          renderTicket({ ...ticket, currency, couponData }, i)
        );
      default:
        return null;
    }
  };

  return (
    <>
      <div style={{ height: autoHeight ? 'auto' : '' }} className="partizan-open-selling__selected-tickets">
        <h4 className="partizan-open-selling__text-bold">{txt.SelectTicketTxt['tickets']}</h4>
        {renderTickets()}
      </div>

      <div className="partizan-open-selling__total">
        {feeRate > 0 && (
          <div>
            <span className="partizan-open-selling__text-bold-large">
              {txt.SelectTicketTxt['bookingFee']}:
            </span>
            <span className="partizan-open-selling__text-bold-large">
              {feeRate * 100}%
            </span>
          </div>
        )}

        {pointsForDiscount
          ? <div>
            <span className="partizan-open-selling__text-bold-large">{txt.SelectTicketTxt['usedPoints']}:</span>
            <span className="partizan-open-selling__text-bold-large">
              -{pointsForDiscount}
            </span>
          </div>
          : ''
        }

        <div>
          <span className="partizan-open-selling__text-bold-large">{txt.SelectTicketTxt['total']}:</span>
          <span className="partizan-open-selling__text-bold-large">
            {total} {currency}
          </span>
        </div>
      </div>
      {allowEnterCouponCode && (
        <div>
          <input
            className="seasonpass__field-input"
            placeholder="Enter coupon code"
            name="couponCode"
            onChange={(e) => handleCouponChanges(e)}
            type="text"
            value={couponCode}
            disabled={couponData}
          />
        </div>
      )}
    </>
  );
};
