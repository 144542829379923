import React, { Component, Fragment } from "react";
import axios from "axios";
import DatePicker from "react-datepicker/es";
import Select from "react-select";
import AsyncSelect from "react-select/lib/Async";
import "react-datepicker/dist/react-datepicker.css";
import ReactTable from "react-table";
import { connect } from "react-redux";
import withRouter from "react-router/es/withRouter";
import moment from "moment";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import { toast } from "react-toastify";

import axiosInstance from "../../../axios-instance";
import Container from "../../../components/UI/Container/Container";
import PageTitle from "../../../components/UI/PageTitle/PageTitle";
import MobileTicketsList from "../../../components/Events/MobileTicketsList";
import Icon from "../../../components/UI/Icon/Icon";
import ROUTES from "../../../constants/routes";
import { currencies } from "../../../constants/currencies";
import {
  clearCurrentEvent,
  deleteEvent,
  getEvent,
  resetEventsError,
  sendResalePurposes,
  updateEvent,
  updateHeaderImage,
  updateScannedTickets,
  getEmailTemplates,
} from "../../../store/actions/eventsActions";
import NewLoader from "../../../components/NewLoader/NewLoader";
import MobileHeader from "../../../components/MobileHeader/MobileHeader";
import Page404 from "../../BarCodes/BarCodes";
import { loadFile } from "../../../helpers/loadFile";
import TicketsImportContainer from "../../../components/TicketsImport/TicketsImportContainer";
import CheckBox from "../../../components/UI/Checkbox/CheckBox";
import { Counter } from "../../../components/Counter/Counter";
import PleaseWaitModal from "../../BarCodes/modals/PleaseWaitModal";
import Dropzone from "../../Upload/Dropzone/Dropzone";
import { getHeaders } from "../../../helpers/getHeaders";
import { executeAsyncWithModal } from "../../../helpers/modal";
import { salesAvailabilityOptions } from "../../../constants/salesAvailabilityOptions";
import { makeElementsReadonly } from "../../../helpers/html";
import { Breadcrumbs } from "../../../components/Breadcrumbs/Breadcrumbs";

import "./Event.sass";

const MySwal = withReactContent(Swal);

class Event extends Component {
  constructor(props) {
    super(props);

    this.state = {
      startDate: null,
      endDate: null,
      currentEvent: {
        eventEmails: {
          emailTicketTemplateId: 0,
          emailOfferTemplateId: 0,
          emailGuestTemplateId: 0,
          emailErrorTemplateId: 0,
          emailInvoiceTemplateId: 0,
        },
      },
      isDisabled: true,
      isFound: true,
      scannedTicketsStartDate: null,
      scannedTicketsEndDate: null,
      isImportActive: false,
      ticketToSync: null,
      qrCodeVisibilityHours: 0,
      ticketEmailValue: { value: 0, label: "Default" },
      offerEmailValue: { value: 0, label: "Default" },
      guestEmailValue: { value: 0, label: "Default" },
      errorEmailValue: { value: 0, label: "Default" },
      invoiceEmailValue: { value: 0, label: "Default" },
      allEmailTemplates: []
    };
  }

  static getDerivedStateFromProps(props, state) {
    if (
      props.currentEvent.id &&
      props.currentEvent.id !== state.currentEvent.id
    ) {
      const currentEvent = {
        ...props.currentEvent,
        salesAvailability: salesAvailabilityOptions.find(
          (o) => o.value === props.currentEvent.salesAvailability
        ),
        currency: currencies.find(
          (option) => option.value === props.currentEvent.currency
        ),
        startDate: props.currentEvent.startDate,
        endDate: props.currentEvent.endDate,
      };
      return {
        currentEvent,
      };
    }
    return null;
  }

  updateEmailTemplatesValues = () => {
    const eventEmails = this.state.currentEvent.eventEmails
    if (this.state.currentEvent && eventEmails) {
      this.setState({
        ticketEmailValue: {
          value: eventEmails.emailTicketTemplateId,
          label: eventEmails.emailTicketTemplateId > 0 ? this.state.allEmailTemplates.find(t => t.id === eventEmails.emailTicketTemplateId).name : 'Default'
        },
        offerEmailValue: {
          value: eventEmails.emailOfferTemplateId,
          label: eventEmails.emailOfferTemplateId > 0 ? this.state.allEmailTemplates.find(t => t.id === eventEmails.emailOfferTemplateId).name : 'Default'
        },
        guestEmailValue: {
          value: eventEmails.emailGuestTemplateId,
          label: eventEmails.emailGuestTemplateId > 0 ? this.state.allEmailTemplates.find(t => t.id === eventEmails.emailGuestTemplateId).name : 'Default'
        },
        errorEmailValue: {
          value: eventEmails.emailErrorTemplateId,
          label: eventEmails.emailErrorTemplateId > 0 ? this.state.allEmailTemplates.find(t => t.id === eventEmails.emailErrorTemplateId).name : 'Default'
        },

        invoiceEmailValue: {
          value: eventEmails.emailInvoiceTemplateId,
          label: eventEmails.emailInvoiceTemplateId > 0 ? this.state.allEmailTemplates.find(t => t.id === eventEmails.emailInvoiceTemplateId).name : 'Default'
        },
      });
    }
  };

  changeEventInfo = (event) => {
    const { name, value } = event.target;

    this.handleChangeEventField(name, value);
  };

  handleChange = (type, value) => {
    this.setState({ [type]: value });
    this.setState({
      isDisabled: false,
    });
  };

  handleChangeEventField = (name, value) => {
    const { ...currentEvent } = this.state.currentEvent;
    currentEvent[name] = value;

    if (this.props.error) {
      this.props.resetEventsError();
    }

    if (value === "") {
      this.setState({
        currentEvent,
        isDisabled: true,
      });
    } else {
      this.setState({
        currentEvent,
        isDisabled: false,
      });
    }
  };

  handleChangeDate = (name, value) => {
    const { ...currentEvent } = this.state.currentEvent;

    currentEvent[name] = value;

    this.setState({
      currentEvent,
      isDisabled: false,
    });
  };

  getEmailTemplates = (typeId) => {
    const user = JSON.parse(localStorage.getItem("user"));

    return () =>
      this.props.getEmailTemplates(typeId, user.token).then((data) => [
        { value: 0, label: "Default" },
        ...data.map(({ id, name }) => ({
          value: id,
          label: name
        })),
      ]);
  };

  handleChangeActivationTime = (value) => {
    const { ...currentEvent } = this.state.currentEvent;

    currentEvent.activationTime = value;

    this.setState({
      currentEvent,
      isDisabled: false,
    });
  };

  handleChangeCurrency = (value) => {
    const { ...currentEvent } = this.state.currentEvent;

    currentEvent.currency = value;

    this.setState({
      currentEvent,
      isDisabled: false,
    });
  };

  handleChangeSalesAvailability = (value) => {
    const { ...currentEvent } = this.state.currentEvent;

    currentEvent.salesAvailability = value;

    this.setState({
      currentEvent,
      isDisabled: false,
    });
  };

  goToUploadBarcodes = (data) => {
    this.props.history.push({
      pathname: ROUTES.ADD_BAR_CODE.replace(
        ":event_id",
        this.props.match.params.id
      ).replace(":id", data.original.id),
    });
  };

  goToAddTicket = () => {
    this.props.history.push({
      pathname: ROUTES.ADD_TICKET.replace(":id", this.props.match.params.id),
    });
  };

  goToUploadAvailableSeats = () => {
    this.props.history.push({
      pathname: ROUTES.UPLOAD_AVAILABLE_SEATS.replace(
        ":id",
        this.props.match.params.id
      ),
    });
  };
  goToTemolateEditor = () => {
    this.props.history.push({
      pathname: ROUTES.EVENT_TEMPLATE_EDITOR.replace(
        ":id",
        this.props.match.params.id
      ),
    });
  };

  goToSellPage = () => {
    this.props.history.push({
      pathname: ROUTES.SELL.replace(
        ":event_id",
        this.props.match.params.id
      ).replace(":id?", ""),
    });
  };

  componentDidMount() {
    this.props
      .getEvent(this.props.match.params.id, this.props.user.token)
      .then((result) => {
        if (!result.isFound) {
          this.setState({ isFound: false });
          this.updateEmailTemplatesValues();
        }
      });
    this.props
      .getEmailTemplates(null, this.props.user.token)
      .then((eTemplatesRes) => {
        this.setState({ allEmailTemplates: eTemplatesRes });
        this.updateEmailTemplatesValues();
      });

    // User can't enter text in Start and End date pickers
    makeElementsReadonly("react-datepicker__input-container");
  }

  componentDidUpdate(prevProps) {
    if (this.props.error && this.props.error !== prevProps.error) {
      toast.error(String(this.props.error));

      this.props.resetEventsError();
    }
  }

  // componentWillUnmount() {
  //   this.props.clearCurrentEvent();
  // }

  deleteEvent = () => {
    const { currentEvent } = this.state;
    const { deleteEvent } = this.props;

    MySwal.fire({
      title: "Are you sure that you want to delete this event?",
      text: "It's impossible to take step back then",
      showCancelButton: true,
      cancelButtonText: "Cancel",
      showConfirmButton: true,
      confirmButtonText: "Delete",
      reverseButtons: true,
      customClass: "warning-modal",
    }).then((result) => {
      if (result.value) {
        deleteEvent(this.props.user.token, currentEvent.id).then((result) => {
          if (result && result.isSuccess) {
            this.props.history.push(ROUTES.EVENTS);
          }
        });
      }
    });
  };

  goToTicketPage = (id, event) => {
    if (
      event.target.tagName.toLowerCase() !== "button" &&
      (event.target.getAttribute("class") === "event__list__item" ||
        event.target.getAttribute("class") === "event__list__item__title" ||
        event.target.getAttribute("class") === "rt-td")
    )
      this.props.history.push({
        pathname: ROUTES.TICKET_DETAIL.replace(":id", id).replace(
          ":event_id",
          this.props.match.params.id
        ),
      });
  };

  goToStatsPage = (id, event) => {
    this.props.history.push({
      pathname: ROUTES.STATS.replace(":id", id).replace(
        ":event_id",
        this.props.match.params.id
      ),
    });
  };

  goToTransactionsPage = (id, event) => {
    this.props.history.push({
      pathname: ROUTES.TRANSACTIONS.replace(":id", id).replace(
        ":event_id",
        this.props.match.params.id
      ),
    });
  };

  updateEvent = () => {
    const { updateEvent, clearCurrentEvent } = this.props;
    const { currentEvent, ticketEmailValue, offerEmailValue, guestEmailValue, errorEmailValue, invoiceEmailValue } = this.state;

    const updateSalesAvailabilityData = {
      salesAvailability: currentEvent.salesAvailability.value,
      activationTime: moment(currentEvent.activationTime)
        .utcOffset(0, true)
        .format(),
    };

    const data = {
      ...updateSalesAvailabilityData,
      slug: currentEvent.slug,
      isDeactivate: false,
      name: currentEvent.name,
      startDate: moment(currentEvent.startDate).utcOffset(0, true).format(),
      endDate: moment(currentEvent.endDate).utcOffset(0, true).format(),
      currency: currentEvent.currency.value,
      extraFeeRate: currentEvent.extraFeeRate,
      isCheckingCovid: currentEvent.isCheckingCovid,
      place: currentEvent.place,
      prefixLabel: currentEvent.prefixLabel,
      maxSeatsToSelectPerTransaction:
        currentEvent.maxSeatsToSelectPerTransaction,
      qrCodeVisibilityHours: currentEvent.qrCodeVisibilityHours,
      emailTicketTemplateId: ticketEmailValue.value,
      emailOfferTemplateId: offerEmailValue.value,
      emailGuestTemplateId: guestEmailValue.value,
      emailErrorTemplateId: errorEmailValue.value,
      emailInvoiceTemplateId: invoiceEmailValue.value,
    };

    if (data.name.length < 2 || data.name.length > 500) {
      MySwal.fire({
        title: "Error!",
        text: "The field Name must be a string with a minimum length of 2 and a maximum length of 500.",
        showConfirmButton: true,
        confirmButtonText: "Ok",
        type: "error",
        timer: 3000,
      });
    } else if (data.slug.length < 2 || data.slug.length > 30) {
      MySwal.fire({
        title: "Error!",
        text: "The field slug must be a string with a minimum length of 2 and a maximum length of 30.",
        showConfirmButton: true,
        confirmButtonText: "Ok",
        type: "error",
        timer: 3000,
      });
    } else {
      updateEvent(this.props.user.token, this.state.currentEvent.id, data);
    }
  };

  filterScannedTicketsByDate = (name, value) => {
    this.setState({ [name]: value }, () => {
      if (
        this.state.scannedTicketsStartDate &&
        this.state.scannedTicketsEndDate
      ) {
        this.props
          .updateScannedTickets(
            this.state.currentEvent.id,
            this.props.user.token,
            moment(this.state.scannedTicketsStartDate)
              .utcOffset(0, true)
              .format()
              .replace(/:/g, "%3A"),
            moment(this.state.scannedTicketsEndDate)
              .utcOffset(0, true)
              .format()
              .replace(/:/g, "%3A")
          )
          .then((result) => {
            this.setState({ currentEvent: result });
          });
      }
    });
  };

  downloadExcelReport = (type) => {
    let url = process.env.API_URL;
    const id = this.state.currentEvent.id;
    const name = this.state.currentEvent.name;
    let fileName = name.toLowerCase().replaceAll(" ", "_");
    let httpMethod = "GET";
    let ext = "csv";
    let body = undefined;

    switch (type) {
      case "tickets-report":
        httpMethod = "POST";
        fileName += "_tickets_report";
        url += `/EventReports/ByTicketTemplatesAtEvent?eventId=${id}&asCsv=true`;
        break;
      case "tickets-report2":
        fileName += "_tickets_report";
        url += `/Events/${id}/ByTicketTemplateReport`;
        break;
      case "transactions-report":
        fileName += "_transactions_report";
        url += `/Events/${id}/ByPaidTransactionsReport`;
        break;
      case "scanning-report":
        httpMethod = "POST";
        body = `{
                "fromTimeUtc": "${moment(this.state.currentEvent.startDate)
            .utcOffset(0, true)
            .add("hours", -12)
            .toISOString()}",
                "toTimeUtc": "${moment(this.state.currentEvent.endDate)
            .utcOffset(0, true)
            .add("hours", 12)
            .toISOString()}"
                }`;
        fileName += "_scan_report";
        url += `/EventReports/ByScanteamAtPeriod?asCsv=true`;
        break;
      default:
        fileName += "_scan_report";
        ext = "xls";
        url += `/ScanActions?eventId=${id}`;
        break;
    }

    axios({
      method: httpMethod,
      url,
      responseType: "arraybuffer",
      headers: {
        Authorization: `Bearer ${this.props.user.token}`,
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      data: body,
    })
      .then((response) => {
        loadFile(
          response.data,
          `${fileName}_${moment().format("MM_DD_HH_mm")}.${ext}`
        );
      })
      .catch((error) => console.log(error));
  };

  importTicketsTogler = (data = null) => {
    this.setState((prevState) => ({
      ticketToSync: data,
      isImportActive: !prevState.isImportActive,
    }));
  };

  handleUploadFile = (files) => {
    this.props.updateHeaderImage(
      this.props.user.token,
      this.state.currentEvent.id,
      files
    );
  };

  handleChangeMapMode = async () => {
    try {
      const { token } = JSON.parse(localStorage.getItem("user"));
      const { id } = this.props.currentEvent;

      const { status, data, error } = await axiosInstance.put(
        `/Events/${id}/SwitchMapMode`,
        null,
        {
          headers: getHeaders(token),
        }
      );

      if (status === 200 && Boolean(data.result)) {
        const { ...currentEvent } = this.state.currentEvent;

        currentEvent.isPublicMode = !currentEvent.isPublicMode;

        this.setState((prev) => ({
          ...prev,
          currentEvent,
        }));
      } else {
        throw new Error(error);
      }
    } catch (error) {
      console.log(error);
      toast.error(String(error));
    }
  };

  handleMigrateSeats = async () => {
    try {
      const { token } = JSON.parse(localStorage.getItem("user"));
      const { id } = this.props.currentEvent;

      const request = () =>
        axiosInstance.put(`/Events/${id}/MigrateSeats`, null, {
          headers: getHeaders(token),
        });

      const { status, data, error } = await executeAsyncWithModal(
        request,
        <PleaseWaitModal message="The migration from admin to public map in process..." />
      );

      if (status === 200 && Boolean(data.result)) {
        const { ...currentEvent } = this.state.currentEvent;
        currentEvent.isPublicMode = true;

        this.setState((prev) => ({
          ...prev,
          currentEvent,
        }));

        toast.success("Map in public mode now");
      } else {
        throw new Error(error);
      }
    } catch (error) {
      console.log(error);
      toast.error(String(error));
    }
  };

  sendResalePurposes = () => {
    const { id } = this.props.currentEvent;

    MySwal.fire({
      title: "Are you sure you want to send reselling offers?",
      text: "Users will receive resale offer emails",
      confirmButtonText: "Send",
      cancelButtonText: "Cancel",
      showCancelButton: true,
      showConfirmButton: true,
      reverseButtons: true,
      customClass: "warning-modal",
    }).then((result) => {
      if (result.value) {
        executeAsyncWithModal(
          () => this.props.sendResalePurposes(id),
          <PleaseWaitModal message="Preparing sending reselling offers...." />
        );
      }
    });
  };

  render() {
    // const { scannedTicketsStartDate, scannedTicketsEndDate } = this.state;

    if (!this.state.isFound) {
      return <Page404 />;
    }

    const customSelectStyles = {
      control: (base) => ({
        ...base,
        height: "46px",
        "min-height": "46px",
        cursor: "pointer",
      }),
    };

    const columns = [
      {
        Header: "Name",
        accessor: "ticketName",
        Cell: (data) => <>{data.original.ticketName}</>,
        width: 196,
      },
      {
        Header: "Scanned",
        // Header: () => (
        //   <div>
        //     <span>Scanned tickets</span>
        //     <div className="date-wrapper">
        //       <div className="date-wrapper__input-wrapper">
        //         <Icon name="events" />
        //         <DatePicker
        //           placeholderText="Start date"
        //           selected={scannedTicketsStartDate}
        //           onChange={(value) =>
        //             this.filterScannedTicketsByDate(
        //               "scannedTicketsStartDate",
        //               value
        //             )
        //           }
        //           showTimeSelect
        //           timeFormat="HH:mm"
        //           timeIntervals={15}
        //           dateFormat="h:mm a d MMM yyyy "
        //           timeCaption="time"
        //           popperProps={{
        //             positionFixed: true,
        //           }}
        //         />
        //       </div>
        //       <div className="date-wrapper__input-wrapper">
        //         <Icon name="events" />
        //         <DatePicker
        //           placeholderText="End date"
        //           selected={scannedTicketsEndDate}
        //           onChange={(value) =>
        //             this.filterScannedTicketsByDate(
        //               "scannedTicketsEndDate",
        //               value
        //             )
        //           }
        //           showTimeSelect
        //           timeFormat="HH:mm"
        //           timeIntervals={15}
        //           dateFormat="h:mm a d MMM yyyy "
        //           timeCaption="time"
        //           popperProps={{
        //             positionFixed: true,
        //           }}
        //         />
        //       </div>
        //     </div>
        //   </div>
        // ),
        accessor: "scannedTickets",
        width: 196,
      },
      {
        Header: "Total",
        accessor: "allTickets",
        width: 196,
      },
      {
        Header: "Price",
        accessor: "price",
        width: 196,
      },
      {
        Header: "Capacity",
        accessor: "capacity",
        width: 196,
      },
      {
        Header: () => (
          <div className="link-stats">
            <div onClick={this.goToTransactionsPage} className="link-primary">
              <Icon name="transactions" className="icon-center" />
              Transactions
            </div>
            <div onClick={this.goToStatsPage} className="link-primary">
              <Icon name="stats" className="icon-center" />
              Statistics
            </div>
          </div>
        ),
        id: "clicka-me-button",
        Cell: (data) => (
          <>
            {/* <button
              className="btn-primary btn-primary--import"
              onClick={() => this.importTicketsTogler(data.original.id)}
            >
              Import
            </button> */}

            <button
              className="btn-primary btn-primary--barcode"
              onClick={() => {
                this.goToUploadBarcodes(data);
              }}
            >
              Upload barcodes
            </button>
          </>
        ),
        width: 270,
      },
    ];
    const { currentEvent, ticketEmailValue, offerEmailValue, guestEmailValue, errorEmailValue, invoiceEmailValue } = this.state;
    const { name, emailHeaderUrl } = this.props.currentEvent;
    const classes = [];
    let emptyStateText = "There are no tickets";

    if (
      currentEvent.ticketTemplates &&
      currentEvent.ticketTemplates.length > 6
    ) {
      classes.push("scroll");
    }

    const fullPath = [
      {
        path: ROUTES.EVENTS,
        name: "Events",
      },
      {
        name: name,
      },
    ];

    return (
      <Container className="event">
        <PageTitle>
          <Breadcrumbs routes={fullPath} />
        </PageTitle>

        <MobileHeader>{currentEvent.name}</MobileHeader>
        {this.props.loading ? <NewLoader isFullScreen /> : ""}

        <Dropzone
          imgUrl={emailHeaderUrl}
          onFilesAdded={this.handleUploadFile}
          onButtonEnable={() => { }}
          unsupportedFileFormatHandle={() => { }}
          onlyOneFileErrorHandle={() => { }}
        />

        <div className="add-event__row">
          <div className="add-event__column">
            <div className="add-event__block">
              <button
                type="button"
                className="btn-primary btn-primary--reselling"
                onClick={this.goToTemolateEditor}
              >
                Template Editor
              </button>
            </div>
          </div>
        </div>

        <div className="add-event__row">
          <div className="add-event__column">
            <div className="add-event__block">
              <label>Event name</label>
              <input
                type="text"
                name="name"
                value={currentEvent.name}
                minLength="3"
                maxLength="500"
                onChange={this.changeEventInfo}
              />
            </div>
          </div>

          <div className="add-event__column">
            <div className="add-event__block">
              <label>Venue</label>
              <input
                type="text"
                name="place"
                minLength="3"
                maxLength="500"
                placeholder="Enter venue"
                value={currentEvent.place}
                onChange={this.changeEventInfo}
              />
            </div>
          </div>
          <div className="all-and-scanned">
            <div className="add-event__column">
              <div className="add-event__block">
                <label>All tickets</label>
                <span className="add-event__block__count">
                  {currentEvent.allTickets}
                </span>
              </div>
              <div className="add-event__block">
                <label>Scanned tickets</label>
                <span className="add-event__block__count">
                  {currentEvent.scannedTickets}
                </span>
              </div>
            </div>
          </div>
        </div>
        <div className="add-event__row table">
          <div className="add-event__column">
            <div className="add-event__block">
              <label>Start</label>
              <DatePicker
                selected={this.state.currentEvent.startDate}
                onChange={(value) => this.handleChangeDate("startDate", value)}
                showTimeSelect
                timeFormat="HH:mm"
                timeIntervals={15}
                dateFormat="h:mm a d MMM yyyy "
                timeCaption="time"
              />
            </div>
          </div>

          <div className="add-event__column">
            <div className="add-event__block">
              <label>End</label>
              <DatePicker
                selected={this.state.currentEvent.endDate}
                onChange={(value) => this.handleChangeDate("endDate", value)}
                showTimeSelect
                timeFormat="HH:mm"
                timeIntervals={15}
                dateFormat="h:mm a d MMM yyyy "
                timeCaption="time"
              />
            </div>
          </div>

          {currentEvent.seasonName && (
            <div className="add-event__column">
              <div className="add-event__block">
                <label>Season</label>
                <span className="add-event__block__count">
                  {currentEvent.seasonName}
                </span>
              </div>
            </div>
          )}
        </div>
        <div className="add-event__row">
          <div className="add-event__column">
            <div className="add-event__block">
              <label>Currency</label>
              <Select
                options={currencies}
                name="currency"
                classNamePrefix="add-event__select"
                value={currentEvent.currency}
                defaultValue={currentEvent.currency}
                onChange={(value) => this.handleChangeCurrency(value)}
                className="add-event__select"
                styles={customSelectStyles}
                theme={(theme) => ({
                  ...theme,
                  colors: {
                    ...theme.colors,
                    primary: "#6071B5",
                    primary25: "#F2F4FE",
                    primary50: "#F2F4FE",
                    primary75: "#F2F4FE",
                  },
                })}
              />
            </div>
          </div>
          <div className="add-event__column">
            <div className="add-event__block">
              <label>Slug</label>
              <input
                type="text"
                name="slug"
                value={currentEvent.slug}
                minLength="3"
                maxLength="500"
                onChange={this.changeEventInfo}
              />
            </div>
          </div>
          {/* <div className="add-event__column">
            <div className="add-event__block add-event__checkboxes-item">
              <div className="checkboxes-item">
                <CheckBox
                  name="withCovidTest"
                  id="with-covid-test"
                  checked={currentEvent.isCheckingCovid}
                  onChange={() => {
                    this.setState((prevState) => ({
                      ...prevState,
                      isDisabled: false,
                      currentEvent: {
                        ...prevState.currentEvent,
                        isCheckingCovid:
                          !prevState.currentEvent.isCheckingCovid,
                      },
                    }));
                  }}
                >
                  Testing for COVID-19
                </CheckBox>
              </div>
            </div>
          </div> */}
        </div>

        <div className="add-event__row">
          <div className="add-event__column">
            <div className="add-event__block">
              <label>Sales availability</label>
              <Select
                options={salesAvailabilityOptions}
                name="salesAvailability"
                classNamePrefix="add-event__select"
                value={currentEvent.salesAvailability}
                defaultValue={currentEvent.salesAvailability}
                onChange={this.handleChangeSalesAvailability}
                className="add-event__select"
                styles={customSelectStyles}
                theme={(theme) => ({
                  ...theme,
                  colors: {
                    ...theme.colors,
                    primary: "#6071B5",
                    primary25: "#F2F4FE",
                    primary50: "#F2F4FE",
                    primary75: "#F2F4FE",
                  },
                })}
              />
            </div>
          </div>
          <div className="add-event__column">
            <div className="add-event__block">
              <label>Sales start time</label>
              <DatePicker
                selected={currentEvent.activationTime}
                onChange={this.handleChangeActivationTime}
                timeFormat="HH:mm"
                showTimeSelect
                timeIntervals={15}
                dateFormat="h:mm a d MMM yyyy "
                timeCaption="time"
                placeholderText="Select activation time"
              />
            </div>
          </div>
        </div>

        <div className="add-event__row">
          <div className="add-event__column">
            <div className="add-event__block">
              <Counter
                label="Number of tickets in one transaction"
                value={currentEvent.maxSeatsToSelectPerTransaction}
                onValueChange={(value) =>
                  this.handleChangeEventField(
                    "maxSeatsToSelectPerTransaction",
                    value
                  )
                }
              />
            </div>
          </div>

          <div className="add-event__column">
            <div className="add-event__block">
              <label>Category</label>
              <input
                type="text"
                name="prefixLabel"
                minLength="3"
                maxLength="500"
                placeholder="Enter event category"
                value={currentEvent.prefixLabel}
                onChange={this.changeEventInfo}
              />
            </div>
          </div>
          <div className="add-event__column">
            <div className="add-event__block">
              <Counter
                min="0"
                label={currentEvent.qrCodeVisibilityHours > 0 ? `QR-code will be visible in ${currentEvent.qrCodeVisibilityHours} hours before event` : "QR-code will be visible any time"}
                value={currentEvent.qrCodeVisibilityHours}
                onValueChange={(value) =>
                  this.handleChangeEventField(
                    "qrCodeVisibilityHours",
                    value
                  )
                }
              />
            </div>
          </div>

          <section className="select-email-templates-block">

            <div className="add-event__row">

              <div className="add-event__column">
                <div className="add-event__block">
                  <label>Ticket email template</label>
                  <AsyncSelect
                    cacheOptions
                    defaultOptions
                    loadOptions={this.getEmailTemplates(1)}
                    value={ticketEmailValue}
                    isSearchable={false}
                    onChange={(value) => this.handleChange("ticketEmailValue", value)}
                    classNamePrefix="custom-select"
                    className="custom-select"
                  />
                </div>
              </div>

              <div className="add-event__column">
                <div className="add-event__block">
                  <label>Offer email template</label>
                  <AsyncSelect
                    cacheOptions
                    defaultOptions
                    loadOptions={this.getEmailTemplates(2)}
                    value={offerEmailValue}
                    isSearchable={false}
                    onChange={(value) => this.handleChange("offerEmailValue", value)}
                    classNamePrefix="custom-select"
                    className="custom-select"
                  />
                </div>
              </div>

              <div className="add-event__column">
                <div className="add-event__block">
                  <label>Invoice email template</label>
                  <AsyncSelect
                    cacheOptions
                    defaultOptions
                    loadOptions={this.getEmailTemplates(5)}
                    value={invoiceEmailValue}
                    isSearchable={false}
                    onChange={(value) => this.handleChange("invoiceEmailValue", value)}
                    classNamePrefix="custom-select"
                    className="custom-select"
                  />
                </div>
              </div>

            </div>

            <div className="add-event__row">

              <div className="add-event__column">
                <div className="add-event__block">
                  <label>Guest email template</label>
                  <AsyncSelect
                    cacheOptions
                    defaultOptions
                    loadOptions={this.getEmailTemplates(3)}
                    value={guestEmailValue}
                    isSearchable={false}
                    onChange={(value) => this.handleChange("guestEmailValue", value)}
                    classNamePrefix="custom-select"
                    className="custom-select"
                  />
                </div>
              </div>

              <div className="add-event__column">
                <div className="add-event__block">
                  <label>Error email template</label>
                  <AsyncSelect
                    cacheOptions
                    defaultOptions
                    loadOptions={this.getEmailTemplates(4)}
                    value={errorEmailValue}
                    isSearchable={false}
                    onChange={(value) => this.handleChange("errorEmailValue", value)}
                    classNamePrefix="custom-select"
                    className="custom-select"
                  />
                </div>
              </div>

            </div>

          </section>

          <div
            className="add-event__column"
            style={{
              alignItems: "flex-end",
            }}
          >
            <div className="add-event__block">
              <button
                type="button"
                className="btn-primary btn-primary--reselling"
                onClick={this.sendResalePurposes}
              >
                Send reselling offers
              </button>
            </div>
          </div>
        </div>

        {currentEvent.ticketTemplates === undefined ? (
          <NewLoader />
        ) : currentEvent.ticketTemplates.length === 0 ? (
          <div className="empty-block">
            <p className="empty-block__text">{emptyStateText}</p>
            <img src="/images/img-empty-events.svg" alt="Empty events" />
          </div>
        ) : (
          <Fragment>
            <PageTitle notAbsolute>
              List of tickets{" "}
              <div className="download-report">
                <div
                  className="download-report__wrapper"
                  onClick={() => this.downloadExcelReport("tickets-report")}
                >
                  <Icon name="excel" />
                  <button className="download-report__button">
                    Tickets Report
                  </button>
                </div>
                <div
                  className="download-report__wrapper"
                  onClick={() =>
                    this.downloadExcelReport("transactions-report")
                  }
                >
                  <Icon name="excel" />
                  <button className="download-report__button">
                    Transactions Report
                  </button>
                </div>
                <div
                  className="download-report__wrapper"
                  onClick={() => this.downloadExcelReport("scanning-report")}
                >
                  <Icon name="excel" />
                  <button className="download-report__button">
                    Scanning Report
                  </button>
                </div>
              </div>
            </PageTitle>
            <ReactTable
              className={classes.join(" ")}
              data={currentEvent.ticketTemplates}
              columns={columns}
              showPagination={false}
              minRows={0}
              sortable={false}
              defaultPageSize={currentEvent.ticketTemplates.length}
              resizable={false}
              getTrProps={(state, rowInfo) => ({
                onClick: (e) => {
                  this.goToTicketPage(rowInfo.original.id, e);
                },
              })}
            />
            <MobileTicketsList
              list={currentEvent.ticketTemplates}
              onRowClick={this.goToTicketPage}
              onImportClick={(ticketId) => this.importTicketsTogler(ticketId)}
            />
          </Fragment>
        )}
        <div className="page-footer">
          <div style={{ marginTop: 8 }}>
            <CheckBox
              name="isPublicMode"
              id="is-public-mode"
              checked={currentEvent.isPublicMode}
              onChange={this.handleChangeMapMode}
            >
              Map mode: {currentEvent.isPublicMode ? "public" : "hidden"}
            </CheckBox>
          </div>
          <button
            type="button"
            className="btn-primary"
            onClick={this.handleMigrateSeats}
          >
            <Icon name="plus" />
            <span>Migrate seats</span>
          </button>

          <button
            type="button"
            className="btn-primary btn-primary--sell"
            onClick={this.goToSellPage}
          >
            <Icon name="plus" />
            <span>Sell page</span>
          </button>
          <button
            type="button"
            className="btn-primary btn-primary--manage"
            onClick={this.goToUploadAvailableSeats}
          >
            <span>Manage seats</span>
          </button>
          <button
            type="button"
            className="btn-primary"
            onClick={this.goToAddTicket}
          >
            <Icon name="plus" />
            <span>Add ticket</span>
          </button>
          <button
            className="btn-red-outline"
            type="button"
            onClick={this.deleteEvent}
          >
            <Icon name="trash" />
            <span>Delete</span>
          </button>
          <button
            type="button"
            className="btn-primary btn-primary--save"
            onClick={this.updateEvent}
            disabled={this.state.isDisabled}
          >
            <Icon name="save" />
            <span>Save</span>
          </button>
        </div>
        <TicketsImportContainer
          isActive={this.state.isImportActive}
          enterXlEventId={this.state.currentEvent.id}
          enterXlTicketTemplateId={this.state.ticketToSync}
          importTicketsToggle={this.importTicketsTogler}
        />
      </Container>
    );
  }
}

Event.propTypes = {};

const mapStateToProps = ({
  auth: { user },
  events: { currentEvent, loading, error },
}) => ({ user, currentEvent, loading, error });

const mapDispatchToProps = (dispatch) => ({
  getEvent: (eventId, token) => dispatch(getEvent(eventId, token)),
  updateHeaderImage: (token, eventId, files) =>
    dispatch(updateHeaderImage(token, eventId, files)),
  updateScannedTickets: (eventId, token, start, end) =>
    dispatch(updateScannedTickets(eventId, token, start, end)),
  updateEvent: (userToken, eventId, data) =>
    dispatch(updateEvent(userToken, eventId, data)),
  deleteEvent: (userToken, eventId) =>
    dispatch(deleteEvent(userToken, eventId)),
  clearCurrentEvent: () => dispatch(clearCurrentEvent()),
  resetEventsError: () => dispatch(resetEventsError()),
  sendResalePurposes: (eventId) => dispatch(sendResalePurposes(eventId)),
  getEmailTemplates: (typeId, userToken) => dispatch(getEmailTemplates(typeId, userToken)),
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Event));
