import React, { Component } from "react";
import { withRouter } from "react-router";

import Select from "react-select";
import AsyncSelect from "react-select/lib/Async";
import swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";

import queryString from "query-string";

import axios from "../../axios-instance";

import Dropzone from "../Upload/Dropzone/Dropzone";
import CheckBox from "../../components/UI/Checkbox/CheckBox";
import Container from "../../components/UI/Container/Container";

import { paymentTypeOptions } from "./constants";

import SellerInfoModal from "./SellerInfoModal/SellerInfoModal";

import SeasonPassLogo from "../../images/season-pass-logo.svg";
import Warning from "../../images/warning.svg";
import PleaseWait from "../../images/please-wait-season-pass-modal.svg";

import { handleSelectPlace, renderTotal, getTotal } from "./helpers";
import { showErrorModal } from "../../modals";

import "./SeasonPass.sass";

const MySwal = withReactContent(swal);

class OnlineSeller extends Component {
  state = {
    fields: {
      fullName: "",
      physicalAddress: "",
      city: "",
      postalCode: "",
      phoneNumber: "",
      buyerEmail: "",
      passType: null,
      area: null,
      row: null,
      seat: null,
      side: null,
      companyVatNumber: "",
      currencyCode: "RSD",
      paymentAmount: "0.00",
      paymentType: paymentTypeOptions[0],
    },
    total: "0.00",
    fileUrl: null,
    submittBlocked: false,
    isCheckoutAsCompanyChecked: false,
  };

  formRef = React.createRef();

  showSellerInfoModal = () => {
    MySwal.fire({
      html: (
        <SellerInfoModal
          onClose={() => {
            MySwal.close();
          }}
        />
      ),
      allowEscapeKey: false,
      customClass: "seasonpass__modal",
      allowOutsideClick: false,
      allowEnterKey: false,
      showConfirmButton: false,
    });
  };

  showConfirmationModal = () => {
    MySwal.fire({
      imageUrl: Warning,
      title: "Confirm Sending the Request",
      text: "Please note that this is an irreversible action",
      cancelButtonText: "Cancel",
      confirmButtonText: "Confirm",
      allowEscapeKey: false,
      customClass: "seasonpass__modal",
      allowOutsideClick: false,
      allowEnterKey: false,
      showConfirmButton: true,
      showCancelButton: true,
      reverseButtons: true,
    }).then((result) => {
      if (result.value) {
        this.handleSubmitt();
      }
    });
  };

  showPleaseWaitModal = () => {
    MySwal.fire({
      imageUrl: PleaseWait,
      title: "Please wait",
      allowEscapeKey: false,
      customClass: "seasonpass__modal",
      allowOutsideClick: false,
      allowEnterKey: false,
      showConfirmButton: false,
    });
  };

  setCheckoutAsCompany = () => {
    const { isCheckoutAsCompanyChecked, fields } = this.state;

    this.setState({
      isCheckoutAsCompanyChecked: !isCheckoutAsCompanyChecked,
      fields: {
        ...fields,
        companyVatNumber: "",
      },
    });
  };

  isSeatOccupied = async () => {
    try {
      const { area, passType, row, side, seat } = this.state.fields;

      const queryData = {
        area: area.value,
        row: row.value,
        passType: passType.value,
        seat: seat.value,
        side: side.value,
      };
      const query = queryString.stringify(queryData, { encode: false });

      const { data } = await axios.get(`/Order/checkSeasonPassStatus?${query}`);

      if (data) {
        showErrorModal({
          title: "This place has already been purchased",
        });
      }

      return data;
    } catch (e) {
      console.log(e);
    }
  };

  handleChange = (name, value) => {
    if (name) {
      if (name === "passType") {
        this.setState({
          fields: {
            ...this.state.fields,
            [name]: value,
            paymentAmount: value.amount,
          },
          total: getTotal(value.amount),
        });
        return;
      }
      this.setState({
        fields: { ...this.state.fields, [name]: value },
      });
    }
  };

  checkIsSubbmitDisabled = () => {
    const {
      fields: {
        companyVatNumber,
        city,
        postalCode,
        physicalAddress,
        ...otherFields
      },
      fileUrl,
      submittBlocked,
      isCheckoutAsCompanyChecked,
    } = this.state;

    const isPhotoRequired = otherFields.paymentType.value !== 1;

    let isRequiredFieldEmpty =
      !Object.keys(otherFields).every((key) => !!otherFields[key]) ||
      submittBlocked;

    if (isCheckoutAsCompanyChecked) {
      isRequiredFieldEmpty = isRequiredFieldEmpty || !companyVatNumber;
    }

    return isPhotoRequired
      ? isRequiredFieldEmpty || !fileUrl
      : isRequiredFieldEmpty;
  };

  getSellerInfoFromLocalStorage = () =>
    JSON.parse(localStorage.getItem("sellerInfo"));

  isSellerInfoFieldEnpty = () => {
    const sellerInfo = this.getSellerInfoFromLocalStorage();

    if (!sellerInfo) return true;

    return !sellerInfo.sellerPin || !sellerInfo.sellerName;
  };

  componentDidMount() {
    const isSellerInfoFieldEnpty = this.isSellerInfoFieldEnpty();

    if (isSellerInfoFieldEnpty) {
      this.showSellerInfoModal();
    }
  }

  handleUploadFile = async (files) => {
    try {
      const file = files[0];

      const url = `/Payment/AttachFile/-1`;

      const data = new FormData();
      data.append("file", file);
      const { data: fileUrl } = await axios.post(url, data);
      this.setState({
        fileUrl,
      });
    } catch (e) {
      showErrorModal();
      console.log(e);
    }
  };

  handleDeleteImg = () => {
    this.setState({
      fileUrl: null,
    });
  };

  handleSubmitt = async () => {
    const isSellerInfoFieldEnpty = this.isSellerInfoFieldEnpty();
    const isSeatOccupied = await this.isSeatOccupied();

    if (isSeatOccupied) {
      return;
    }

    if (isSellerInfoFieldEnpty) {
      this.showSellerInfoModal();
      return;
    }

    try {
      this.showPleaseWaitModal();
      const {
        fields: {
          area,
          row,
          passType,
          seat,
          side,
          paymentType,
          paymentAmount,
          currencyCode,
          ...otherFields
        },
        fileUrl,
        total,
      } = this.state;
      const sellerInfo = this.getSellerInfoFromLocalStorage();
      const paymentTypeValue = paymentType.value;

      const body = {
        order: {
          ...otherFields,
          fileUrl,
          area: area.value,
          row: row.value,
          passType: passType.value,
          seat: seat.value,
          side: side.value,
          currency: currencyCode,
          amount: paymentTypeValue === 1 ? paymentAmount : total,
          paymentType: paymentTypeValue,
          sellerPin: sellerInfo.sellerPin,
          sellerName: sellerInfo.sellerName,
        },
      };

      await axios.post("Payment/CreateOrderViaOnline", body);
      window.location.reload();
    } catch (e) {
      showErrorModal();
      console.log(e);
    }
  };

  checkIsSelectDisabled = (name) => {
    const { area, row, passType, side } = this.state.fields;
    switch (name) {
      case "area":
        return !passType;
      case "row":
        return !passType || !area;
      case "side":
        return !passType || !area || !row;
      case "seat":
        return !passType || !area || !row || !side;
      default:
        return false;
    }
  };

  render() {
    const {
      fields: {
        area,
        city,
        buyerEmail,
        fullName,
        physicalAddress,
        passType,
        phoneNumber,
        postalCode,
        row,
        seat,
        side,
        companyVatNumber,
        currencyCode,
        paymentAmount,
        paymentType,
      },
      total,
      fileUrl,
      isCheckoutAsCompanyChecked,
    } = this.state;

    const isPhotoRequired = paymentType.value !== 1;

    return (
      <Container className="seasonpass online-seller">
        <div className="seasonpass__wrapper">
          <div className="seasonpass__header-logo-wrapper">
            <img src={SeasonPassLogo} alt="Logo" />
          </div>
          <div className="seasonpass__header">
            <h2 className="seasonpass__header-title">Buy Season Pass</h2>
          </div>

          <form ref={this.formRef} className="seasonpass__form">
            <label className="seasonpass__field">
              <span className="seasonpass__field-text">
                <span className="seasonpass__field-text-label">Full Name</span>
                {!fullName && (
                  <span className="seasonpass__field-text-required">
                    Required Field
                  </span>
                )}
              </span>
              <input
                className={"seasonpass__field-input"}
                name="fullName"
                onChange={(e) => this.handleChange("fullName", e.target.value)}
                type="text"
                value={fullName}
              />
            </label>

            <label className="seasonpass__field">
              <span className="seasonpass__field-text">
                <span className="seasonpass__field-text-label">
                  Street and House Number
                </span>
              </span>
              <input
                className={"seasonpass__field-input"}
                name="physicalAddress"
                onChange={(e) =>
                  this.handleChange("physicalAddress", e.target.value)
                }
                type="text"
                value={physicalAddress}
              />
            </label>

            <div className="seasonpass__grouped-fields">
              <label className="seasonpass__field">
                <span className="seasonpass__field-text">
                  <span className="seasonpass__field-text-label">City</span>
                </span>
                <input
                  className="seasonpass__field-input"
                  name="city"
                  onChange={(e) => this.handleChange("city", e.target.value)}
                  value={city}
                  type="text"
                />
              </label>

              <label className="seasonpass__field">
                <span className="seasonpass__field-text">
                  <span className="seasonpass__field-text-label">
                    Postal Code
                  </span>
                </span>
                <input
                  className="seasonpass__field-input"
                  name="postalCode"
                  onChange={(e) =>
                    this.handleChange("postalCode", e.target.value)
                  }
                  type="text"
                  value={postalCode}
                />
              </label>
            </div>

            <div className="seasonpass__grouped-fields">
              <label className="seasonpass__field">
                <span className="seasonpass__field-text">
                  <span className="seasonpass__field-text-label">
                    Phone Number
                  </span>
                  {!phoneNumber && (
                    <span className="seasonpass__field-text-required">
                      Required Field
                    </span>
                  )}
                </span>
                <input
                  className="seasonpass__field-input"
                  type="text"
                  name="phoneNumber"
                  onChange={(e) =>
                    this.handleChange("phoneNumber", e.target.value)
                  }
                  value={phoneNumber}
                />
              </label>

              <label className="seasonpass__field">
                <span className="seasonpass__field-text">
                  <span className="seasonpass__field-text-label">
                    Buyer Email
                  </span>
                  {!buyerEmail && (
                    <span className="seasonpass__field-text-required">
                      Required Field
                    </span>
                  )}
                </span>
                <input
                  className="seasonpass__field-input"
                  name="buyerEmail"
                  onChange={(e) =>
                    this.handleChange("buyerEmail", e.target.value)
                  }
                  type="text"
                  value={buyerEmail}
                />
              </label>
            </div>

            <div className="seasonpass__grouped-fields seasonpass__company-vat-number">
              <div className="seasonpass__field">
                <span className="seasonpass__field-text">
                  <span className="seasonpass__field-text-label">
                    Checkout as company
                  </span>
                </span>
                <CheckBox
                  checked={isCheckoutAsCompanyChecked}
                  id="checkout-as-company-checkbox"
                  name="checkout-as-company-checkbox"
                  onChange={this.setCheckoutAsCompany}
                >
                  <span>Checkout as company</span>
                </CheckBox>
              </div>

              {isCheckoutAsCompanyChecked && (
                <label className="seasonpass__field">
                  <span className="seasonpass__field-text">
                    <span className="seasonpass__field-text-label">
                      Company Vat Number
                    </span>
                    {!companyVatNumber && (
                      <span className="seasonpass__field-text-required">
                        Required Field
                      </span>
                    )}
                  </span>
                  <input
                    className="seasonpass__field-input"
                    disabled={!isCheckoutAsCompanyChecked}
                    name="companyVatNumber"
                    onChange={(e) =>
                      this.handleChange("companyVatNumber", e.target.value)
                    }
                    type="text"
                    value={companyVatNumber}
                  />
                </label>
              )}
            </div>

            <div className="seasonpass__dropzone">
              <span className="seasonpass__field-text">
                <span className="seasonpass__field-text-label">
                  Upload Season Pass
                </span>
                {isPhotoRequired && !fileUrl && (
                  <span className="seasonpass__field-text-required">
                    Required Field
                  </span>
                )}
              </span>
              <div className="seasonpass__dropzone-hero">
                {fileUrl ? (
                  <div className="seasonpass__dropzone-image-wrapper">
                    <img
                      className="seasonpass__dropzone-image"
                      src={fileUrl}
                      alt="Uploaded"
                    />
                    <button
                      type="button"
                      className="seasonpass__dropzone-image-drop"
                      onClick={this.handleDeleteImg}
                    />
                  </div>
                ) : (
                  <Dropzone
                    // accept="image/png, image/jpeg"
                    onFilesAdded={this.handleUploadFile}
                    onButtonEnable={() => {}}
                    unsupportedFileFormatHandle={() => {}}
                    onlyOneFileErrorHandle={() => {}}
                  />
                )}
              </div>
              <span className="seasonpass__field-text">
                <span className="seasonpass__field-text-label">
                  Please note that without your season pass purchase will not be
                  possible
                </span>
                <span className="seasonpass__field-text-required seasonpass__dropzone-format-text">
                  Only in JPEG and PNG Format
                </span>
              </span>
            </div>

            <label className="seasonpass__field">
              <span className="seasonpass__field-text">
                <span className="seasonpass__field-text-label">
                  Season Pass Type
                </span>
                {!passType && (
                  <span className="seasonpass__field-text-required">
                    Required Field
                  </span>
                )}
              </span>

              <AsyncSelect
                cacheOptions
                defaultOptions
                loadOptions={handleSelectPlace("passType")}
                name="passType"
                isDisabled={this.checkIsSelectDisabled("passType")}
                onChange={(value) => this.handleChange("passType", value)}
                classNamePrefix="seasonpass__select"
                className="seasonpass__select"
              />
            </label>

            <div className="seasonpass__grouped-fields seasonpass__grouped-fields_selects">
              <label className="seasonpass__field">
                <span className="seasonpass__field-text">
                  <span className="seasonpass__field-text-label">Area</span>
                  {!area && (
                    <span className="seasonpass__field-text-required">
                      Required Field
                    </span>
                  )}
                </span>
                <AsyncSelect
                  cacheOptions
                  defaultOptions
                  loadOptions={handleSelectPlace("area")}
                  name="area"
                  isDisabled={this.checkIsSelectDisabled("area")}
                  onChange={(value) => this.handleChange("area", value)}
                  classNamePrefix="seasonpass__select"
                  className="seasonpass__select"
                />
              </label>

              <label className="seasonpass__field">
                <span className="seasonpass__field-text">
                  <span className="seasonpass__field-text-label">Row</span>
                  {!row && (
                    <span className="seasonpass__field-text-required">
                      Required Field
                    </span>
                  )}
                </span>
                <AsyncSelect
                  cacheOptions
                  defaultOptions
                  loadOptions={handleSelectPlace("row")}
                  isDisabled={this.checkIsSelectDisabled("row")}
                  onChange={(value) => this.handleChange("row", value)}
                  classNamePrefix="seasonpass__select"
                  className="seasonpass__select"
                />
              </label>

              <label className="seasonpass__field">
                <span className="seasonpass__field-text">
                  <span className="seasonpass__field-text-label">Side</span>
                  {!side && (
                    <span className="seasonpass__field-text-required">
                      Required Field
                    </span>
                  )}
                </span>
                <AsyncSelect
                  cacheOptions
                  defaultOptions
                  loadOptions={handleSelectPlace("side")}
                  isDisabled={this.checkIsSelectDisabled("side")}
                  onChange={(value) => this.handleChange("side", value)}
                  classNamePrefix="seasonpass__select"
                  className="seasonpass__select"
                />
              </label>

              <label className="seasonpass__field">
                <span className="seasonpass__field-text">
                  <span className="seasonpass__field-text-label">Seat</span>
                  {!seat && (
                    <span className="seasonpass__field-text-required">
                      Required Field
                    </span>
                  )}
                </span>
                <AsyncSelect
                  cacheOptions
                  defaultOptions
                  loadOptions={handleSelectPlace("seat")}
                  isDisabled={this.checkIsSelectDisabled("seat")}
                  onChange={(value) => this.handleChange("seat", value)}
                  classNamePrefix="seasonpass__select"
                  className="seasonpass__select"
                />
              </label>
            </div>

            <label className="seasonpass__field">
              <span className="seasonpass__field-text">
                <span className="seasonpass__field-text-label">
                  Payment Type
                </span>
                {!paymentType && (
                  <span className="seasonpass__field-text-required">
                    Required Field
                  </span>
                )}
              </span>

              <Select
                options={paymentTypeOptions}
                name="paymentType"
                value={paymentType}
                onChange={(value) => this.handleChange("paymentType", value)}
                classNamePrefix="seasonpass__select"
                className="seasonpass__select"
              />
            </label>

            <div className="seasonpass__result-wrapper">
              <button
                type="button"
                onClick={this.showConfirmationModal}
                className="btn-primary seasonpass__btn"
                disabled={this.checkIsSubbmitDisabled()}
              >
                Send
              </button>

              {renderTotal({
                currencyCode,
                price: paymentAmount,
                total,
              })}
            </div>
          </form>
        </div>
      </Container>
    );
  }
}

export default withRouter(OnlineSeller);
