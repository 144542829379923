import * as actionTypes from "../actionTypes/eventsTypes";

const initialState = {
  eventsList: [],
  loading: false,
  currentEvent: {},
  currentTicket: {},
};

const events = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.GET_EVENTS_START:
      return {
        ...state,
        loading: true,
      };
    case actionTypes.GET_EVENTS_SUCCESS:
      return {
        ...state,
        eventsList: action.payload,
        loading: false,
      };
    case actionTypes.GET_EVENTS_FAIL:
      return {
        ...state,
        error: action.error,
        loading: false,
      };

    case actionTypes.ADD_EVENT_START:
      return {
        ...state,
        error: null,
        loading: true,
      };
    case actionTypes.ADD_EVENT_SUCCESS:
      return {
        ...state,
        eventsList: action.payload,
        error: null,
        loading: false,
      };
    case actionTypes.ADD_EVENT_FAIL:
      return { ...state, error: action.error.message, loading: false };

    case actionTypes.RESET_EVENTS_ERROR:
      return {
        ...state,
        error: null,
        loading: false,
      };

    case actionTypes.GET_EVENT_START:
      return { ...state, loading: true };
    case actionTypes.GET_EVENT_SUCCESS:
      return {
        ...state,
        currentEvent: action.payload.event,
        loading: false,
      };
    case actionTypes.GET_EVENT_FAIL:
      return { ...state, loading: false };

    case actionTypes.GET_TICKET_START:
      return { ...state, loading: true };
    case actionTypes.GET_TICKET_SUCCESS:
      return {
        ...state,
        currentTicket: action.payload.ticket,
        loading: false,
      };
    case actionTypes.GET_TICKET_FAIL:
      return { ...state, loading: false };

    case actionTypes.DELETE_EVENT_START:
      return { ...state, loading: true };
    case actionTypes.DELETE_EVENT_SUCCESS: {
      return { ...state, loading: false };
    }
    case actionTypes.DELETE_EVENT_FAIL:
      return { ...state, loading: false };

    case actionTypes.UPDATE_EVENT_START:
      return { ...state, error: null, loading: true };
    case actionTypes.UPDATE_EVENT_SUCCESS:
      return { ...state, error: null, loading: false };
    case actionTypes.UPDATE_EVENT_FAIL:
      return { ...state, error: action.error.message, loading: false };

    case actionTypes.CLEAR_CURRENT_EVENT:
      return {
        ...state,
        currentEvent: {},
      };

    case actionTypes.GET_UPDATE_TICKET_START:
    case actionTypes.REMOVE_TICKET_START:
      return { ...state, loading: true };

    case actionTypes.GET_UPDATE_TICKET_SUCCESS:
    case actionTypes.REMOVE_TICKET_SUCCESS:
      return { ...state, loading: false };

    case actionTypes.GET_UPDATE_TICKET_FAIL:
    case actionTypes.REMOVE_TICKET_FAIL:
      return { ...state, loading: false };

    case actionTypes.GET_EVENT_SELL_STATS_START:
      return { ...state, loading: true };
    case actionTypes.UPDATE_EVENT_SELL_STATISTIC:
    case actionTypes.GET_EVENT_SELL_STATS_FAIL:
      return { ...state, loading: false };

    case actionTypes.GET_EVENT_SCAN_STATS_START:
      return { ...state, loading: true };
    case actionTypes.UPDATE_EVENT_SCAN_STATISTIC:
    case actionTypes.GET_EVENT_SCAN_STATS_FAIL:
      return { ...state, loading: false };

    default:
      return state;
  }
};

export default events;
