import React, { Component } from 'react';
import TicketsImportStep from "./TicketsImportStep";
import './TicketsImport.sass'
import Modal from '../Modal/Modal';

class TicketsImportComponent extends Component {
    state = {
        loginData: {
            login: '',
            password: '',
        },
    };

    handleInput = evt => {
        const name = evt.target.name;
        const value = evt.target.value;

        this.setState((state) => ({
            loginData: {
                ...state.loginData,
                [name]: value,
            }
        }));
    };

    clearInput = () => {
        this.setState({ loginData: {
                login: '',
                password: '',
        }});
    }

    render() {
        const {providersEvents, selectedEvent, currentStep, isValid} = this.props.ticketsImport;
        const {isActive, prevStep, isLoading, nextStep} = this.props;
        return <>
            {
                isActive
                    ? <Modal>
                        <div className="tickets-import">
                            <TicketsImportStep step={1} currentStep={currentStep}>
                                <div className="tickets-import__header">
                                    <h2 className="tickets-import__title">Choose ticket provider</h2>
                                </div>

                                <div className="tickets-import__content">
                                    <div
                                        className="tickets-import__item tickets-import__item--tibbaa"
                                        onClick={() => nextStep()}
                                    >
                                        Tibbaa
                                    </div>
                                </div>
                                <div className="tickets-import__footer">
                                    <button
                                        className="btn-secondary"
                                        type="button"
                                        onClick={
                                            () => {
                                                this.props.importTicketsToggle();
                                                this.props.ticketsImportClearStore();
                                            }
                                        }
                                    >
                                        Cancel
                                    </button>
                                </div>
                            </TicketsImportStep>

                            <TicketsImportStep step={2} currentStep={currentStep}>
                                <div className="tickets-import__header">
                                      <span
                                          className="tickets-import__header-btn"
                                        onClick={
                                            () => {
                                                prevStep();
                                                this.clearInput()
                                            }}
                                      >
                                        Back
                                      </span>
                                    <h2 className="tickets-import__title">Sign in to Tibbaa</h2>
                                </div>

                                <div className="tickets-import__content">
                                    <form className="tickets-import__form">
                                        <input
                                            name="login"
                                            type="text"
                                            placeholder="Email"
                                            value={this.state.loginData.email}
                                            onChange={this.handleInput}
                                        />
                                        <input
                                            name="password"
                                            type="password"
                                            placeholder="Password"
                                            value={this.state.loginData.password}
                                            onChange={this.handleInput}
                                        />
                                    </form>
                                </div>
                                <div className="tickets-import__footer">
                                    <button
                                        className="btn-secondary"
                                        type="button"
                                        onClick={
                                            () => {
                                                this.props.importTicketsToggle();
                                                this.props.ticketsImportClearStore();
                                                this.clearInput()
                                            }
                                        }
                                    >
                                        Cancel
                                    </button>
                                    <button
                                        className="btn-primary"
                                        type="submit"
                                        disabled={this.state.loginData.login.length < 3 || this.state.loginData.password.length < 3}
                                        onClick={
                                            () => {
                                                this.props.getProvidersEvents(this.state.loginData, this.props.token)
                                            }
                                        }
                                    >
                                        Sign In
                                    </button>
                                </div>
                            </TicketsImportStep>

                            <TicketsImportStep step={3} currentStep={currentStep}>
                                <div className="tickets-import__header">
                                        <span className="tickets-import__header-btn"
                                              onClick={prevStep}>Back</span>
                                    <h2 className="tickets-import__title">Choose your event</h2>
                                </div>

                                <div className="tickets-import__content">
                                    {
                                        !isLoading
                                            ? [...providersEvents].map(event => {
                                                const {enterXlEventId, selectProvidersEvent} = this.props;
                                                return (
                                                    <div key={event.eventId}
                                                         onClick={() => selectProvidersEvent(event, enterXlEventId)}
                                                         className={
                                                             `tickets-import__item ${
                                                                 this.props.ticketsImport.selectedEvent.eventId === event.eventId
                                                                     ? 'tickets-import__item--active'
                                                                     : null
                                                             }`
                                                         }
                                                    >
                                                        {event.eventName}
                                                    </div>
                                                );
                                            })
                                            : 'Loading...'
                                    }
                                </div>
                                <div className="tickets-import__footer">
                                    <button
                                        className="btn-secondary"
                                        type="button"
                                        onClick={
                                            () => {
                                                this.props.importTicketsToggle();
                                                this.props.ticketsImportClearStore();
                                            }
                                        }
                                    >
                                        Cancel
                                    </button>
                                    <button
                                        className="btn-primary"
                                        type="button"
                                        disabled={!isValid}
                                        onClick={
                                            () => {
                                                this.props.nextStep();
                                            }
                                        }
                                    >
                                        Next
                                    </button>
                                </div>
                            </TicketsImportStep>

                            <TicketsImportStep step={4} currentStep={currentStep}>
                                <div className="tickets-import__header">
                                      <span
                                          className="tickets-import__header-btn"
                                          onClick={prevStep}
                                      >
                                        Back
                                      </span>
                                    <h2 className="tickets-import__title">Choose your ticket template</h2>
                                </div>

                                <div className="tickets-import__content">
                                    {
                                        !isLoading && Object.keys(selectedEvent).length
                                            ? [...selectedEvent.ticketTemplate].map(ticketTemplate => {

                                                return (
                                                    <div
                                                        key={ticketTemplate.ticketTemplateId}
                                                        onClick={
                                                            () => this.props.selectProvidersTicketTemplate(ticketTemplate, this.props.enterXlTicketTemplateId)}
                                                        className={
                                                            `tickets-import__item ${
                                                                this.props.ticketsImport.selectedTicket.ticketTemplateId === ticketTemplate.ticketTemplateId
                                                                    ? 'tickets-import__item--active'
                                                                    : null
                                                            }`
                                                        }
                                                    >
                                                        {ticketTemplate.ticketName}
                                                    </div>
                                                );
                                            })
                                            : 'false'
                                    }
                                </div>
                                <div className="tickets-import__footer">
                                    <button
                                        className="btn-secondary"
                                        type="button"
                                        onClick={
                                            () => {
                                                this.props.importTicketsToggle();
                                                this.props.ticketsImportClearStore();
                                            }
                                        }
                                    >
                                        Cancel
                                    </button>
                                    <button
                                        className="btn-primary"
                                        type="button"
                                        disabled={!isValid}
                                        onClick={
                                            () => {
                                                this.props.importTickets(this.props.ticketsImport.dataForImport, this.state.loginData, this.props.token);
                                                this.props.importTicketsToggle();
                                                this.setState({
                                                    loginData: {
                                                        login: '',
                                                        password: '',
                                                    },
                                                })
                                            }
                                        }
                                    >
                                        Import
                                    </button>
                                </div>
                            </TicketsImportStep>
                        </div>
                    </Modal>
                    : null
            }
        </>
            ;
    }
}

export default TicketsImportComponent;

