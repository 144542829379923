import React, { Component } from "react";
import { withRouter } from "react-router";

import swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import queryString from "query-string";

import moment from "moment";

import axios from "../../../axios-instance";

import Container from "../../../components/UI/Container/Container";
import Icon from "../../../components/UI/Icon/Icon";
import { Stepper } from "../../../components/Stepper/Stepper";

import { FillFields, ChoosePaymentMethod, SelectTickets } from "./Steps";
import TermsAndConditionsModal from "../TermsAndConditionsModal/TermsAndConditionsModal";

import { bankLogos, fee, paymentMethodOptions } from "../constants";
import ROUTES from "../../../constants/routes";
import { currencies } from "../../../constants/currencies";
import { createMerchantSin, getTotal } from "../helpers";
import { showInfoModal, showErrorModal } from "../../../modals";

import PleaseWait from "../../../images/please-wait-season-pass-modal.svg";
import ThanksForYourPurchase from "../../../images/thanks-for-your-purchase.svg";
import PartizanOpenSellingLogo from "../../../images/partizan-open-selling-logo.svg";

import "../SeasonPass.sass";
import "./PartizanOpenSelling.sass";

const MySwal = withReactContent(swal);

const mapSeatIo = ({
  category: { label, key },
  labels: { section, parent, own },
  pricing: { price },
  eventData,
}) => {
  const [area, side] = section.split("-");
  const ticketTemplate = eventData.ticketTemplates.find(
    ({ categoryKey }) => +categoryKey === +key
  );
  const ticketTemplateId = ticketTemplate && ticketTemplate.ticketTemplateId;

  return {
    ticketTemplateId,
    passType: label,
    area: area,
    row: parent,
    seat: own,
    side: side,
    amount: price,
    membershipCardPrice: 0,
  };
};

const mapSeat = (result, item) => {
  const { ticketTemplateId, count } = item;

  for (let i = 0; i < count; i++) {
    result.push({ ticketTemplateId: ticketTemplateId });
  }

  return result;
};

class PartizanOpenSelling extends Component {
  state = {
    fields: {
      fullName: "",
      physicalAddress: "",
      city: "",
      postalCode: "",
      phoneNumber: "",
      buyerEmail: "",
      birthday: null,
      gender: "",
      paymentMethod: paymentMethodOptions[0].value,
      companyName: "",
      companyVatNumber: "",
    },
    checkboxeFields: {
      termsAndConditionsChecked: false,
    },
    tickets: [],
    transactionData: {
      orderId: "",
      paymentAmount: "",
      currencyCode: "RSD",
      resURL: "",
      //TODO: should check
      sessionValidity: "2023-08-28T14:26:53.000Z",
      redirectURL: "",
      lang: "en",
      merchantName: "WE ARE FANCEE d.o.o",
      signedKeys:
        "orderId,paymentAmount,currencyCode,sessionValidity,resURL,redirectURL,lang,merchantName",
    },
    eventData: {
      ticketTemplates: [],
    },
    step: 1,
    themeColor: "#F95700",
    hasSeats: undefined,
    feeRate: fee,
    loading: true,
    submittBlocked: false,
  };

  formRef = React.createRef();

  handleaChangeStep = (nextStep) => {
    this.setState({ step: nextStep });
  };

  setOrderDataToState = (data) => {
    const fields = {
      ...this.state.fields,
      fullName: data.fullName,
      physicalAddress: data.physicalAddress,
      city: data.city,
      postalCode: data.postalCode,
      phoneNumber: data.phoneNumber,
      buyerEmail: data.buyerEmail,
      birthday: data.birthday ? new Date(data.birthday) : null,
      gender: data.gender,
      companyName: data.companyName,
      companyVatNumber: data.companyVatNumber,
    };

    this.setState({
      fields,
    });
  };

  showPleaseWaitModal = () => {
    MySwal.fire({
      imageUrl: PleaseWait,
      title: "Please wait",
      text: "You will be redirected to the payment page",
      allowEscapeKey: false,
      customClass: "seasonpass__modal",
      allowOutsideClick: false,
      allowEnterKey: false,
      showConfirmButton: false,
    });
  };

  getOrderData = async () => {
    try {
      const { data } = await axios.get(
        `Transaction/${this.props.match.params.id}`
      );
      if (data) {
        this.handleOrderStatuses(data);
        this.setOrderDataToState(data);
      }
    } catch (e) {
      showErrorModal({
        showConfirmButton: false,
        text: `It seems to have failed to complete the payment, please try again`,
      });
      console.log(e);
    }
  };

  setPrice = () => {
    const { tickets, hasSeats } = this.state;
    let price = 0;
    if (!hasSeats) {
      price = tickets.reduce((accumulator, ticket) => {
        return accumulator + ticket.price * ticket.count;
      }, 0);
    } else {
      price = tickets.reduce((accumulator, ticket) => {
        return accumulator + +ticket.pricing.price;
      }, 0);
    }
    this.setTransactionData({
      paymentAmount: price,
    });
  };

  handleOrderStatuses = (data) => {
    switch (data.paymentStatus) {
      //New
      case 0:
        break;
      //Success
      case 1:
        showInfoModal({
          image: ThanksForYourPurchase,
          title: "Thanks for Your Purchase!",
          text: `We will send all the details to your inbox (${data.buyerEmail}) and look forward to meeting you`,
          additionalText: `(Payment Status: ${
            data.additionalPaymentStatus
          }, Payment Date: ${moment(data.paymentDate).format(
            "DD MMMM YYYY HH:mm"
          )}, Transaction Id: ${data.transactionId})`,
        });
        this.setState({ submittBlocked: true });
        break;
      //Failed
      case 2:
        showErrorModal({
          text: `It seems to have failed to complete the payment, please try again`,
          additionalText: `(Payment Status: ${
            data.additionalPaymentStatus
          }, Payment Date: ${moment(data.paymentDate).format(
            "DD MMMM YYYY HH:mm"
          )}, Transaction Id: ${data.transactionId})`,
        });
        break;
      //Cancelled
      case 3:
        break;
      default:
        break;
    }
  };

  setTransactionData = (data) => {
    const paymentAmount = data.paymentAmount;

    const orderId = data.orderId || this.state.transactionData.orderId || "";

    const transactionData = {
      ...this.state.transactionData,
      ...data,
      orderId,
      redirectURL: `${window.location.origin}${ROUTES.LOVEFEST.replace(
        ":id?",
        orderId
      )}?eventId=${this.state.eventData.eventId}`,
      resURL: `${process.env.API_URL}/Payment/Webhook/Kopa`,
    };

    if (paymentAmount) {
      transactionData.paymentAmount = getTotal(
        paymentAmount,
        this.state.feeRate
      );
    }

    transactionData.merchantSig = createMerchantSin(
      transactionData,
      transactionData.signedKeys
    );

    this.setState({
      transactionData,
    });
  };

  createOrder = async () => {
    const {
      fields: { paymentMethod, ...otherFields },
      transactionData,
      tickets,
      eventData,
      hasSeats,
    } = this.state;

    const seats = hasSeats
      ? tickets.map((ticket) => mapSeatIo({ ...ticket, eventData }))
      : tickets.reduce(mapSeat, []);

    const { eventId } = queryString.parse(this.props.location.search);

    const body = {
      transaction: {
        ...otherFields,
        currency: transactionData.currencyCode,
        amount: transactionData.paymentAmount,
        seats,
        seatsIoEventId: eventData.seatsIoEventId,
      },
    };

    const { data: id } = await axios.post("Transaction/createForSell", body);

    this.setTransactionData({
      orderId: id,
    });

    this.props.history.push({
      pathname: ROUTES.LOVEFEST.replace(":id?", id) + `?eventId=${eventId}`,
    });
  };

  handleSubmit = async () => {
    try {
      this.showPleaseWaitModal();
      await this.createOrder();
      this.formRef.current.submit();
    } catch (e) {
      console.log(e);
      showErrorModal({
        text: `It seems to have failed to complete the payment, please try again`,
      });
    }
  };

  handleChange = (name, value) => {
    if (name) {
      this.setState({ fields: { ...this.state.fields, [name]: value } });
    }
  };

  showTermsAndConditionsModal = (ev) => {
    ev.preventDefault();
    MySwal.fire({
      html: <TermsAndConditionsModal />,
      width: "64em",
      customClass: "seasonpass__modal",
    });
  };

  handleChangeCheckbox = (name) => {
    if (name) {
      this.setState({
        checkboxeFields: {
          ...this.state.checkboxeFields,
          [name]: !this.state.checkboxeFields[name],
        },
      });
    }
  };

  checkActionButtonDisabled = () => {
    const {
      step,
      fields: {
        companyName,
        companyVatNumber,
        paymentMethod,
        birthday,
        ...otherFields
      },
      checkboxeFields,
      submittBlocked,
      tickets,
    } = this.state;

    if (submittBlocked) return true;

    switch (step) {
      case 1:
        return !tickets.length;
      case 3:
        return !paymentMethod;
      case 2:
        return (
          !checkboxeFields.termsAndConditionsChecked ||
          !Object.keys(otherFields).every((key) => !!otherFields[key]) ||
          !moment(birthday).isValid()
        );
      default:
        return false;
    }
  };

  onObjectSelected = (obj) => {
    const { hasSeats } = this.state;
    if (!hasSeats) {
      let findedTicket = this.state.tickets.find(
        ({ ticketTemplateId }) => ticketTemplateId === obj.ticketTemplateId
      );

      if (findedTicket) {
        this.setState(
          {
            tickets: this.state.tickets.map((ticket) =>
              ticket.ticketTemplateId === obj.ticketTemplateId ? obj : ticket
            ),
          },
          () => this.setPrice()
        );
        return;
      }
    }
    if (hasSeats && !obj.pricing) {
      return;
    }
    const tickets = [...this.state.tickets, obj];

    this.setState({ tickets }, () => this.setPrice());
  };

  onObjectDeselected = (obj) => {
    const { hasSeats } = this.state;
    if (!hasSeats) {
      this.setState(
        {
          tickets: this.state.tickets.filter(
            (item) => item.ticketTemplateId !== obj.ticketTemplateId
          ),
        },
        () => this.setPrice()
      );
      return;
    }

    this.setState(
      {
        tickets: this.state.tickets.filter((item) => item.id !== obj.id),
      },
      () => this.setPrice()
    );
  };

  getEventData = async () => {
    try {
      const { eventId } = queryString.parse(this.props.location.search);
      if (!eventId) throw Error();

      this.setState({ loading: true });
      document.documentElement.style.setProperty(
        "--partizan-theme",
        this.state.themeColor
      );

      const { data } = await axios.get(`Transaction/Event/${eventId}`);

      this.setState({
        transactionData: {
          ...this.state.transactionData,
          currencyCode: currencies.find(
            ({ value }) => value === data.currencyId
          ).label,
        },
        hasSeats: data.ticketTemplates.length
          ? data.ticketTemplates[0].hasSeats
          : undefined,
        feeRate: data.feeRate || fee,
        eventData: data,
        loading: false,
      });
    } catch (e) {
      showErrorModal({
        showConfirmButton: false,
        text: `It seems to have failed to complete the payment, please try again`,
      });
      console.log(e);
    }
  };

  componentDidMount() {
    const orderId = this.props.match.params.id;

    if (orderId) this.getOrderData();

    this.getEventData();
  }

  render() {
    const {
      step,
      fields,
      checkboxeFields,
      tickets,
      transactionData,
      eventData,
      loading,
      hasSeats,
      feeRate,
    } = this.state;

    const time = "08.08.2024 | 12:00 - 11.08.2024 | 18:00";

    const pricing = eventData.ticketTemplates.map(({ categoryKey, price }) => ({
      category: categoryKey,
      price,
    }));

    const steps = [
      {
        title: "Select Tickets",
        component: (
          <SelectTickets
            feeRate={feeRate}
            tickets={tickets}
            ticketTemplates={eventData.ticketTemplates}
            pricing={pricing}
            hasSeats={hasSeats}
            workspaceKey={eventData.seatsIoWorkspaceId}
            event={eventData.seatsIoEventId}
            loading={loading}
            currency={transactionData.currencyCode}
            onObjectSelected={this.onObjectSelected}
            onObjectDeselected={this.onObjectDeselected}
            total={transactionData.paymentAmount}
          />
        ),
      },
      {
        title: "Fill in the info",
        component: (
          <FillFields
            fields={fields}
            handleChange={this.handleChange}
            checkboxeFields={checkboxeFields}
            showTermsAndConditionsModal={this.showTermsAndConditionsModal}
            handleChangeCheckbox={this.handleChangeCheckbox}
          />
        ),
      },
      {
        title: "Buy tickets",
        component: (
          <ChoosePaymentMethod
            feeRate={feeRate}
            hasSeats={hasSeats}
            currency={transactionData.currencyCode}
            email={fields.buyerEmail}
            tickets={tickets}
            total={transactionData.paymentAmount}
            paymentMethod={fields.paymentMethod}
            handleChange={this.handleChange}
            allowEnterCouponCode={false}
          />
        ),
      },
    ];

    const headerComponent = (
      <>
        <div className="partizan-open-selling__stepper-header-wrapper">
          <h2 className="partizan-open-selling__stepper-header-title">
            {eventData.eventName}
          </h2>
          <h5 className="partizan-open-selling__stepper-header-subtitle">
            Supported by Fancee tickets
          </h5>
          <div className="partizan-open-selling__stepper-header-info">
            <div className="partizan-open-selling__stepper-header-info-location">
              <Icon name={"map-pin-solid"} />
              <p>Vrnjacka Banja, Park Jezero</p>
            </div>
            <p className="partizan-open-selling__stepper-header-info-time">
              {time}
            </p>
          </div>
        </div>
      </>
    );

    const actionComponent = (
      <div className="partizan-open-selling__stepper-action">
        <div className="partizan-open-selling__stepper-action-btns">
          <button
            className="btn-secondary"
            type="button"
            disabled={step === 1}
            onClick={() => this.handleaChangeStep(step - 1)}
          >
            Previous
          </button>
          {steps.length === step ? (
            <button
              className="btn-primary"
              type="button"
              disabled={this.checkActionButtonDisabled()}
              onClick={this.handleSubmit}
            >
              Pay
            </button>
          ) : (
            <button
              className="btn-primary"
              type="button"
              disabled={this.checkActionButtonDisabled()}
              onClick={() => this.handleaChangeStep(step + 1)}
            >
              Next
            </button>
          )}
        </div>
        <div className="seasonpass__bank-logos">
          {bankLogos.map(({ alt, src, link }) => (
            <div key={alt}>
              <a target="_blank" rel="noopener noreferrer" href="{link}">
                <img style="heigh:50px;" src={src} alt={alt} />
              </a>
            </div>
          ))}
        </div>
      </div>
    );

    return (
      <Container className="seasonpass partizan-open-selling">
        <form
          ref={this.formRef}
          action={process.env.REACT_APP_PAYMENT_LINK}
          method="post"
          className="seasonpass__wrapper partizan-open-selling__wrapper"
        >
          {Object.keys(transactionData).map((key) => (
            <input
              name={key}
              key={key}
              type="hidden"
              value={transactionData[key]}
            />
          ))}
          <div className="partizan-open-selling__logo seasonpass__header-logo-wrapper">
            <img src={PartizanOpenSellingLogo} alt="Logo" />
            <h2>Fancee Tickets</h2>
          </div>
          <Stepper
            headerBgStyle={{
              background: "url('/images/lovefest-bg.png')",
              backgroundSize: "100%",
              backgroundRepeat: "no-repeat",
            }}
            headerWrapperClass="partizan-open-selling__stepper-header"
            headerComponent={headerComponent}
            actionComponent={actionComponent}
            currentStep={step}
            steps={steps}
          />
        </form>
      </Container>
    );
  }
}

export default withRouter(PartizanOpenSelling);
