import React, { Component } from "react";
import { connect } from "react-redux";
import withRouter from "react-router/es/withRouter";
import { Link } from "react-router-dom";
import { Tab, TabList, TabPanel, Tabs } from "react-tabs";

import Container from "../../components/UI/Container/Container";
import PageTitle from "../../components/UI/PageTitle/PageTitle";
import MobileHeaderChart from "../../components/Statistics/MobileHeaderChart/MobileHeaderChart";
import NewLoader from "../../components/NewLoader/NewLoader";
import {
  getEvent,
  getEventScanStats,
  getEventSellStats,
} from "../../store/actions/eventsActions";
import ROUTES from "../../constants/routes";

import "./Statistics.sass";
import "react-tabs/style/react-tabs.css";
import SellStatisticForm from "./sell/SellStatisticForm";
import ScanStatisticForm from "./scan/ScanStatisticForm";
import browserTabTitleOverride from "../../helpers/tabTitleOverride";
import { Breadcrumbs } from "../../components/Breadcrumbs/Breadcrumbs";

class StatisticPage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isScanOpened: false,
    };
  }

  componentDidMount() {
    browserTabTitleOverride();
    const {
      token,
      match: { params },
      currentEvent,
    } = this.props;
    let event_id = params.event_id || params.id;

    if (!currentEvent.id) {
      this.props.getEvent(event_id, token).then((res) => {
        if (res.isFound) {
          this.props.getEventSellStats(event_id, token);
        }
      });
    } else {
      this.props.getEventSellStats(event_id, token);
    }
  }

  handleScanClick = () => {
    if (!this.state.isScanOpened) {
      const {
        token,
        match: { params },
        currentEvent,
      } = this.props;
      let event_id = params.event_id || params.id;

      if (!currentEvent.id) {
        this.props
          .getEvent(event_id, token)
          .then((res) => {
            if (res.isFound) {
              this.props.getEventScanStats(event_id, token);
            }
          })
          .then(() => this.setState({ isScanOpened: true }));
      } else {
        this.props.getEventScanStats(event_id, token);
        this.setState({ isScanOpened: true });
      }
    }
  };

  render() {
    const { currentEvent, isLoading } = this.props;

    const fullPath = [
      {
        path: ROUTES.EVENTS,
        name: "Events",
      },
      {
        path: ROUTES.EVENT.replace(":id", currentEvent.id),
        name: currentEvent.name,
      },
      {
        name: "Statistics",
      },
    ];

    return (
      <Container className="stats">
        {isLoading ? <NewLoader isFullScreen /> : ""}
        <PageTitle>
          <Breadcrumbs routes={fullPath} />
        </PageTitle>

        <MobileHeaderChart>Statistics</MobileHeaderChart>

        <Tabs className="stats__tabs">
          <TabList>
            <Tab>Sell</Tab>
            <Tab onClick={this.handleScanClick}>Scan</Tab>
          </TabList>
          <TabPanel>
            <SellStatisticForm id={currentEvent.id} />
          </TabPanel>
          <TabPanel>
            <ScanStatisticForm id={currentEvent.id} />
          </TabPanel>
        </Tabs>
      </Container>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    token: state.auth.user.token,
    currentEvent: state.events.currentEvent,
    isLoading: state.events.loading,
  };
};

const mapDispatchToProps = (dispatch) => ({
  getEvent: (eventId, token) => dispatch(getEvent(eventId, token)),
  getEventSellStats: (eventId, token) =>
    dispatch(getEventSellStats(eventId, token)),
  getEventScanStats: (eventId, token) =>
    dispatch(getEventScanStats(eventId, token)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(StatisticPage));
