import React from 'react';

const TicketsImportStep = props => {
    if (props.currentStep !== props.step) {
        return null
    }
    return (
        <div>
            {props.children}
        </div>
    );
};

export default TicketsImportStep;