import React, {Component} from 'react';
import OutsideClickHandler from 'react-outside-click-handler';

import './Column.sass';

class Column extends Component {
    state = {
        isShowDropdown: false,
        selectedItem: this.props.selectedItem ? this.props.selectedItem : 'Choose field'
    };

    closeToggle = () => {
        this.props.clearColumn(this.props.accessor);
        this.setState({
            selectedItem: 'Choose field'
        })
    };

    onShowDropdown = () => {
        this.setState({
            isShowDropdown: !this.state.isShowDropdown
        });
    };

    clickHandler = item => {
        this.setState({
            selectedItem: item.fieldName,
            isShowDropdown: false
        });
    };

    dropdownItems = () => this.props.dropdown.map((item) => {
            return <p
                key={item.id}
                onClick={() => {this.props.clickDropdownItemHandler(item, this.props.accessor); this.clickHandler(item)}}
            >{item.fieldName}</p>
        }
    );

    render() {
        const {selectedItem, isShowDropdown} = this.state;
        const {title, toggleSubmenu, clearColumn} = this.props;
        return(
            <OutsideClickHandler
                onOutsideClick={() => {
                    this.setState({isShowDropdown: false})
                }}
            >
                <div>
                    <div className="column">
                        <p>{title || selectedItem}</p>
                        {
                            toggleSubmenu &&
                            (
                                <div
                                    className={`toggle-submenu ${isShowDropdown ? 'show' : ''}`}
                                    onClick={this.onShowDropdown}
                                >
                                </div>
                            )
                        }
                        {clearColumn && <button
                            className="close"
                            onClick={this.closeToggle}
                        /> }
                    </div>
                    {
                        isShowDropdown &&
                        (
                            <div className="dropdown">
                                {this.dropdownItems()}
                            </div>
                        )
                    }
                </div>
            </OutsideClickHandler>
        )
    }

};

export default Column;




