import React from 'react';
import {connect} from "react-redux";
import TicketsImportComponent from "./TicketsImportComponent";
import {
    getProviders,
    getProvidersEvents, importTickets,
    nextStep,
    prevStep,
    selectProvidersEvent, selectProvidersTicketTemplate, ticketsImportClearStore
} from "../../store/actions/ticketImportActions";


const mapStateToProps = state => ({
    token: state.auth.user.token,
    ticketsImport: state.ticketsImport,
});

const mapDispatchToProps = dispatch => ({
    nextStep: () => dispatch(nextStep),
    prevStep: () => dispatch(prevStep),
    ticketsImportClearStore: () => dispatch(ticketsImportClearStore),
    getProviders: token => dispatch(getProviders(token)),
    getProvidersEvents: (authData, token) => dispatch(getProvidersEvents(authData, token)),
    selectProvidersEvent: (event, enterXlEventId) => dispatch(selectProvidersEvent(event, enterXlEventId)),
    selectProvidersTicketTemplate: (ticketTemplate, enterXlTicketTemplateId) => dispatch(selectProvidersTicketTemplate(ticketTemplate, enterXlTicketTemplateId)),
    importTickets: (dataForImport, authData, token) => dispatch(importTickets(dataForImport, authData, token)),
});

const TicketsImportContainer = connect(
    mapStateToProps,
    mapDispatchToProps,
)(TicketsImportComponent);

export default TicketsImportContainer;
