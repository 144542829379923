import React from "react";

import { getSeatTextDescription } from "../../../helpers/getSeatTextDescription";

export const SeatsDescription = ({ seats }) => {
  if (seats && seats.length > 0) {
    return (
      <ul>
        {seats.map((seat) => (
          <li key={seat.id}>{getSeatTextDescription(seat, true)}</li>
        ))}
      </ul>
    );
  } else {
    return "-";
  }
};
