import React, { Component } from "react";

import { connect } from "react-redux";

import axios from "../../axios-instance";

import DatePicker from "react-datepicker/es";
import Select from "react-select";
import moment from "moment";

import Container from "../../components/UI/Container/Container";
import PageTitle from "../../components/UI/PageTitle/PageTitle";
import Icon from "../../components/UI/Icon/Icon";

import { getOptionsForAsyncSelect } from "../../helpers/getOptionsForAsyncSelect";
import { loadFile } from "../../helpers/loadFile";

import {
  getEvents,
  getReportByScanteamAtPeriod,
  getReportByTicketTemplatesAtEvent,
  setSelectedEvent,
  setTab,
} from "../../store/actions/statisticsActions";

import "./Statistics.sass";
import { Tab, TabList, TabPanel, Tabs } from "react-tabs";
import StatsInfo from "./StatsInfo/StatsInfo";
import { getHeaders } from "../../helpers/getHeaders";

class Statistics extends Component {
  state = {
    startDate: new Date(Date.now() - 24 * 60 * 60 * 1000),
    endDate: new Date(),
  };

  hadleChangeEvent = (value) => {
    this.props.setSelectedEvent(value);
  };

  handleChangeDate = (name, value) => {
    this.setState(
      {
        [name]: value,
      },
      () => this.getReportByScanteamAtPeriod()
    );
  };

  getReportByScanteamAtPeriod = (asCsv) => {
    const { endDate, startDate } = this.state;

    return this.props.getReportByScanteamAtPeriod(
      {
        fromTimeUtc: moment(startDate).toISOString(),
        toTimeUtc: moment(endDate).toISOString(),
      },
      this.props.user.token,
      asCsv
    );
  };

  getReportByTicketTemplatesAtEvent = (asCsv) =>
    this.props.getReportByTicketTemplatesAtEvent(
      this.props.selectedEvent.value,
      this.props.user.token,
      asCsv
    );

  setTab = (newTab) => {
    this.props.setTab(newTab);
    switch (newTab) {
      case 1:
        this.getReportByScanteamAtPeriod();
        break;
      default:
        this.getReportByTicketTemplatesAtEvent();
        break;
    }
  };

  downloadExcelReport = async (type) => {
    const eventsLoading = this.props.eventsLoading;

    if (eventsLoading || !this.props.selectedEvent) {
      return;
    }
    const { label: name, value: id } = this.props.selectedEvent;

    let fileName = name.toLowerCase().replaceAll(" ", "_");
    let ext = "csv";

    switch (type) {
      case "tickets-report":
        fileName += "_tickets_report";
        this.getReportByTicketTemplatesAtEvent(true).then((res) => {
          loadFile(
            res.data,
            `${fileName}_${moment().format("MM_DD_HH_mm")}.${ext}`
          );
        });
        break;
      case "transactions-report":
        fileName += "_transactions_report";
        axios
          .get(`/Events/${id}/TransactionReport`, {
            headers: getHeaders(this.props.user.token),
          })
          .then((res) => {
            loadFile(
              res.data,
              `${fileName}_${moment().format("MM_DD_HH_mm")}.${ext}`
            );
          });
        break;
      default:
        fileName = "scan_report";
        this.getReportByScanteamAtPeriod(true).then((res) => {
          loadFile(
            res.data,
            `${fileName}_${moment().format("MM_DD_HH_mm")}.${ext}`
          );
        });
        break;
    }
  };

  callApis = async () => {
    await this.props.getEvents();
  };

  componentDidMount() {
    this.callApis();
  }

  render() {
    const { endDate, startDate } = this.state;
    const {
      selectedEvent,
      events,
      selectedTab,
      loading,
      eventsLoading,
      scaningReports,
      sellingReports,
    } = this.props;

    return (
      <Container className="statistics">
        <PageTitle>Statistic</PageTitle>
        <div className="statistics__actions">
          <div className="statistics__actions_fields">
            {selectedTab === 0 ? (
              <Select
                value={selectedEvent}
                isLoading={eventsLoading}
                options={getOptionsForAsyncSelect(events, {
                  labelKey: "name",
                  valueKey: "id",
                })}
                onChange={this.hadleChangeEvent}
                classNamePrefix="custom-select"
                className="custom-select"
              />
            ) : (
              <>
                <DatePicker
                  selected={startDate}
                  onChange={(value) =>
                    this.handleChangeDate("startDate", value)
                  }
                  dateFormat="d MMM yyyy "
                />
                <DatePicker
                  selected={endDate}
                  onChange={(value) => this.handleChangeDate("endDate", value)}
                  dateFormat="d MMM yyyy "
                />
              </>
            )}
          </div>
          <div className="download-report">
            {selectedTab === 0 ? (
              <>
                <div
                  className="download-report__wrapper"
                  onClick={() => this.downloadExcelReport("tickets-report")}
                >
                  <Icon name="excel" />
                  <button className="download-report__button">
                    Tickets Report
                  </button>
                </div>
                <div
                  className="download-report__wrapper"
                  onClick={() =>
                    this.downloadExcelReport("transactions-report")
                  }
                >
                  <Icon name="excel" />
                  <button className="download-report__button">
                    Transactions Report
                  </button>
                </div>
              </>
            ) : (
              <div
                className="download-report__wrapper"
                onClick={this.downloadExcelReport}
              >
                <Icon name="excel" />
                <button className="download-report__button">
                  Scanning Report
                </button>
              </div>
            )}
          </div>
        </div>
        <Tabs selectedIndex={selectedTab} onSelect={this.setTab}>
          <TabList>
            <Tab>Selling report</Tab>
            <Tab>Scanning report</Tab>
          </TabList>
          <TabPanel>
            <StatsInfo
              selectedTab={selectedTab}
              data={sellingReports}
              loading={loading}
            />
          </TabPanel>
          <TabPanel>
            <StatsInfo
              selectedTab={selectedTab}
              data={scaningReports}
              loading={loading}
            />
          </TabPanel>
        </Tabs>
      </Container>
    );
  }
}

const mapStateToProps = ({ statistics, auth: { user } }) => ({
  ...statistics,
  user,
});

const mapDispatchToProps = (dispatch) => ({
  getEvents: () => dispatch(getEvents()),
  setSelectedEvent: (value) => dispatch(setSelectedEvent(value)),
  setTab: (tab) => dispatch(setTab(tab)),
  getReportByTicketTemplatesAtEvent: (eventId, token, asCsv) =>
    dispatch(getReportByTicketTemplatesAtEvent(eventId, token, asCsv)),
  getReportByScanteamAtPeriod: (request, token, asCsv) =>
    dispatch(getReportByScanteamAtPeriod(request, token, asCsv)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Statistics);
