import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import { toast } from "react-toastify";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";

// import Swal from "sweetalert2";
// import withReactContent from "sweetalert2-react-content";

import axios from "../../axios-instance";

import Container from "../../components/UI/Container/Container";
import PageTitle from "../../components/UI/PageTitle/PageTitle";

import ROUTES from "../../constants/routes";
import Page404 from "../../components/Page404/Page404";

import Link from "react-router-dom/es/Link";

import { clearCurrentEvent, getEvent } from "../../store/actions/eventsActions";

import "./TicketActions.sass";
import Upload from "../Upload/Upload";
import { getHeaders } from "../../helpers/getHeaders";
import { loadFile } from "../../helpers/loadFile";
import CheckBox from "../../components/UI/Checkbox/CheckBox";
import PleaseWaitModal from "../BarCodes/modals/PleaseWaitModal";
import SingleCheckboxSelector from "../../components/SingleCheckboxSelector";
import { isSunday } from "date-fns";
import { executeAsyncWithModal } from "../../helpers/modal";
import { Breadcrumbs } from "../../components/Breadcrumbs/Breadcrumbs";

const MySwal = withReactContent(Swal);

// const MySwal = withReactContent(Swal);

const EVENT_ACTIONS = [
  {
    value: "/Events/AddSeatsForSale",
    label: "Add seats from the file to existing seats",
  },
  {
    value: "/Events/SetSeatsForSale",
    label: "Replace existing seats with seats from the file",
  },
  {
    value: "/Events/ExcludeSeatsForSale",
    label: "Remove seats from the file from existing seats",
  },
];

class UploadAvailableSeats extends Component {
  state = {
    file: [],
    isFound: true,
    selectedEventActionPath: EVENT_ACTIONS[0].value,
  };

  componentDidMount() {
    const {
      user: { token },
      match: { params },
      getEvent,
    } = this.props;

    if (!this.props.currentEvent.id)
      getEvent(params.id, token).then((result) => {
        if (!result.isFound) {
          this.setState({ isFound: false });
        }
      });
  }
  onButtonEnable = (file) => {
    this.setState({
      file,
    });
  };

  downloadTemplateHandler = async () => {
    try {
      if (!navigator.onLine) {
        throw new Error("offline");
      }

      const url =
        process.env.API_URL.substring(0, process.env.API_URL.length - 2) +
        "Files/Static/csv/AvailableSeats.csv";

      const { data } = await executeAsyncWithModal(
        () => axios.get(url),
        <PleaseWaitModal message="Downloading file…" />
      );

      this.downloadDataAsFile(data, "AvailableSeatsTemplate.csv");
    } catch (e) {
      toast.error("File was not downloaded");
      console.log(e);
    }
  };

  downloadAvailableSeatsHandler = async () => {
    try {
      if (!navigator.onLine) {
        throw new Error("offline");
      }

      const user = JSON.parse(localStorage.getItem("user"));
      const { currentEvent } = this.props;

      const request = () =>
        axios.get(`Events/${currentEvent.id}/SeatsForSale`, {
          headers: getHeaders(user.token),
        });

      const { data } = await executeAsyncWithModal(
        request,
        <PleaseWaitModal message="Downloading file…" />
      );

      this.downloadDataAsFile(data, `Seats ${currentEvent.name}.csv`);
    } catch (e) {
      toast.error("File was not downloaded");
      console.log(e);
    }
  };

  selectedEventActionChangeHandler = (evt) => {
    this.setState({ selectedEventActionPath: evt.target.name });
  };

  closeFileHandler = () => {
    this.setState({ selectedEventActionPath: EVENT_ACTIONS[0].value });
  };

  removeAllRestrictionsHandler = () =>
    MySwal.fire({
      title: "Are you sure you want to de-restrict?",
      text: "This action will make all seats available",
      showCancelButton: true,
      cancelButtonText: "Cancel",
      showConfirmButton: true,
      confirmButtonText: "De-restrict",
      reverseButtons: true,
    }).then(async (result) => {
      if (result.value) {
        const { isSuccess } = await this.uploadFile(
          "/Events/SetAllSeatsForSale"
        );

        if (isSuccess) {
          toast.success("Restriction removed successfully");
        } else {
          toast.error("Restriction was not removed");
        }
      }
    });

  handleSubmit = async () => {
    const { file } = this.state;

    const body = new FormData();
    body.append("file", file[0]);

    const { isSuccess, data } = await executeAsyncWithModal(
      () => this.uploadFile(this.state.selectedEventActionPath, body),
      <PleaseWaitModal message="Downloading file…" />
    );

    if (isSuccess) {
      this.downloadDataAsFile(data, "AvailableSeats.csv");
    } else {
      toast.error("File was not downloaded");
    }
  };

  uploadFile = async (eventActionPath, body = null) => {
    //TODO: Should use redux!!!
    let result = {
      data: null,
      isSuccess: false,
    };

    if (!navigator.onLine) {
      return result;
    }

    try {
      const {
        match: { params },
      } = this.props;

      const user = JSON.parse(localStorage.getItem("user"));
      const url = `${eventActionPath}/${params.id}`;

      const { data } = await axios.post(url, body, {
        headers: getHeaders(user.token),
      });

      result = {
        data: data,
        isSuccess: true,
      };
    } catch (e) {
      console.log(e);
    }

    return result;
  };

  downloadDataAsFile = (data, name) => {
    if (data) {
      loadFile(data, name);

      toast.success("File uploaded successfully");
    } else {
      toast.error("Failed to retrieve data");
    }
  };

  checkIsDisabled = () => {
    const { file } = this.state;

    return !file || !file.length;
  };

  render() {
    if (!this.state.isFound) {
      return <Page404 />;
    }

    const { currentEvent } = this.props;

    const fullPath = [
      {
        path: ROUTES.EVENTS,
        name: "Events",
      },
      {
        path: ROUTES.EVENT.replace(":id", currentEvent.id),
        name: currentEvent.name,
      },
      {
        name: "Manage seats",
      },
    ];

    return (
      <Container className="ticket-action">
        <PageTitle>
          <Breadcrumbs routes={fullPath} />
        </PageTitle>

        <a
          className="seats-io-link"
          target="__balnk"
          href={`${process.env.REACT_APP_SEATS_IO_HIDDEN_LINK}-${currentEvent.id}/forsale`}
        >
          Go to Seats Io (hidden)
        </a>
        <a
          className="seats-io-link"
          target="__balnk"
          href={`${process.env.REACT_APP_SEATS_IO_PUBLIC_LINK}-${currentEvent.id}/forsale`}
        >
          Go to Seats Io (public)
        </a>
        <div className="download-actions-container">
          <div className="ticket-action__download download-action">
            <button onClick={this.downloadTemplateHandler}>
              Download template
            </button>
          </div>
          <div className="ticket-action__download download-action">
            <button onClick={this.downloadAvailableSeatsHandler}>
              Download available seats
            </button>
          </div>
        </div>
        <div className="ticket-action__upload">
          <div className="ticket-action__choose-file">
            <p>Choose the file ( .csv)</p>

            <Upload
              onClose={this.closeFileHandler}
              onButtonEnable={this.onButtonEnable}
            />
          </div>
        </div>

        {!this.checkIsDisabled() && (
          <SingleCheckboxSelector
            className="event-action-selector"
            items={EVENT_ACTIONS}
            selectedValue={this.state.selectedEventActionPath}
            onChange={this.selectedEventActionChangeHandler}
          />
        )}

        <div className="bottom-buttons-container">
          <button
            type="button"
            className="ticket-action__de-restrict btn-red-outline"
            onClick={this.removeAllRestrictionsHandler}
          >
            De-restrict
          </button>

          <button
            type="button"
            disabled={this.checkIsDisabled()}
            onClick={this.handleSubmit}
            className="ticket-action__submit btn-primary"
          >
            Send
          </button>
        </div>
      </Container>
    );
  }
}

const mapStateToProps = ({ auth: { user }, events: { currentEvent } }) => ({
  user,
  currentEvent,
});

const mapDispatchToProps = (dispatch) => ({
  getEvent: (eventId, token) => dispatch(getEvent(eventId, token)),
  clearCurrentEvent: () => dispatch(clearCurrentEvent()),
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(UploadAvailableSeats)
);
