import React from "react";
import { fee, hmac_key } from "../constants";
import axios from "../../../axios-instance";
import crypto from "crypto-js";

export const createMerchantSin = (pairs, pairsKeys = "") => {
  let keys = pairsKeys ? pairsKeys.split(",") : Object.keys(pairs);
  keys = keys.sort();

  const signData = keys;

  keys.forEach((key) => {
    const val = pairs[key].toString();
    signData.push(val.replace(/\\/g, "\\\\").replace(/:/g, "\\:"));
  });

  return crypto.enc.Base64.stringify(
    crypto.HmacSHA256(signData.join(":"), crypto.enc.Hex.parse(hmac_key))
  );
};

export const getSelectOptions = async (type, value) => {
  const { data } = await axios.get(`/Payment/place/${type}`);
  return data;
};

export const createSelectOption = (value) => ({
  label: value,
  value,
});

export const handleSelectPlace = (name, value) => {
  switch (name) {
    case "passType":
      return () =>
        getSelectOptions("pass-type").then((data) =>
          data.map((value) => ({
            amount: value.amount,
            ...createSelectOption(value.name),
          }))
        );
    default:
      return () =>
        getSelectOptions(name).then((data) => data.map(createSelectOption));
  }
};

export const getPriceView = (price) => {
  if ((price ^ 0) === price) return price + ".00";

  if (price.toFixed(1) === String(price)) return price + "0";

  return price;
};

export const getTotal = (price, currentFee = fee) => {
  let total = +price;
  const totalFee = total * currentFee;

  total = total + totalFee;

  return getPriceView(+total.toFixed(2));
};

export const renderTotal = ({
  currencyCode,
  price,
  total,
  currentFee = fee,
  otherFields = [],
}) => {
  const feeRate = (currentFee * 100).toFixed(1) + "%";
  return (
    <div className="seasonpass__total">
      <div>
        <span className="seasonpass__total-text">Price:</span>
        <span>
          {price} {currencyCode}
        </span>
      </div>
      {otherFields.map(({ title, text }) => (
        <div key={title + text}>
          <span className="seasonpass__total-text">{title}</span>
          <span>{text}</span>
        </div>
      ))}
      {feeRate > 0 &&
        <div>
          <span className="seasonpass__total-text">Booking fee:</span>
          <span>{feeRate}</span>
        </div>
      }
      <div>
        <span className="seasonpass__total-text">Total:</span>
        <span>
          {total} {currencyCode}
        </span>
      </div>
    </div>
  );
};
