import React, { useState } from "react";
import Icon from "../../../components/UI/Icon/Icon";

const Ticket = ({
  ticket,
  canSelect,
  onObjectDeselected,
  onObjectSelected,
  currency,
  showAction
}) => {
  const [count, setCapacity] = useState(0);

  const handleSelect = (type) => {
    switch (type) {
      case "add":
        if (!canSelect) {
          return;
        }
        if (ticket.allowedToSell >= count + 1) {
          setCapacity(count + 1);
          onObjectSelected({ ...ticket, count: count + 1 });
        }
        break;
      case "remove":
        if (count - 1 > 0) {
          setCapacity(count - 1);
          onObjectSelected({ ...ticket, count: count - 1 });
        } else {
          setCapacity(0);
          onObjectDeselected(ticket);
        }
        break;
      default:
        break;
    }
  };

  if (showAction && (!ticket.allowedToSell || !ticket.capacity)) {
    return (
      <div className="partizan-open-selling__select-section-ticket partizan-open-selling__select-section-ticket-unavailable">
        <p>{ticket.name}</p>
        <p>Unavailable</p>
      </div>
    );
  }

  return (

    <div className="partizan-open-selling__select-section-ticket" style={{display: 'flex', flexDirection: 'row' }}>
      <img src={ticket.imgUrl} style={{maxWidth: 150, marginRight: '16px' }}/>
      <div style={{display: 'flex', flexDirection: 'column', justifyContent: 'space-between', width: '100%' }}>
        {showAction ? (
          <>
            <div style={{display: 'flex', justifyContent: 'space-between' }}>
              <p className="partizan-open-selling__text-normal-small">
                {ticket.name}
              </p>
              <p className="partizan-open-selling__text-bold">
                {ticket.price} {currency}
              </p>
            </div>
            <div style={{display: 'flex', WebkitJustifyContent: 'end' }}>
              <div className="partizan-open-selling__select-section-ticket-count">
                <button type="button" onClick={() => handleSelect("remove")}>
                  <Icon name="minus" />
                </button>
                <span className="partizan-open-selling__select-section-ticket-count">
                  {count}
                </span>
                <button type="button" onClick={() => handleSelect("add")}>
                  <Icon name="plus" />
                </button>
              </div>
            </div>
          </>
        ) : (
          <>
            <div style={{display: 'flex', justifyContent: 'space-between' }}>
              <p className="partizan-open-selling__text-normal-small">
                {ticket.name}
              </p>
              <p className="partizan-open-selling__text-bold">
                {ticket.price} {currency}
              </p>
            </div>
            <div />
          </>
        )}
      </div>
    </div>
  );
};

export const TicketsPicker = ({
  ticketTemplates,
  showAction = true,
  canSelect = true,
  onObjectDeselected,
  onObjectSelected,
  currency,
}) => {
  return (
    <>
      {ticketTemplates.map((ticket) => (
        <Ticket
          showAction={showAction}
          canSelect={canSelect}
          key={ticket.ticketTemplateId}
          ticket={ticket}
          onObjectDeselected={onObjectDeselected}
          onObjectSelected={onObjectSelected}
          currency={currency}
        />
      ))}
    </>
  );
};
