import React, { Component } from "react";

import "./SellerInfoModal.sass";
import "../SeasonPass.sass";

class SellerInfoModal extends Component {
  state = {
    sellerPin: "",
    sellerName: "",
  };

  handleChange = (value, name) => {
    this.setState({ [name]: value });
  };

  handleClose = () => {
    localStorage.setItem("sellerInfo", JSON.stringify(this.state));
    this.props.onClose();
  };

  render() {
    const { sellerName, sellerPin } = this.state;

    return (
      <div className="seller-info-modal">
        <label className="seasonpass__field">
          <span className="seasonpass__field-text">
            <span className="seasonpass__field-text-label">Your Name</span>
          </span>
          <input
            className={"seasonpass__field-input"}
            name="sellerName"
            onChange={(e) => this.handleChange(e.target.value, "sellerName")}
            type="text"
            value={sellerName}
          />
        </label>
        <label className="seasonpass__field">
          <span className="seasonpass__field-text">
            <span className="seasonpass__field-text-label">Your Pin</span>
          </span>
          <input
            className={"seasonpass__field-input"}
            name="sellerPin"
            onChange={(e) => this.handleChange(e.target.value, "sellerPin")}
            type="text"
            value={sellerPin}
          />
        </label>
        <button
          type="button"
          onClick={this.handleClose}
          className="btn-primary seasonpass__btn"
          disabled={!sellerName || !sellerPin}
        >
          Save
        </button>
      </div>
    );
  }
}

export default SellerInfoModal;
