import * as actionTypes from "../actionTypes/canvasActionTypes";
import { toast } from "react-toastify";
import Swal from "sweetalert2";
import axios from "../../axios-instance";
import { getHeaders } from "../../helpers/getHeaders";
import { getPdfTemplatesAsyncFunc } from "../../helpers/getPdfTemplatesAsyncFunc";
import {
  DEFAULT_CANVAS_FOOTER_IMAGE_OBJECT,
  DEFAULT_CANVAS_QRCODE_OBJECT,
  DEFAULT_TEAMPLAE,
} from "../../constants/canvas";
import { getPdfTemplateTextValuesAsyncFunc } from "../../helpers/getPdfTemplateTextValuesAsyncFunc";
import getImageElementFromUrl from "../../helpers/getImageElementFromUrl";
import { loadFile } from "../../helpers/loadFile";
import { getObjectsOrder } from "../reducers/canvasReducer";
import { getFooterImagePdfTemplate } from "../../helpers/getFooterImagePdfTemplate";

const getTemplateStart = () => ({ type: actionTypes.GET_TEMPLATE });

const getTemplateSuccess = (obj) => ({
  type: actionTypes.GET_TEMPLATE_SUCCESS,
  payload: obj,
});

const getTemplateFail = () => ({
  type: actionTypes.GET_TEMPLATE_FAIL,
});

const saveTemplateSuccess = () => ({
  type: actionTypes.SAVE_TEMPLATE_SUCCESS,
});

const getTemplatesStart = () => ({ type: actionTypes.GET_TEMPLATES });

const getTemplatesSuccess = (templates) => ({
  type: actionTypes.GET_TEMPLATES_SUCCESS,
  payload: templates,
});

const getTemplatesFail = () => ({
  type: actionTypes.GET_TEMPLATES_FAIL,
});

const getTemplateTextValuesStart = () => ({
  type: actionTypes.GET_TEMPLATE_TEXT_VALUES,
});

const getTemplateTextValuesSuccess = (textValues) => ({
  type: actionTypes.GET_TEMPLATE_TEXT_VALUES_SUCCESS,
  payload: textValues,
});

const getTemplateTextValuesFail = () => ({
  type: actionTypes.GET_TEMPLATE_TEXT_VALUES_FAIL,
});

export const getTemplate = (pdfTemplateId, userToken) => (dispatch) => {
  dispatch(getTemplateStart());
  return axios
    .get(`/PdfTemplate/${pdfTemplateId}`, {
      headers: getHeaders(userToken),
    })
    .then((response) => {
      if (response.data.isSuccess) {
        return response.data.result;
      }
      Swal({
        title: "Error!",
        text: response.data.errorMessage,
        type: "error",
        timer: 3000,
      });
      throw new Error(response.data.errorMessage);
    })
    .then(async (result) => {
      let data = [];

      if (result.json) {
        const resultJson = JSON.parse(result.json);
        if (Array.isArray(resultJson)) {
          const mappedData = await Promise.all(
            resultJson.map(async (obj) => {
              if (obj.type === DEFAULT_CANVAS_FOOTER_IMAGE_OBJECT.type) {
                return {
                  ...obj,
                  imageElement: await getFooterImagePdfTemplate(),
                };
              }

              if (
                obj.type.toLowerCase().includes("image") ||
                obj.type === "qrCode"
              ) {
                return {
                  ...obj,
                  imageElement: await getImageElementFromUrl(obj.imageUrl),
                };
              }
              return obj;
            })
          );

          data = getObjectsOrder(mappedData);
        }
      }

      dispatch(getTemplateSuccess(data));
      return result;
    })
    .catch((err) => {
      dispatch(getTemplateFail());
    });
};

export const getTemplateTextValues = () => (dispatch) => {
  dispatch(getTemplateTextValuesStart());
  return getPdfTemplateTextValuesAsyncFunc()("")
    .then((textValues) => {
      dispatch(getTemplateTextValuesSuccess(textValues));
    })
    .catch((e) => {
      dispatch(getTemplateTextValuesFail());
    });
};

export const getTemplates = () => (dispatch) => {
  dispatch(getTemplatesStart());
  return getPdfTemplatesAsyncFunc(
    true,
    DEFAULT_TEAMPLAE
  )("")
    .then((templates) => {
      dispatch(getTemplatesSuccess(templates));
    })
    .catch((e) => {
      dispatch(getTemplatesFail());
    });
};

export const saveTemplate = (data, userToken) => (dispatch) => {
  dispatch(getTemplateStart());
  return axios
    .post(`/PdfTemplate`, data, {
      headers: getHeaders(userToken),
    })
    .then((response) => {
      if (response.data.isSuccess) {
        toast.success("Template successfully saved");
        dispatch(getTemplates());
        dispatch(saveTemplateSuccess());
        return response.data.result;
      }
      Swal({
        title: "Error!",
        text: response.data.errorMessage,
        type: "error",
        timer: 3000,
      });
      throw new Error(response.data.errorMessage);
    })
    .catch((err) => {
      dispatch(getTemplateFail());
    });
};

export const editTemplate = (data, pdfTemplateId, userToken) => (dispatch) => {
  dispatch(getTemplateStart());
  return axios
    .put(`/PdfTemplate/${pdfTemplateId}`, data, {
      headers: getHeaders(userToken),
    })
    .then((response) => {
      if (response.data.isSuccess) {
        toast.success("Template successfully edited");
        dispatch(getTemplates());
        dispatch(saveTemplateSuccess());
        return response.data;
      }
      Swal({
        title: "Error!",
        text: response.data.errorMessage,
        type: "error",
        timer: 3000,
      });
      throw new Error(response.data.errorMessage);
    })
    .catch((err) => {
      dispatch(getTemplateFail());
    });
};

export const downloadPdf = (data, userToken) => () => {
  return axios
    .post("/PdfTemplate/SampleByRequest", data, {
      headers: getHeaders(userToken),
      responseType: "blob",
    })
    .then((res) => {
      const file = new Blob([res.data], { type: "application/pdf" });
      loadFile(file, `${data.name}.pdf`);
    })
    .catch((error) => {
      console.error("Error downloading the PDF", error);
    });
};

export const deleteTemplate = (pdfTemplateId, userToken) => (dispatch) => {
  dispatch(getTemplateStart());
  return axios
    .delete(`/PdfTemplate/${pdfTemplateId}`, {
      headers: getHeaders(userToken),
    })
    .then((response) => {
      if (response.data.isSuccess) {
        dispatch(setCurrentTemplate(DEFAULT_TEAMPLAE));
        dispatch(getTemplates());
        return;
      }
      Swal({
        title: "Error!",
        text: response.data.errorMessage,
        type: "error",
        timer: 3000,
      });
      throw new Error(response.data.errorMessage);
    })
    .catch((err) => {
      dispatch(getTemplateFail());
    });
};

export const setCurrentTemplate = (template) => (dispatch) =>
  dispatch({
    type: actionTypes.SET_TEMPLATE,
    payload: template,
  });

export const resetZoom = () => (dispatch) =>
  dispatch({
    type: actionTypes.RESET_ZOOM,
  });

export const setZoom = (zoom) => (dispatch) =>
  dispatch({
    type: actionTypes.SET_ZOOM,
    payload: zoom,
  });

export const setCanvasLoading = (isLoading) => (dispatch) =>
  dispatch({
    type: actionTypes.SET_CANVAS_LOADING,
    payload: isLoading,
  });

export const decrementZoom = (zoom) => (dispatch) =>
  dispatch({
    type: actionTypes.DECREMENT_ZOOM,
    payload: zoom,
  });

export const incrementZoom = (zoom) => (dispatch) =>
  dispatch({
    type: actionTypes.INCREMENT_ZOOM,
    payload: zoom,
  });

export const appendRectangleObject = (object) => (dispatch) => {
  const objectType = "rectangle";
  dispatch({
    type: actionTypes.APPEND_RECTANGLE_OBJECT,
    payload: { objectType, object },
  });
};

export const appendEllipseObject = (object) => (dispatch) => {
  const objectType = "ellipse";
  dispatch({
    type: actionTypes.APPEND_ELLIPSE_OBJECT,
    payload: { objectType, object },
  });
};

export const appendFreeDrawObject = (object) => (dispatch) => {
  const objectType = "free-draw";
  dispatch({
    type: actionTypes.APPEND_FREE_DRAW_OBJECT,
    payload: { objectType, object },
  });
};

export const appendTextObject = (object) => (dispatch) => {
  const objectType = "text";
  dispatch({
    type: actionTypes.APPEND_TEXT_OBJECT,
    payload: { objectType, object },
  });
};

export const appendIconObject = (object) => (dispatch) => {
  const objectType = "icon";
  dispatch({
    type: actionTypes.APPEND_ICON_OBJECT,
    payload: { objectType, object },
  });
};

export const appendImageObject = (object) => (dispatch) => {
  const objectType = "image";
  dispatch({
    type: actionTypes.APPEND_IMAGE_OBJECT,
    payload: { objectType, object },
  });
};

export const appendFooterImageObject = (object) => (dispatch) => {
  const objectType = DEFAULT_CANVAS_FOOTER_IMAGE_OBJECT.type;
  dispatch({
    type: actionTypes.APPEND_FOOTER_OBJECT,
    payload: { objectType, object },
  });
};

export const appendQRCodeObject = (object) => (dispatch) => {
  const objectType = DEFAULT_CANVAS_QRCODE_OBJECT.type;
  dispatch({
    type: actionTypes.APPEND_QRCODE_OBJECT,
    payload: { objectType, object },
  });
};

export const updateCanvasObject = (id, object) => (dispatch) => {
  dispatch({
    type: actionTypes.UPDATE_CANVAS_OBJECT,
    payload: { id, object },
  });
};

export const appendFreeDrawPointToCanvasObject = (id, point) => (dispatch) => {
  dispatch({
    type: actionTypes.APPEND_FREE_DRAW_POINT_TO_CANVAS_OBJECT,
    payload: { id, point },
  });
};

export const deleteCanvasObject = (id) => (dispatch) => {
  dispatch({
    type: actionTypes.DELETE_CANVAS_OBJECT,
    payload: id,
  });
};

export const moveCanvasObject =
  ({ id, deltaPosition }) =>
  (dispatch) => {
    dispatch({
      type: actionTypes.MOVE_CANVAS_OBJECT,
      payload: { id, deltaPosition },
    });
  };

export const resizeCanvasObject =
  ({ id, actionModeOption, delta }) =>
  (dispatch) => {
    dispatch({
      type: actionTypes.RESIZE_CANVAS_OBJECT,
      payload: { id, actionModeOption, delta },
    });
  };

export const setCanvasObjectLayerIndex = (id, layerIndex) => (dispatch) => {
  dispatch({
    type: actionTypes.SET_CANVAS_OBJECT_LAYER_INDEX,
    payload: { id, layerIndex },
  });
};

export const setCanvasObjectLayerToTop = (id) => (dispatch) => {
  dispatch({
    type: actionTypes.SET_CANVAS_OBJECT_LAYER_TO_TOP,
    payload: { id },
  });
};

export const setCanvasObjectLayerToBottm = (id) => (dispatch) => {
  dispatch({
    type: actionTypes.SET_CANVAS_OBJECT_LAYER_TO_BOTTOM,
    payload: { id },
  });
};

export const resetCanvasObjects = () => (dispatch) => {
  dispatch({
    type: actionTypes.RESET_CANVAS_OBJECTS,
  });
};

export const setActiveObjectId = (activeObjectId) => (dispatch) => {
  dispatch({
    type: actionTypes.SET_ACTIVE_OBJECT_ID,
    payload: activeObjectId,
  });
};

export const setCanvasWorkingWidth = (width) => (dispatch) => {
  dispatch({
    type: actionTypes.SET_CANVAS_WORKING_WIDTH,
    payload: width,
  });
};

export const setCanvasWorkingHeight = (height) => (dispatch) => {
  dispatch({
    type: actionTypes.SET_CANVAS_WORKING_HEIGHT,
    payload: height,
  });
};

export const setDefaultParams = (params) => (dispatch) => {
  dispatch({
    type: actionTypes.SET_DEFAULT_PARAMS,
    payload: params,
  });
};

export const setScrollPosition = (scrollPosition) => (dispatch) => {
  dispatch({
    type: actionTypes.SET_SCROLL_POSITION,
    payload: scrollPosition,
  });
};

export const updateScrollPosition = (delta) => (dispatch) => {
  dispatch({
    type: actionTypes.UPDATE_SCROLL_POSITION,
    payload: delta,
  });
};

export const setUserMode = (userMode) => (dispatch) => {
  dispatch({
    type: actionTypes.SET_USER_MODE,
    payload: userMode,
  });
};

export const setActionMode = (params) => (dispatch) => {
  dispatch({
    type: actionTypes.SET_ACTION_MODE,
    payload: params,
  });
};
