import React, { useEffect, useState } from "react";
import { withRouter } from "react-router";
import { connect } from "react-redux";
import axios from "../../../axios-instance";
import { toast } from "react-toastify";
import queryString from "query-string";

import Container from "../../../components/UI/Container/Container";
// import PartizanOpenSellingLogo from "../../../images/partizan-open-selling-logo.svg";
import OpenEnvelope from "../../../images/open-envelope.png";
import EmailSent from "../../../images/email-sent.png";

import { InputValidation } from "../../../components/UI/InputValidation/InputValidation";
import { isValidEmail } from "../../../helpers/validators";

import "./SeasonPassMember.sass";
import { seasonPassMemberLangs } from "../../../constants/seasonPassMemberLangs";
import ROUTES from "../../../constants/routes";

export const SeasonPassMember = ({ match, history }) => {
  const [isEmailSent, setIsEmailSent] = useState(false);
  const [email, setEmail] = useState("");
  // const [streetAndHouseNumber, setStreetAndHouseNumber] = useState("");
  // const [city, setCity] = useState("");
  // const [postalCode, setPostalCode] = useState("");
  const [validEmail, setValidEmail] = useState(true);
  const [defaultLang, setDefaultLang] = useState("en");

  const isAcceptableEmail = () => isValidEmail(email) || email === "";

  const handleLang = () => {
    const acceptedLangs = Object.keys(seasonPassMemberLangs);
    const lang = match.params.lang;

    if (!lang || !acceptedLangs.some((key) => key === lang)) {
      history.replace(ROUTES.SEASON_PASS_MEMBER.replace(":lang?", ""));
      setDefaultLang("en");
      return;
    }

    setDefaultLang(lang);
  };

  const lostFocusEmailInput = () => {
    const valid = isAcceptableEmail();

    setValidEmail(valid);
  };

  const changeInputEmailHandler = (e) => {
    setEmail(e.target.value);
  };
  // const changeInputStreetAndHouseNumberHandler = (e) => {
  //   setStreetAndHouseNumber(e.target.value);
  // };
  // const changeInputCityHandler = (e) => {
  //   setCity(e.target.value);
  // };
  // const changeInputPostalCodeHandler = (e) => {
  //   setPostalCode(e.target.value);
  // };

  const changeEmailHandler = (e) => {
    setIsEmailSent(false);
  };

  const confirmEmailHandler = (e) => {
    const query = queryString.stringify({ email }, { encode: false });
    const token = localStorage.getItem('user_' + email.toLowerCase().trim());
    if (token)
    {
      axios
      .get(`/SeasonPassMember?accessToken=${token}`)
      .then((response) => {
        if (response.status === 200 && response.data) {
          window.open(ROUTES.MEMBER + `?token=${token}&lang=${defaultLang}`, "_blank");
          return;
        }

        localStorage.removeItem('user_' + email.toLowerCase().trim());
      })
      .catch((err) => {
        localStorage.removeItem('user_' + email.toLowerCase().trim());
      });
    }
    else
    {
      axios
      .post(`/SeasonPassMember/request-to-login?${query}&lang=${defaultLang}`)
      .then((response) => {
        if (response.status === 200) {
          setIsEmailSent(true);
          return;
        }

        throw new Error(response.data.errorMessage);
      })
      .catch((err) => {
        toast.error("Something went wrong");
      });
    }
  };

  useEffect(() => {
    handleLang();
  }, []);

  const { emailSent, emailConfirmation } = seasonPassMemberLangs[defaultLang];

  return (
    <Container className="seasonpass seasonpass-member">
      {/* <div className="seasonpass-member__logo seasonpass-member__header-logo-wrapper">
        <img src={PartizanOpenSellingLogo} alt="Logo" />
        <h2>Fancee Tickets</h2>
      </div> */}
      {isEmailSent ? (
        <div className="seasonpass-member__block">
          <img style={{width: 120 }} src={EmailSent} alt="open envelope" />
          <h2 className="title">{emailSent.title}</h2>
          <span className="about">{emailSent.about}</span>
          <div className="seasonpass-member__actions">
            <button
              className="btn-primary white-button"
              onClick={changeEmailHandler}
            >
              {emailSent.btnChange}
            </button>
            <button className="btn-primary" onClick={confirmEmailHandler}>
              {emailSent.btnReset}
            </button>
          </div>
        </div>
      ) : (
        <div className="seasonpass-member__block">
          <img style={{width: 120 }} src={OpenEnvelope} alt="open envelope" />
          <h2 className="title">{emailConfirmation.title}</h2>
          <span className="about">{emailConfirmation.about}</span>
          {/* <InputValidation
            value={streetAndHouseNumber}
            placeholder={emailConfirmation.inputStreetAndHouseNumberPlaceholder}
            className="input-email"
            onChange={changeInputStreetAndHouseNumberHandler}
          />
          <InputValidation
            value={city}
            placeholder={emailConfirmation.inputCityPlaceholder}
            className="input-email"
            onChange={changeInputCityHandler}
          />
          <InputValidation
            value={postalCode}
            placeholder={emailConfirmation.inputPostalCodePlaceholder}
            className="input-email"
            onChange={changeInputPostalCodeHandler}
          /> */}
          <InputValidation
            value={email}
            placeholder={emailConfirmation.inputEmailPlaceholder}
            isValid={validEmail}
            errorMessage={emailConfirmation.inputEmailErrorMessage}
            className="input-email"
            onChange={changeInputEmailHandler}
            onBlur={lostFocusEmailInput}
          />
          <div className="seasonpass-member__actions">
            <button
              disabled={!validEmail}
              onClick={confirmEmailHandler}
              className="btn-primary"
            >
              {emailConfirmation.btnConfirm}
            </button>
          </div>
        </div>
      )}
    </Container>
  );
};

const mapStateToProps = () => {};
const mapDispatchToProps = (dispatch) => {};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(SeasonPassMember)
);
