import React from "react";

import Icon from "../../UI/Icon/Icon";
import { IconButton } from "../../UI/IconButton/IconButton";
import { useShowActionsMenu } from "../../UI/ActionsMenu/hooks/useShowActionsMenu";

export const MembershipManagmentAction = ({
  maximizingOptions,
  data,
  showApproveModal,
  copyMemberPageLink,
  editMemberData,
  showRejectModal
}) => {
  const [showMenuActions] = useShowActionsMenu();

  const handleAccordionClick = (e, data) => {
    if (data.seats && data.seats.length > 1) {
      e.target.parentNode.parentNode.parentNode.parentNode.classList.toggle(
        "active"
      );
    }
  };

  const isApprovedTransaction = data.status === 3;
  const isPendingTransaction = data.status === 2;

  const actionButtonClassName = isPendingTransaction
    ? "btn-green IconButton green"
    : "";

  const actionButtonIconName = isPendingTransaction ? "approve" : "";

  //   const accordionClassName = "IconButton accordion-btn";

  const menuItems = [];

  if (!maximizingOptions) {
    menuItems.push({
      text: "Aprrove",
      onClick: showApproveModal,
      data: data,
    });
  }

  menuItems.push({
    text: "Copy member link",
    onClick: copyMemberPageLink,
    data,
  });
  menuItems.push({
    text: "Edit",
    onClick: editMemberData,
    data,
  });
  menuItems.push({
    text: "Reject",
    onClick: showRejectModal,
    data,
  });

  const optionsClassName =
    menuItems.length > 0
      ? "btn-primary IconButton primary trigger-actions-menu"
      : "IconButton gray trigger-actions-menu";

  const optionsIconName = menuItems.length > 0 ? "options" : "options-gray";

  const menuOptions = {
    items: menuItems,
    minMenuHeight: 35 * menuItems.length,
    offsetY: 7,
  };

  return (
    <div className="buttons-block">
      {maximizingOptions && (
        <>
          {actionButtonIconName && (
            <IconButton
              iconName={actionButtonIconName}
              className={actionButtonClassName}
              onClick={(e) => {
                showApproveModal(data);
              }}
            />
          )}
        </>
      )}

      <IconButton
        iconName={optionsIconName}
        className={optionsClassName}
        onClick={showMenuActions(menuOptions)}
      />

      {/* {maximizingOptions && (
        <button
          className={accordionClassName}
          onClick={(e) => {
            handleAccordionClick(e, data);
          }}
        >
          <Icon name={"chevron"} />
        </button>
      )} */}
    </div>
  );
};
