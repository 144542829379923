import React from 'react';
import PropTypes from 'prop-types';

const MobileTicketsList = ({ list, onRowClick, onImportClick }) => (
  <div className="event__list">
    {list
      ? list.map((eventItem) => (
          <div
            className="event__list__item"
            key={eventItem.id}
            onClick={(event) => {
              onRowClick(eventItem.id, event);
            }}>
            <span className="event__list__item__title">
              {eventItem.ticketName}
            </span>
            <span className="event__list__item__counts">
              {eventItem.scannedTickets}/{eventItem.allTickets}
            </span>
            {/* <span
              className="event__list__item__import"
              onClick={() => onImportClick(eventItem.id)}>
              import
            </span> */}
          </div>
        ))
      : null}
  </div>
);

MobileTicketsList.propTypes = {
  list: PropTypes.array,
};

export default MobileTicketsList;
