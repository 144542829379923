import React from "react";
import { connect } from "react-redux";
import { DEFAULT_TEAMPLAE } from "../../constants/canvas";
import {
  deleteTemplate,
  downloadPdf,
  editTemplate,
  saveTemplate,
  setCurrentTemplate,
} from "../../store/actions/canvasActions";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";

const MySwal = withReactContent(Swal);

const CanvasActions = ({
  canvasWorkingSize,
  currentTemplate,
  canvasObjects,
  token,
  saveTemplate,
  editTemplate,
  deleteTemplate,
  setCurrentTemplate,
  downloadPdf,
}) => {
  const isSaveBtnDisabled =
    !currentTemplate.label || currentTemplate.label === DEFAULT_TEAMPLAE.label;

  const isDeleteBtnDisabled = !currentTemplate.value;

  const handleSave = () => {
    const id = currentTemplate.value;
    const data = {
      ...canvasWorkingSize,
      name: currentTemplate.label,
      framesJson: JSON.stringify(canvasObjects),
    };
    if (id) {
      editTemplate(data, id, token);
      return;
    }
    saveTemplate(data, token).then((id) => {
      setCurrentTemplate({ ...currentTemplate, value: id });
    });
  };

  const handlePreview = () => {
    const data = {
      ...canvasWorkingSize,
      name: currentTemplate.label,
      framesJson: JSON.stringify(canvasObjects),
    };
    downloadPdf(data, token);
  };

  const handleDelete = () => {
    MySwal.fire({
      title: "Are you sure that you want to delete this template?",
      text: "It's impossible to take step back then",
      showCancelButton: true,
      cancelButtonText: "Cancel",
      showConfirmButton: true,
      confirmButtonText: "Delete",
      reverseButtons: true,
    }).then((result) => {
      if (result.value) {
        deleteTemplate(currentTemplate.value, token);
      }
    });
  };

  return (
    <>
      <button
        className="btn-primary"
        onClick={handlePreview}
        disabled={isSaveBtnDisabled}
      >
        Download Pdf
      </button>
      <button
        className="btn-red"
        onClick={handleDelete}
        disabled={isDeleteBtnDisabled}
      >
        Delete
      </button>
      <button
        className="btn-primary"
        onClick={handleSave}
        disabled={isSaveBtnDisabled}
      >
        Save
      </button>
    </>
  );
};

const mapStateToProps = ({ canvas, auth }) => ({
  canvasWorkingSize: canvas.canvasWorkingSize,
  currentTemplate: canvas.currentTemplate,
  canvasObjects: canvas.canvasObjects,
  token: auth.user.token,
});

const mapDispatchToProps = (dispatch) => ({
  setCurrentTemplate: (template) => dispatch(setCurrentTemplate(template)),
  saveTemplate: (data, userToken) => dispatch(saveTemplate(data, userToken)),
  editTemplate: (data, id, userToken) =>
    dispatch(editTemplate(data, id, userToken)),
  deleteTemplate: (id, userToken) => dispatch(deleteTemplate(id, userToken)),
  downloadPdf: (data, token) => dispatch(downloadPdf(data, token)),
});

export default connect(mapStateToProps, mapDispatchToProps)(CanvasActions);
