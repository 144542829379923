import React, { Component } from "react";
import { withRouter } from "react-router";
import ReactTable from "react-table";
import queryString from "query-string";

import swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";

import axios from "../../../axios-instance";

import Container from "../../../components/UI/Container/Container";

import SeasonPassLogo from "../../../images/season-pass-logo.svg";
import SomethingWentWrong from "../../../images/something-went-wrong.svg";
import Warning from "../../../images/warning.svg";
import Icon from "../../../components/UI/Icon/Icon";

import ROUTES from "../../../constants/routes";

import debounce from "lodash.debounce";

import "../SeasonPass.sass";
import "./Orders.sass";
import "react-table/react-table.css";
import CheckBox from "../../../components/UI/Checkbox/CheckBox";
import { showErrorModal } from "../../../modals";

const defaultPageSizes = [20, 60, 100, 500, 1000, 2000];

const MySwal = withReactContent(swal);

const sortFunc = (a, b, desc) => {
  a = a === null || a === undefined ? -Infinity : a;
  b = b === null || b === undefined ? -Infinity : b;
  a = a === "string" ? a.toLowerCase() : a;
  b = b === "string" ? b.toLowerCase() : b;
  if (desc) {
    return a > b ? -1 : 1;
  } else {
    return a < b ? -1 : 1;
  }
};

class Orders extends Component {
  state = {
    search: {
      email: "",
      orderId: "",
      transactionId: "",
      phone: "",
      waitedForApprove: false,
    },
    data: [],
    defaultSorted: [{ id: "area", desc: false }],
    loading: true,
    pagination: {
      pageNumber: 0,
      pageSize: defaultPageSizes[0],
      total: 0,
    },
  };

  resendEmail = async (id) => {
    try {
      await axios.post(`/ManageOrder/resend-email/${id}`);
      MySwal.fire({
        title: "Sended",
        customClass: "seasonpass__modal",
      });
    } catch (e) {
      showErrorModal();
      console.log(e);
    }
  };

  resendTicket = async (id) => {
    try {
      await axios.post(`/ManageOrder/resend-ticket/${id}`);
      MySwal.fire({
        title: "Sended",
        customClass: "seasonpass__modal",
      });
    } catch (e) {
      showErrorModal();
      console.log(e);
    }
  };

  goToApprovePage = (id, requestChangesId) => {
    let pathname = `${ROUTES.APPROVE_REQUESR.replace(
      ":id",
      id
    )}?supervisorId=${requestChangesId}`;

    window.open(pathname);
  };

  showConfirmationModal = ({
    title,
    text = "Please note that this is an irreversible action",
    afterConfirm,
  }) => {
    MySwal.fire({
      imageUrl: Warning,
      title,
      text,
      cancelButtonText: "Cancel",
      confirmButtonText: "Confirm",
      allowEscapeKey: false,
      customClass: "seasonpass__modal",
      allowOutsideClick: false,
      allowEnterKey: false,
      showConfirmButton: true,
      showCancelButton: true,
      reverseButtons: true,
    }).then((result) => {
      if (result.value) {
        afterConfirm();
      }
    });
  };

  handleClick = (type, ...data) => {
    switch (type) {
      case "resend-email":
        this.showConfirmationModal({
          title: "Confirm Resend Email With Payment Link",
          afterConfirm: () => this.resendEmail(data),
        });
        break;
      case "resend-ticket":
        this.showConfirmationModal({
          title: "Confirm Resend Ticket",
          afterConfirm: () => this.resendTicket(data),
        });
        break;
      case "approve":
        this.goToApprovePage(...data);
        break;
      default:
        break;
    }
  };

  getTableData = async () => {
    try {
      this.setState({ loading: true });
      const { search, pagination } = this.state;

      const queryData = {
        ...search,
        pageNumber: pagination.pageNumber,
        pageSize: pagination.pageSize,
      };

      const query = queryString.stringify(queryData, { encode: false });

      const {
        data: { list, total },
      } = await axios.get(`/ManageOrder/search?${query}`);

      this.setState(
        {
          data: list,
          loading: false,
          pagination: { ...pagination, total },
        },
        () => {
          this.sortData();
        }
      );
    } catch (e) {
      console.log(e);
    }
  };

  renderActionCell = ({ original: rowData }) => {
    if (rowData.changesRequested && !rowData.originalOrderId) return null;
    return (
      <>
        {rowData.paymentStatus !== 1 && (
          <button
            className="btn-primary seasonpass__btn orders__btn"
            title="Resend email with payment link"
            onClick={() => this.handleClick("resend-email", rowData.id)}
          >
            <Icon name={"send-email"} />
          </button>
        )}
        {rowData.paymentStatus === 1 && (
          <button
            className="btn-primary orders__btn"
            title="Resend ticket"
            onClick={() => this.handleClick("resend-ticket", rowData.id)}
          >
            <Icon name={"tickets"} />
          </button>
        )}
        {rowData.changesRequested &&
          rowData.requestChangesId &&
          rowData.originalOrderId && (
            <button
              className="btn-primary seasonpass__btn orders__btn"
              title="Go To Approve Page"
              onClick={() =>
                this.handleClick(
                  "approve",
                  rowData.id,
                  rowData.requestChangesId
                )
              }
            >
              <Icon name={"confirm"} />
            </button>
          )}
      </>
    );
  };

  handleCheck = () => {
    const { waitedForApprove, ...otherSearch } = this.state.search;
    this.setState(
      {
        search: { ...otherSearch, waitedForApprove: !waitedForApprove },
        pagination: { ...this.state.pagination, pageNumber: 0 },
      },
      this.getTableData
    );
  };

  componentDidMount() {
    this.getTableData();
  }

  handleChange = debounce((name, value) => {
    if (name) {
      this.setState(
        {
          search: { ...this.state.search, [name]: value },
          pagination: { ...this.state.pagination, pageNumber: 0 },
        },
        this.getTableData
      );
    }
  }, 500);

  onPageSizeChange = (pageSize, pageNumber) => {
    this.setState({ pagination: { pageNumber, pageSize } }, this.getTableData);
  };

  onPageChange = (pageNumber) => {
    this.setState(
      {
        pagination: {
          ...this.state.pagination,
          pageNumber,
        },
      },
      this.getTableData
    );
  };

  sortData = () => {
    const [sortedItem] = this.state.defaultSorted;

    this.setState({
      data: this.state.data.sort((a, b) =>
        sortFunc(a[sortedItem.id], b[sortedItem.id], sortedItem.desc)
      ),
    });
  };

  onSortedChange = (item) => {
    this.setState({ defaultSorted: item }, () => {
      this.sortData();
    });
  };

  render() {
    const {
      data,
      loading,
      search: { waitedForApprove },
      pagination: { pageNumber, pageSize, total },
      defaultSorted,
    } = this.state;

    const columns = [
      {
        Header: "Action",
        accessor: "action",
        width: 180,
        className: "orders__action",
        sortable: false,
        Cell: this.renderActionCell,
      },
      {
        Header: "Email",
        accessor: "buyerEmail",
        width: 260,
      },
      {
        Header: "Phone",
        accessor: "phoneNumber",
        width: 200,
      },
      {
        Header: "Full Name",
        accessor: "fullName",
        width: 200,
      },
      {
        Header: "Order id",
        accessor: "id",
        width: 340,
      },
      {
        Header: "Pass Type",
        accessor: "passType",
        width: 220,
      },
      {
        Header: "Area",
        accessor: "area",
      },
      {
        Header: "Row",
        accessor: "row",
      },
      {
        Header: "Seat",
        accessor: "seat",
      },
      {
        Header: "Side",
        accessor: "side",
      },
      {
        Header: "Price",
        accessor: "amount",
      },
      {
        Header: "Payment Type",
        accessor: "paymentType",
        width: 120,
      },
      {
        Header: "Transaction Status",
        accessor: "additionalPaymentStatus",
        width: 180,
      },
      {
        Header: "Transaction ID",
        accessor: "transactionId",
        width: 140,
      },
      {
        Header: "Transaction Error Message",
        accessor: "transactionErrorMessage",
        width: 220,
      },
      {
        Header: "Address",
        accessor: "fullAddress",
      },
      {
        Header: "City",
        accessor: "city",
      },
      {
        Header: "Postal Code",
        accessor: "postalCode",
      },
      //   {
      //     Header: "Transaction Error Code",
      //     accessor: "transactionErrorCode",
      //     width: 180,
      //   },
    ];

    return (
      <Container className="seasonpass orders">
        <div className="seasonpass__wrapper">
          <div className="seasonpass__header-logo-wrapper">
            <img src={SeasonPassLogo} alt="Logo" />
          </div>
          <div className="seasonpass__header">
            <h2 className="seasonpass__header-title">Orders</h2>
          </div>
          <div className="seasonpass__form">
            <div>
              <div className="orders__search-fields">
                <label className="seasonpass__field">
                  <span className="seasonpass__field-text">
                    <span className="seasonpass__field-text-label">Email</span>
                  </span>
                  <input
                    className={"seasonpass__field-input"}
                    name="email"
                    onChange={(e) => this.handleChange("email", e.target.value)}
                    type="text"
                  />
                </label>
                <label className="seasonpass__field">
                  <span className="seasonpass__field-text">
                    <span className="seasonpass__field-text-label">
                      Phone Number
                    </span>
                  </span>
                  <input
                    className={"seasonpass__field-input"}
                    name="phone"
                    onChange={(e) => this.handleChange("phone", e.target.value)}
                    type="text"
                  />
                </label>
                <label className="seasonpass__field">
                  <span className="seasonpass__field-text">
                    <span className="seasonpass__field-text-label">
                      Order Id
                    </span>
                  </span>
                  <input
                    className={"seasonpass__field-input"}
                    name="orderId"
                    onChange={(e) =>
                      this.handleChange("orderId", e.target.value)
                    }
                    type="text"
                  />
                </label>
                <label className="seasonpass__field">
                  <span className="seasonpass__field-text">
                    <span className="seasonpass__field-text-label">
                      Transaction Id
                    </span>
                  </span>
                  <input
                    className={"seasonpass__field-input"}
                    name="transactionId"
                    onChange={(e) =>
                      this.handleChange("transactionId", e.target.value)
                    }
                    type="text"
                  />
                </label>
              </div>
              <CheckBox
                checked={waitedForApprove}
                id="request-changed"
                name="request-changed"
                onChange={this.handleCheck}
              >
                <span>Request Changed</span>
              </CheckBox>
            </div>
            <ReactTable
              resizable={false}
              data={data}
              loading={loading}
              defaultSorted={defaultSorted}
              page={pageNumber}
              // sortable={false}
              onSortedChange={this.onSortedChange}
              pages={Math.ceil(total / pageSize)}
              pageSize={pageSize}
              columns={columns}
              manual
              onPageSizeChange={this.onPageSizeChange}
              onPageChange={this.onPageChange}
              defaultPageSize={defaultPageSizes[0]}
              pageSizeOptions={defaultPageSizes}
              getTrProps={(state, rowInfo) => {
                const prors = {};
                const isOldOrder =
                  rowInfo &&
                  rowInfo.original &&
                  rowInfo.original.changesRequested &&
                  !rowInfo.original.originalOrderId;

                if (isOldOrder) {
                  prors.style = { backgroundColor: "#FFAB00" };
                }

                return prors;
              }}
            />
          </div>
        </div>
      </Container>
    );
  }
}

export default withRouter(Orders);
