import React, { Component } from "react";

import "./Dropzone.sass";

class Dropzone extends Component {
  state = {
    wasLoad: false,
  };

  fileInputRef = React.createRef();

  openFileDialog = () => {
    this.fileInputRef.current.click();
  };

  onFilesAdded = (evt) => {
    const files = evt.target.files;
    if (this.props.onFilesAdded) {
      const array = this.fileListToArray(files);
      this.props.onFilesAdded(array);
      this.props.onButtonEnable(evt.target.files);
      this.setState({
        wasLoad: true,
      });
    }
  };

  onDrop = (event) => {
    event.preventDefault();
    const files = event.dataTransfer.files;
    // const format = files[0].name.slice(-4);
    const type = files[0].type;
    const accept = this.props.accept;
    const isAccepted = accept ? accept.includes(type) : true;

    if (files.length > 1) {
      this.props.onlyOneFileErrorHandle();
      return;
    }

    if (this.props.onFilesAdded && isAccepted) {
      const array = this.fileListToArray(files);
      this.props.onFilesAdded(array);
      this.props.onButtonEnable(files);
    }

    if (!isAccepted) {
      this.props.unsupportedFileFormatHandle();
    }
  };

  onDragOver = (event) => {
    event.preventDefault();
  };

  fileListToArray = (list) => {
    const array = [];
    for (let i = 0; i < list.length; i++) {
      array.push(list.item(i));
    }
    return array;
  };

  onFileInputClick = (event) => {
    event.target.value = null;
  };

  render() {
    const { imgUrl } = this.props;

    return (
      <div
        className="Dropzone"
        onDrop={this.onDrop}
        onClick={this.openFileDialog}
        onDragOver={this.onDragOver}
      >
        <input
          ref={this.fileInputRef}
          className="FileInput"
          type="file"
          onChange={this.onFilesAdded}
          onClick={this.onFileInputClick}
          id="test"
          accept={this.props.accept}
        />
        {imgUrl ? (
          <img className="Dropzone__img" src={imgUrl} alt="Uploaded Img" />
        ) : (
          <>
            <p>
              Drop file here
              <br /> or
            </p>

            <button type="button" className="btn-secondary">
              Upload file
            </button>
          </>
        )}
      </div>
    );
  }
}

export default Dropzone;
