import React from "react";

import "./TermsAndConditionsModal.scss";

const TermsAndConditionsModal = () => {
  return (
    <div className="modal-overlay">
      <div className="modal-content">
        <div className="terms">
          <p className="terms-title">TERMS AND CONDITIONS</p>
          <p className="sub-title">
            <strong>SELLER</strong>
          </p>
          <ul className="terms-list">
            <li>WE ARE FANCEE DOO</li>
            <li>Humska 1</li>
            <li>7990 Other reservation services and related activities</li>
            <li>11000 Belgrade</li>
            <li>Company Registration Number: 21940291</li>
            <li>Tax Identification Number: 113886255</li>
            <li>
              <a
                href="https://fanceetickets.com"
                target="_blank"
                rel="noopener noreferrer"
              >
                https://www.fanceetickets.com
              </a>
            </li>
            <li>Phone: +381 63 284 325</li>
            <li>
              <a
                href="mailto:info@fanceetickets.com"
                target="_blank"
                rel="noopener noreferrer"
              >
                info@fanceetickets.com
              </a>
            </li>
          </ul>
          <h2>TERMS OF USE OF THE WE ARE FANCEE ONLINE SERVICE</h2>
          <p>
            Your use of the fanceetickets.com website, as well as the service of purchasing/selling 
            tickets online at fanceetickets.com (hereinafter referred to as the "Service"), is 
            subject to these Terms of Use. These Terms of Use constitute an agreement between 
            We Are Fancee Ltd. Belgrade (hereinafter "WAF," "Our") and visitors of the 
            fanceetickets.com website and/or ticket buyers (hereinafter "You," "Your"). If you 
            are an organization selling tickets or managing ticket offices using the WE ARE FANCEE 
            ticket office management system, your use and operation of that system are governed 
            by the WE ARE FANCEE Seller Agreement and Terms of Service.
          </p>

          <h2>SERVICE</h2>
          <p>
            Availability and ticket prices. The organization solely determines the availability of the 
            number of tickets for events or performances. The ticket prices are determined by the 
            organization selling the tickets.
          </p>

          <h2>TICKETS</h2>
          <p>
            Availability and ticket prices. The organization solely determines the availability of the 
            number of tickets for events or performances. The ticket prices are determined by the 
            organization selling the tickets.
            <br/>
            VAT Statement
            <br/>
            VAT included in the price and no hidden costs
          </p>

          <h2>TICKET PURCHASE</h2>
          <p>
            If you purchase tickets from the seller through the Service, the ticket will be sent to you via 
            email after the ticket purchase process is completed. You will not receive a paper copy of the ticket 
            from WAF, only an electronic copy of the purchased ticket.
            <br/>
            Financial Transaction Data Protection
            <br/>
            When entering credit card data, confidential information is transmitted over a public network in a 
            protected (encrypted) form using SSL protocol and PKI system, as the most current cryptographic 
            technology. The security of data during purchase is guaranteed by the card processor, the Acquirer 
            Bank, so the entire payment process is carried out on a secure page, whose processor is the Bank. 
            At no time are credit card data available to our system.
            <br/>
            Conversion Statement
            <br/>
            All payments will be effected in Serbian currency – dinar (RSD). The amount your credit
            card account will be charged for is obtained through the conversion of the price in Euro
            into Serbian dinar according to the current exchange rate of the Serbian National Bank.
            When charging your credit card, the same amount is converted into your local currency
            according to the exchange rate of credit card associations. As a result of this conversion
            there is a possibility of a slight difference from the original price stated in our web site’.
          </p>

          <h2>REFUND OF FUNDS</h2>
          <p>
            The refund of previously purchased tickets is at the discretion of the selling organization 
            (we do not have the discretionary right to refund money) and can only be done through that 
            organization. In case of overpayment or other errors caused by the WAF service itself and when 
            these errors are confirmed, a refund may be made at WAFs discretion. In that case, contact We 
            Are Fancee support via
            {" "}
            <a href="mailto:info@fanceetickets.com">info@fanceetickets.com</a>.
            <br/>
            Refund of Charged Funds
            <br/>
            In the event of returning goods and refunding funds to a customer who previously paid with any of 
            the payment cards, either partially or in full, regardless of the reason for the return, WAF 
            is obliged to make the refund exclusively through American Express (AmEx), DinaCard, Maestro/MasterCard, 
            and VISA payment methods, meaning that the Electronic Money Institution, upon the merchants request, 
            will refund the funds to the cardholders account.
            <br/>
            Complaints are not common and may be initiated in case of card misuse or any other force majeure reasons. 
            Please contact us by email, and we will resolve your complaint through direct communication as soon as possible.
          </p>

          <h2>CONTACT THE SELLING ORGANIZATION</h2>
          <p>
            If you have any questions for the ticket seller (event organizer), you can contact them using the contact 
            information provided in the confirmation of receipt of your ticket, which WAF sends to you electronically.
          </p>

          <h2>ORDER CANCELLATION</h2>
          <p>
            We reserve the right to cancel any ticket purchase for any reason, including but not limited to incomplete 
            or inaccurate payment information, purchasing irregular cards, or any other violation of the WAF Acceptable 
            Use Policy, provisions, and terms USAGE POLICY
            <br/>
            Your use of the service is subject to the "acceptable use policy" established by WAF. The main focus of this 
            policy is not to use our service to commit acts that may be harmful to WAF or undermine WAFs ethical standards, 
            values, and reputation. Malicious actions include intentional misuse of access, granted privileges or licenses, 
            use of copyrighted material, or any infringement of intellectual property, hacking, involvement of third parties, 
            DoS attacks, intentional misuse or misrepresentation of ticket purchases, altering routes, conducting 
            robot-controlled purchases, or impersonating the WAF website. From time to time, our understanding of what is 
            harmful may change as a result of new (technological) developments. Standards and values in using our service reflect 
            social norms and values. Posting offensive images, texts, statements, or defamatory statements do not represent 
            acceptable social norms and moral values, nor do statements lacking artistic, literary, or scientific value. We may, 
            at our discretion, temporarily block your account or completely block access if you violate this WAF "acceptable use" policy.
          </p>
          <h2>PRIVACY POLICY</h2>
          <p>
            Your use of the service is also subject to WAFs privacy policy, which is updated from time to time to 
            comply with current regulations. The Privacy Policy page can be accessed via a link on the WAF websites 
            homepage. Please note that the Privacy Policy applies only to the Service and does not cover third-party 
            websites or services linked through the Service. Additionally, all information you share with the ticket 
            seller through WAF or outside of WAF, such as your name and email address, is subject to that sellers 
            privacy policy
            <br/>
            On behalf of WAF, we commit to maintaining the privacy of all our customers. We only collect necessary, 
            basic customer/user data and data necessary for business and informing users in accordance with good business 
            practices and in order to provide quality service. We give customers the option to choose, including the option 
            to decide whether or not they want to be removed from mailing lists used for marketing campaigns. All customer/user 
            data is strictly kept and available only to employees who need this data to perform their job. All WAF employees 
            and business partners are responsible for adhering to the principles of privacy protection.
          </p>

          <h2>STATEMENTS, DISCLAIMER OF LIABILITY, AND LIMITATION OF LIABILITY</h2>
          <h2>STATEMENT</h2>
          <p>
            The service and website www.fanceetickets.com are not intended for children under the age of 13. 
            By using the Service, you promise that you are over 13 years old and have the authority or appropriate permission 
            to purchase tickets purchased through www.fanceetickets.com
          </p>

          <h2>DISCLAIMER OF LIABILITY</h2>
          <p>
            The WAF service is offered "as is" and we make no warranties, express or implied, including, but not limited to, 
            implied warranties of merchantability, fitness for a particular purpose, non-infringement of intellectual property 
            rights, or implied warranties arising from applicable law. We are not obligated to indemnify or defend you against 
            lawsuits related to intellectual property infringement; and we cannot guarantee that the Service will operate without 
            error or interruption.
          </p>

          <h2>LIMITATION OF LIABILITY</h2>
          <p>
            Under no circumstances will we be liable for any damages, including consequential, indirect, special, incidental, 
            or punitive damages arising out of or in connection with your use of the Service. The limitations of liability in 
            this section do NOT APPLY to liability for negligence, regardless of the form of action, and apply even if we are 
            aware in advance of the possibility of damages and even if such damages were foreseeable. Limitations of liability 
            also apply even if your available legal remedies are inadequate. If applicable law limits the application of the 
            provisions of this section, our liability will still be limited.
          </p>

          <h2>OTHER TERMS</h2>

          <h2>AMENDMENTS</h2>
          <p>
            We may change these Terms and Conditions from time to time by posting an amended version on our website. At any time, 
            we may change the WAF "acceptable use" policy or our privacy policy. We recommend that you periodically review these terms.
          </p>

          <h2>TRANSFER AND DURATION</h2>
          <p>
            Neither party may transfer this Agreement or any of its rights or obligations hereunder without the express written consent 
            of the other, except for the transfer of the Agreement to a legal entity that, as a result of any merger of our company 
            with another, would represent a newly formed legal entity. Except to the extent that transfer is not permitted in the 
            preceding sentence, this Agreement shall be binding upon (and shall inure to the benefit of) the parties respective successors.
          </p>

          <h2>APPLICABLE LAW - SERBIA</h2>
          <p>
            This Agreement is made in the Serbia and shall be governed by Serbian law. The Customer agrees to the exclusive jurisdiction of the courts in Serbia.
          </p>

          <h2>COPYRIGHT</h2>
          <p>
            If you believe that your work is posted on the WAF website in violation of copyright without permission, and exceptions for 
            fair use are not applicable or have been exceeded, you can send an email describing the misuse to {" "}
            <a href="mailto:info@fanceetickets.com">info@fanceetickets.com</a>.
          </p>

          <h2>SEVERABILITY</h2>
          <p>
            To the extent permitted by applicable law, the parties waive all legal provisions that would cause a clause of this agreement 
            to be invalid or otherwise unenforceable in any respect. In the event that any provision of this agreement is nevertheless 
            invalid or otherwise unenforceable, such provision shall be interpreted in a manner that comes as close as possible to the 
            purpose intended by that provision (to the greatest extent permitted by applicable law), while the remaining provisions of 
            this Agreement shall remain in force.
          </p>

          <h2>OTHER LEGAL DOCUMENTS</h2>
          <p>
            Privacy Policy (Visitor/Cardholder) WAF Seller Agreement and Terms of Service (Event Organizers).
          </p>
        </div>
      </div>
    </div>
  );
};

export default TermsAndConditionsModal;
