import * as actionTypes from "../actionTypes/eventsTypes";
import axios from "../../axios-instance";
import Swal from "sweetalert2";
import { toast } from "react-toastify";
import {
  convertScanDataForStore,
  convertSellDataForStore,
  convertToDataMap,
  summedValuesDataMap,
} from "../../helpers/converter";
import { getHeaders } from "../../helpers/getHeaders";

export const clearCurrentEvent = () => ({
  type: actionTypes.CLEAR_CURRENT_EVENT,
});

const getEventsStart = () => ({ type: actionTypes.GET_EVENTS_START });
const getEventsSuccess = (events) => ({
  type: actionTypes.GET_EVENTS_SUCCESS,
  payload: events,
});
const getEventsFail = (error) => ({ type: actionTypes.GET_EVENTS_FAIL, error });

export const getEvents =
  (userToken, isPassedEvents, searchEventValue, skip, take) => (dispatch) => {
    dispatch(getEventsStart());
    return axios
      .get(
        `/Events?includeEnded=${isPassedEvents}&search=${searchEventValue}&skip=${skip}&take=${take}`,
        { headers: getHeaders(userToken) }
      )
      .then((response) => {
        if (response.data.isSuccess) {
          return response.data.result;
        }
        throw new Error(response.data.errorMessage);
      })
      .then((result) => {
        dispatch(getEventsSuccess(result));
        return result;
      })
      .catch((err) => {
        dispatch(getEventsFail(err));
      });
  };

const addEventStart = () => ({ type: actionTypes.ADD_EVENT_START });
const addEventSuccess = (event) => ({
  type: actionTypes.ADD_EVENT_SUCCESS,
  payload: event,
});
const addEventFail = (error) => ({ type: actionTypes.ADD_EVENT_FAIL, error });

export const resetEventsError = () => ({
  type: actionTypes.RESET_EVENTS_ERROR,
});

export const addEvent = (data, userToken) => (dispatch) => {
  dispatch(addEventStart());
  return axios
    .post("/Events", data, { headers: getHeaders(userToken) })
    .then((response) => {
      if (response.data.isSuccess) {
        return response.data.result;
      } else if (
        !response.data.isSuccess &&
        response.data.errorMessage ===
          "User can't set end date before start date."
      ) {
        Swal({
          title: "Error!",
          text: "You can't set start date before end date!",
          type: "error",
          timer: 3000,
        });
      }
      throw new Error(response.data.errorMessage);
    })
    .then((result) => {
      dispatch(addEventSuccess(result));
      toast.success("Your event was successfully created");
      return result;
    })
    .catch((err) => {
      dispatch(addEventFail(err));
    });
};

const getEventStart = () => ({ type: actionTypes.GET_EVENT_START });
const getEventSuccess = (event) => {
  event.activationTime = new Date(event.activationTime);
  event.startDate = new Date(event.startDate);
  event.endDate = new Date(event.endDate);

  return {
    type: actionTypes.GET_EVENT_SUCCESS,
    payload: { event },
  };
};
const getEventFail = () => ({ type: actionTypes.GET_EVENT_FAIL });

export const getEvent = (eventId, token) => (dispatch) => {
  dispatch(getEventStart());
  return axios
    .get(`/Events/${eventId}`, { headers: getHeaders(token) })
    .then((response) => {
      if (response.data.isSuccess) {
        return response.data.result;
      }
      throw new Error(response.data.errorMessage);
    })
    .then((result) => {
      dispatch(getEventSuccess(result));
      return { isFound: true };
    })
    .catch((err) => {
      dispatch(getEventFail(err));
      return { isFound: false };
    });
};

export const getEmailTemplates = (typeId, token) => (dispatch) => {
  return axios
    .get(typeId ? `/EmailTemplate/Templates/?eventEmailTypeId=${typeId}` : `/EmailTemplate/Templates/`, {
      headers: getHeaders(token),
    })
    .then(({ data }) => (data && data.isSuccess ? data.result : []));
};

export const updateScannedTickets =
  (eventId, token, start, end) => (dispatch) => {
    dispatch(getEventStart());
    return axios
      .get(
        `/Events/${eventId}?startDateForTicketsStats=${start}&endDateForTicketsStats=${end}`,
        { headers: getHeaders(token) }
      )
      .then((response) => {
        if (response.data.isSuccess) {
          return response.data.result;
        }
        throw new Error(response.data.errorMessage);
      })
      .then((result) => {
        dispatch(getEventSuccess(result));
        return { result, isFound: true };
      })
      .catch((err) => {
        dispatch(getEventFail(err));
        return { isFound: false };
      });
  };

const createTicketStart = () => ({ type: actionTypes.CREATE_TICKET_START });
const createTicketSuccess = () => ({ type: actionTypes.CREATE_TICKET_SUCCESS });
const createTicketFail = () => ({ type: actionTypes.CREATE_TICKET_FAIL });

export const createTicket = (data, token) => (dispatch) => {
  dispatch(createTicketStart());
  return axios
    .post("/TicketTemplate", data, { headers: getHeaders(token) })
    .then((response) => {
      if (response.data.isSuccess) {
        return response.data.result;
      }
      throw new Error(response.data.errorMessage);
    })
    .then((result) => {
      toast.success("Your ticket was successfully created");
      dispatch(createTicketSuccess(result));
      return result.id;
    })
    .catch((err) => {
      dispatch(createTicketFail(err));
    });
};

const createTicketRuleStart = () => ({
  type: actionTypes.CREATE_TICKET_RULE_START,
});
const createTicketRuleSuccess = () => ({
  type: actionTypes.CREATE_TICKET_RULE_SUCCESS,
});
const createTicketRuleFail = () => ({
  type: actionTypes.CREATE_TICKET_RULE_FAIL,
});

export const createTicketRule = (data, token) => (dispatch) => {
  dispatch(createTicketRuleStart());
  return axios
    .post("/TicketRules", data, { headers: getHeaders(token) })
    .then((response) => {
      if (response.data.isSuccess) {
        return response.data.result;
      }
      throw new Error(response.data.errorMessage);
    })
    .then((result) => {
      dispatch(createTicketRuleSuccess());
      return true;
    })
    .catch((err) => {
      dispatch(createTicketRuleFail());
    });
};

const updateTicketRuleStart = () => ({
  type: actionTypes.UPDATE_TICKET_RULE_START,
});
const updateTicketRuleSuccess = () => ({
  type: actionTypes.UPDATE_TICKET_RULE_SUCCESS,
});
const updateTicketRuleFail = () => ({
  type: actionTypes.UPDATE_TICKET_RULE_FAIL,
});

export const updateTicketRule = (data, token) => (dispatch) => {
  dispatch(updateTicketRuleStart());

  return axios
    .put(`/TicketRules/${data.id}`, data, { headers: getHeaders(token) })
    .then((response) => {
      if (response.data.isSuccess) {
        return response.data.result;
      }
      throw new Error(response.data.errorMessage);
    })
    .then((result) => {
      dispatch(updateTicketRuleSuccess(result));
      return true;
    })
    .catch((err) => {
      dispatch(updateTicketRuleFail(err));
    });
};

const removeTicketRuleStart = () => ({
  type: actionTypes.REMOVE_TICKET_RULE_START,
});
const removeTicketRuleSuccess = () => ({
  type: actionTypes.REMOVE_TICKET_RULE_SUCCESS,
});
const removeTicketRuleFail = () => ({
  type: actionTypes.REMOVE_TICKET_RULE_FAIL,
});

export const removeTicketRule = (id, token) => (dispatch) => {
  dispatch(removeTicketRuleStart());
  axios
    .delete(`/TicketRules/${id}`, { headers: getHeaders(token) })
    .then((response) => {
      if (response.data.isSuccess) {
        return response.data.result;
      }
      throw new Error(response.data.errorMessage);
    })
    .then((result) => {
      dispatch(removeTicketRuleSuccess(result));
    })
    .catch((err) => {
      dispatch(removeTicketRuleFail(err));
    });
};

const getTicketStart = () => ({ type: actionTypes.GET_TICKET_START });
const getTicketSuccess = (ticket) => ({
  type: actionTypes.GET_TICKET_SUCCESS,
  payload: { ticket },
});
const getTicketFail = () => ({ type: actionTypes.GET_TICKET_FAIL });

export const getTicket = (id, token) => (dispatch) => {
  dispatch(getTicketStart());
  return axios
    .get(`/TicketTemplate/${id}`, { headers: getHeaders(token) })
    .then((response) => {
      if (response.data.isSuccess) {
        return response.data.result;
      }
      throw new Error(response.data.errorMessage);
    })
    .then((result) => {
      dispatch(getTicketSuccess(result));
      return { isFound: true };
    })
    .catch((err) => {
      dispatch(getTicketFail(err));
      return { isFound: false };
    });
};

const updateTicketStart = () => ({ type: actionTypes.GET_UPDATE_TICKET_START });
const updateTicketSuccess = () => ({
  type: actionTypes.GET_UPDATE_TICKET_SUCCESS,
});
const updateTicketFail = () => ({ type: actionTypes.GET_UPDATE_TICKET_FAIL });

export const updateTicket = (data, token) => (dispatch) => {
  dispatch(updateTicketStart());
  return axios
    .put(`/TicketTemplate/${data.id}`, data, { headers: getHeaders(token) })
    .then((response) => {
      if (response.data.isSuccess) {
        return response.data.result;
      }
      throw new Error(response.data.errorMessage);
    })
    .then((result) => {
      dispatch(updateTicketSuccess(result));
      toast.success("Your ticket was successfully edited");
      return true;
    })
    .catch((err) => {
      dispatch(updateTicketFail(err));
    });
};

const removeTicketStart = () => ({ type: actionTypes.REMOVE_TICKET_START });
const removeTicketSuccess = () => ({ type: actionTypes.REMOVE_TICKET_SUCCESS });
const removeTicketFail = () => ({ type: actionTypes.REMOVE_TICKET_FAIL });

export const removeTicket = (id, token) => (dispatch) => {
  dispatch(removeTicketStart());
  return axios
    .delete(`/TicketTemplate/${id}`, { headers: getHeaders(token) })
    .then((response) => {
      if (response.data.isSuccess) {
        return response.data.result;
      }
      throw new Error(response.data.errorMessage);
    })
    .then((result) => {
      dispatch(removeTicketSuccess(result));
      toast.success("Your ticket was successfully deleted");
      return true;
    })
    .catch((err) => {
      dispatch(removeTicketFail(err));
    });
};

const deleteEventStart = () => ({ type: actionTypes.DELETE_EVENT_START });
const deleteEventSuccess = (eventId) => ({
  type: actionTypes.DELETE_EVENT_SUCCESS,
  payload: eventId,
});
const deleteEventFail = (error) => ({
  type: actionTypes.DELETE_EVENT_FAIL,
  error,
});

export const deleteEvent = (userToken, eventId) => (dispatch) => {
  dispatch(deleteEventStart());
  return axios
    .delete(`/Events/${eventId}`, { headers: getHeaders(userToken) })
    .then((response) => {
      if (response.data.isSuccess) {
        return response.data;
      }
      throw new Error(response.data.errorMessage);
    })
    .then((result) => {
      toast.success("Your event was successfully deleted");
      return result;
    })
    .catch((err) => {
      dispatch(deleteEventFail(err));
    });
};

export const sendResalePurposes = (eventId) => (dispatch) => {
  return axios
    .post(`/SeasonRefuse/SendResalePurposes?eventId=${eventId}`)
    .then((result) => {
      toast.success(
        "Season pass owners will receive an email with link for reselling seats for this game."
      );
      return result;
    });
};

const updateEventStart = () => ({ type: actionTypes.UPDATE_EVENT_START });
const updateEventSuccess = (eventId) => ({
  type: actionTypes.UPDATE_EVENT_SUCCESS,
  payload: eventId,
});
const updateEventFail = (error) => ({
  type: actionTypes.UPDATE_EVENT_FAIL,
  error,
});

export const updateHeaderImage = (userToken, eventId, files) => (dispatch) => {
  dispatch(updateEventStart());
  const body = new FormData();
  body.append("image", files[0]);

  return axios
    .put(`/Templates/${eventId}/HeaderImage`, body, {
      headers: getHeaders(userToken),
    })
    .then(() => {
      toast.success("Your event image was successfully edited");
      dispatch(getEvent(eventId, userToken));
    })
    .catch((err) => {
      toast.error("Something went wrong. Try later");
      dispatch(updateEventFail(err));
    });
};

export const getSeasons = (userToken) => (dispatch) => {
  return axios
    .get("/Season/All", {
      headers: getHeaders(userToken),
    })
    .then(({ data }) => (data && data.isSuccess ? data.result : []));
};

export const updateEvent = (userToken, eventId, data) => (dispatch) => {
  dispatch(updateEventStart());
  return axios
    .put(`/Events/${eventId}`, data, { headers: getHeaders(userToken) })
    .then((response) => {
      if (response.data.isSuccess) {
        return response.data;
      } else if (
        !response.data.isSuccess &&
        response.data.errorMessage ===
          "User can't set end date before start date."
      ) {
        Swal({
          title: "Error!",
          text: "You can't set start date before end date!",
          type: "error",
          timer: 3000,
        });
      }
      throw new Error(response.data.errorMessage);
    })
    .then((result) => {
      dispatch(updateEventSuccess(eventId));
      toast.success("Your event was successfully edited");
      return result;
    })
    .catch((err) => {
      dispatch(updateEventFail(err));
    });
};

const getEventSellStatsStart = () => ({
  type: actionTypes.GET_EVENT_SELL_STATS_START,
});
const getEventSellStatsSuccess = (payload) => ({
  type: actionTypes.UPDATE_EVENT_SELL_STATISTIC,
  payload,
});
const getEventSellStatsFail = () => ({
  type: actionTypes.GET_EVENT_SELL_STATS_FAIL,
});

export const getEventSellStats = (eventId, token) => (dispatch) => {
  dispatch(getEventSellStatsStart());
  return axios
    .get(`/Events/${eventId}/sell-statistics`, { headers: getHeaders(token) })
    .then((response) => {
      if (response.data.isSuccess) {
        return response.data.result;
      }
      throw new Error(response.data.errorMessage);
    })
    .then((result) => {
      const { sellData, ticketNamesMap, teamNamesMap, devicesNamesMap } =
        convertSellDataForStore(result);
      const { dataMap, detailsMap } = convertToDataMap(sellData);
      summedValuesDataMap(
        dataMap,
        detailsMap,
        Array.from(ticketNamesMap.keys()),
        Array.from(teamNamesMap.keys()),
        Array.from(devicesNamesMap.keys())
      );
      dispatch(
        getEventSellStatsSuccess({
          dataMap,
          detailsMap,
          ticketNamesMap,
          teamNamesMap,
          devicesNamesMap,
        })
      );
    })
    .catch((err) => {
      dispatch(getEventSellStatsFail(err));
    });
};

const getEventScanStatsStart = () => ({
  type: actionTypes.GET_EVENT_SCAN_STATS_START,
});
const getEventScanStatsSuccess = (payload) => ({
  type: actionTypes.UPDATE_EVENT_SCAN_STATISTIC,
  payload,
});
const getEventScanStatsFail = () => ({
  type: actionTypes.GET_EVENT_SCAN_STATS_FAIL,
});

export const getEventScanStats = (eventId, token) => (dispatch) => {
  dispatch(getEventScanStatsStart());
  return axios
    .get(`/Events/${eventId}/scan-statistics`, { headers: getHeaders(token) })
    .then((response) => {
      if (response.data.isSuccess) {
        return response.data.result;
      }
      throw new Error(response.data.errorMessage);
    })
    .then((result) => {
      const { scanData, ticketNamesMap, teamNamesMap, devicesNamesMap } =
        convertScanDataForStore(result);
      const { dataMap, detailsMap } = convertToDataMap(scanData);
      summedValuesDataMap(
        dataMap,
        detailsMap,
        Array.from(ticketNamesMap.keys()),
        Array.from(teamNamesMap.keys()),
        Array.from(devicesNamesMap.keys())
      );
      dispatch(
        getEventScanStatsSuccess({
          dataMap,
          detailsMap,
          ticketNamesMap,
          teamNamesMap,
          devicesNamesMap,
        })
      );
    })
    .catch((err) => {
      console.log(err);
      dispatch(getEventScanStatsFail(err));
    });
};
