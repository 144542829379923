import React, { Component } from "react";
import { withRouter } from "react-router";

import Select from "react-select";

import moment from "moment";
import swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";

import axios from "../../../axios-instance";

import CheckBox from "../../../components/UI/Checkbox/CheckBox";
import Container from "../../../components/UI/Container/Container";

import TermsAndConditionsModal from "../TermsAndConditionsModal/TermsAndConditionsModal";

import { bankLogos, companyCountryOptions } from "../constants";
import { showInfoModal, showErrorModal } from "../../../modals";

import SeasonPassLogo from "../../../images/season-pass-logo.svg";
import ThanksForYourPurchase from "../../../images/thanks-for-your-purchase.svg";
import PleaseWait from "../../../images/please-wait-season-pass-modal.svg";

import {
  createMerchantSin,
  renderTotal,
  getTotal,
  getPriceView,
} from "../helpers";

import "../SeasonPass.sass";
import "./PartizanSeasonPass.sass";
import ROUTES from "../../../constants/routes";

const MySwal = withReactContent(swal);

class PartizanSeasonPass extends Component {
  state = {
    personalFields: {
      name: "",
      country: "",
      address: "",
      city: "",
      postalCode: "",
      phoneNumber: "",
      email: "",
    },
    companyFields: {
      name: "",
      country: "",
      address: "",
      city: "",
      postalCode: "",
      vatNumber: "",
      email: "",
    },
    transactionData: {
      orderId: "",
      paymentAmount: "",
      currencyCode: "",
      //TODO: should check
      sessionValidity: "2023-08-28T14:26:53.000Z",
      resURL: "",
      redirectURL: "",
      lang: "en",
      merchantName: "WE ARE FANCEE d.o.o",
      signedKeys:
        "orderId,paymentAmount,currencyCode,sessionValidity,resURL,redirectURL,lang,merchantName",
    },
    disabledFields: {
      personalFields: {
        phoneNumber: false,
        email: false,
        name: false,
      },
      companyFields: {},
    },
    price: "",
    seats: [],
    termsAndConditionsChecked: false,
    submittBlocked: false,
    processingFeeRate: 0,
    membershipCardsPrice: "",
  };

  formRef = React.createRef();

  setOrderDataToState = (data) => {
    const membershipCardsPrice = data.seats.reduce(
      (accumulator, currentValue) => {
        return accumulator + +currentValue.membershipCardPrice;
      },
      0
    );

    const price = data.seats.reduce((accumulator, currentValue) => {
      return accumulator + +currentValue.amount;
    }, 0);

    const processingFeeRate = data.processingFeeRate;

    const transactionData = {
      ...this.state.transactionData,
      orderId: data.id,
      paymentAmount: getTotal(price + membershipCardsPrice, processingFeeRate),
      currencyCode: data.currency,
      redirectURL: `${
        window.location.origin
      }${ROUTES.PARTIZAN_SEASON_PASS.replace(":id", data.id)}?`,
      resURL: `${process.env.API_URL}/Payment/Webhook/Kopa`,
    };

    const personalFields = {
      name: data.fullName,
      country: data.buyerCountry || companyCountryOptions[0].value,
      address: data.physicalAddress,
      city: data.city,
      postalCode: data.postalCode,
      phoneNumber: data.phoneNumber,
      email: data.buyerEmail,
    };

    const companyFields = {
      name: data.companyName,
      country: data.companyCountry || companyCountryOptions[0].value,
      address: data.companyAddress,
      city: data.companyCity,
      postalCode: data.companyPostalCode,
      vatNumber: data.companyVatNumber,
      email: data.companyEmail,
    };

    const disabledFields = {
      personalFields: {
        name: !!data.fullName,
        phoneNumber: !!data.phoneNumber,
        email: !!data.buyerEmail,
      },
      companyFields: {},
    };

    transactionData.merchantSig = createMerchantSin(
      transactionData,
      transactionData.signedKeys
    );

    this.setState({
      transactionData,
      seats: data.seats,
      disabledFields,
      personalFields,
      companyFields,
      price: getPriceView(price),
      processingFeeRate,
      membershipCardsPrice: getPriceView(membershipCardsPrice),
    });
  };

  showPleaseWaitModal = () => {
    MySwal.fire({
      imageUrl: PleaseWait,
      title: "Please wait",
      text: "You will be redirected to the payment page",
      allowEscapeKey: false,
      customClass: "seasonpass__modal",
      allowOutsideClick: false,
      allowEnterKey: false,
      showConfirmButton: false,
    });
  };

  showTermsAndConditionsModal = (ev) => {
    ev.preventDefault();
    MySwal.fire({
      html: <TermsAndConditionsModal />,
      width: "64em",
      customClass: "seasonpass__modal",
    });
  };

  handleOrderStatuses = (data) => {
    switch (data.paymentStatus) {
      //New
      case 0:
        this.setState({ submittBlocked: false });
        break;
      //Success
      case 1:
        showInfoModal({
          image: ThanksForYourPurchase,
          title: "Thanks for Your Purchase!",
          text: `We will send all the details to your inbox (${data.buyerEmail}) and look forward to meeting you`,
          additionalText: `(Payment Status: ${
            data.additionalPaymentStatus
          }, Payment Date: ${moment(data.paymentDate).format(
            "DD MMMM YYYY HH:mm"
          )}, Transaction Id: ${data.transactionId})`,
        });
        this.setState({ submittBlocked: true });
        break;
      //Failed
      case 2:
        showErrorModal({
          showConfirmButton: false,
          text: `It seems to have failed to complete the payment, please try again`,
          additionalText: `(Payment Status: ${
            data.additionalPaymentStatus
          }, Payment Date: ${moment(data.paymentDate).format(
            "DD MMMM YYYY HH:mm"
          )}, Transaction Id: ${data.transactionId})`,
        });
        this.setState({ submittBlocked: false });
        break;
      //Cancelled
      case 3:
        this.setState({ submittBlocked: false });
        break;
      default:
        this.setState({ submittBlocked: true });
        break;
    }
  };

  getOrderData = async () => {
    const id = this.props.match.params.id;

    try {
      const { data } = await axios.get(`Payment/orders/${id}`);
      if (data) {
        this.handleOrderStatuses(data);
        this.setOrderDataToState(data);
      }
    } catch (e) {
      showErrorModal({
        text: `It seems to have failed to complete the payment, please try again`,
      });
      console.log(e);
    }
  };

  setTermsAndConditions = () => {
    const { termsAndConditionsChecked } = this.state;
    this.setState({ termsAndConditionsChecked: !termsAndConditionsChecked });
  };

  handleChange = (type, name, value) => {
    if (name && type) {
      this.setState({
        [type]: {
          ...this.state[type],
          [name]: value,
        },
      });
    }
  };

  checkIsSubbmitDisabled = () => {
    const { personalFields, termsAndConditionsChecked } = this.state;

    let isSubbmitDisabled =
      !Object.keys(personalFields).every((key) => !!personalFields[key]) ||
      !termsAndConditionsChecked;

    return isSubbmitDisabled;
  };

  componentDidMount() {
    this.getOrderData();
  }

  sendOrderData = async () => {
    const {
      companyFields,
      personalFields,
      transactionData: { orderId },
    } = this.state;

    const body = {
      fullName: personalFields.name,
      physicalAddress: personalFields.address,
      city: personalFields.city,
      postalCode: personalFields.postalCode,
      phoneNumber: personalFields.phoneNumber,
      buyerEmail: personalFields.email,
      buyerCountry: personalFields.country,
      companyName: companyFields.name,
      companyEmail: companyFields.email,
      companyAddress: companyFields.address,
      companyCity: companyFields.city,
      companyPostalCode: companyFields.postalCode,
      companyCountry: companyFields.country,
      companyVatNumber: companyFields.vatNumber,
    };
    await axios.put(`Payment/orders/${orderId}`, body);
  };

  handleSubmit = async () => {
    try {
      this.showPleaseWaitModal();
      await this.sendOrderData();
      this.formRef.current.submit();
    } catch (e) {
      console.log(e);
      showErrorModal({
        text: `It seems to have failed to complete the payment, please try again`,
      });
    }
  };

  render() {
    const {
      companyFields,
      personalFields,
      transactionData,
      seats,
      disabledFields,
      termsAndConditionsChecked,
      price,
      membershipCardsPrice,
      processingFeeRate,
    } = this.state;

    const otherFields = [
      {
        title: "Membership cards:",
        text: `${membershipCardsPrice} ${transactionData.currencyCode}`,
      },
    ];

    return (
      <Container className="seasonpass partizan-season-pass">
        <div className="seasonpass__wrapper">
          <div className="seasonpass__header-logo-wrapper">
            <img src={SeasonPassLogo} alt="Logo" />
          </div>
          <div className="seasonpass__header">
            <h2 className="seasonpass__header-title">Partizan Season Pass</h2>
          </div>
          <form
            ref={this.formRef}
            action={process.env.REACT_APP_PAYMENT_LINK}
            method="post"
            className="seasonpass__form"
          >
            {Object.keys(transactionData).map((key) => (
              <input
                name={key}
                key={key}
                type="hidden"
                value={transactionData[key]}
              />
            ))}

            <div className="seasonpass__grouped-fields">
              <div>
                <label className="seasonpass__field">
                  <span className="seasonpass__field-text">
                    <span className="seasonpass__field-text-label">
                      Personal Name
                    </span>
                    {!personalFields.name && (
                      <span className="seasonpass__field-text-required">
                        Required Field
                      </span>
                    )}
                  </span>
                  <input
                    className={"seasonpass__field-input"}
                    name="fullName"
                    disabled={disabledFields.personalFields.name}
                    onChange={(e) =>
                      this.handleChange(
                        "personalFields",
                        "name",
                        e.target.value
                      )
                    }
                    type="text"
                    value={personalFields.name}
                  />
                </label>

                <label className="seasonpass__field">
                  <span className="seasonpass__field-text">
                    <span className="seasonpass__field-text-label">
                      Country
                    </span>
                  </span>

                  <Select
                    options={companyCountryOptions}
                    name="buyerCountry"
                    value={companyCountryOptions.find(
                      ({ value }) => personalFields.country === value
                    )}
                    onChange={(value) =>
                      this.handleChange(
                        "personalFields",
                        "country",
                        value.value
                      )
                    }
                    classNamePrefix="seasonpass__select"
                    className="seasonpass__select"
                  />
                </label>

                <label className="seasonpass__field">
                  <span className="seasonpass__field-text">
                    <span className="seasonpass__field-text-label">
                      Personal Address
                    </span>
                    {!personalFields.address && (
                      <span className="seasonpass__field-text-required">
                        Required Field
                      </span>
                    )}
                  </span>
                  <input
                    className={"seasonpass__field-input"}
                    name="personalAddress"
                    onChange={(e) =>
                      this.handleChange(
                        "personalFields",
                        "address",
                        e.target.value
                      )
                    }
                    type="text"
                    value={personalFields.address}
                  />
                </label>

                <div className="seasonpass__grouped-fields">
                  <label className="seasonpass__field">
                    <span className="seasonpass__field-text">
                      <span className="seasonpass__field-text-label">City</span>
                      {!personalFields.city && (
                        <span className="seasonpass__field-text-required">
                          Required Field
                        </span>
                      )}
                    </span>
                    <input
                      className="seasonpass__field-input"
                      name="personalCity"
                      onChange={(e) =>
                        this.handleChange(
                          "personalFields",
                          "city",
                          e.target.value
                        )
                      }
                      value={personalFields.city}
                      type="text"
                    />
                  </label>

                  <label className="seasonpass__field">
                    <span className="seasonpass__field-text">
                      <span className="seasonpass__field-text-label">
                        Postal Code
                      </span>
                      {!personalFields.postalCode && (
                        <span className="seasonpass__field-text-required">
                          Required Field
                        </span>
                      )}
                    </span>
                    <input
                      className="seasonpass__field-input"
                      name="personalPostalCode"
                      onChange={(e) =>
                        this.handleChange(
                          "personalFields",
                          "postalCode",
                          e.target.value
                        )
                      }
                      type="text"
                      value={personalFields.postalCode}
                    />
                  </label>
                </div>

                <div className="seasonpass__grouped-fields">
                  <label className="seasonpass__field">
                    <span className="seasonpass__field-text">
                      <span className="seasonpass__field-text-label">
                        Personal Phone Number
                      </span>
                      {!personalFields.phoneNumber && (
                        <span className="seasonpass__field-text-required">
                          Required Field
                        </span>
                      )}
                    </span>
                    <input
                      className="seasonpass__field-input"
                      type="text"
                      name="personalPhoneNumber"
                      disabled={disabledFields.personalFields.phoneNumber}
                      onChange={(e) =>
                        this.handleChange(
                          "personalFields",
                          "phoneNumber",
                          e.target.value
                        )
                      }
                      value={personalFields.phoneNumber}
                    />
                  </label>

                  <label className="seasonpass__field">
                    <span className="seasonpass__field-text">
                      <span className="seasonpass__field-text-label">
                        Buyer Email
                      </span>
                      {!personalFields.email && (
                        <span className="seasonpass__field-text-required">
                          Required Field
                        </span>
                      )}
                    </span>
                    <input
                      className="seasonpass__field-input"
                      name="personalBuyerEmail"
                      disabled={disabledFields.personalFields.email}
                      onChange={(e) =>
                        this.handleChange(
                          "personalFields",
                          "email",
                          e.target.value
                        )
                      }
                      type="text"
                      value={personalFields.email}
                    />
                  </label>
                </div>
              </div>

              <div>
                <label className="seasonpass__field">
                  <span className="seasonpass__field-text">
                    <span className="seasonpass__field-text-label">
                      Company Name
                    </span>
                  </span>
                  <input
                    className={"seasonpass__field-input"}
                    name="companyName"
                    onChange={(e) =>
                      this.handleChange("companyFields", "name", e.target.value)
                    }
                    type="text"
                    value={companyFields.name}
                  />
                </label>

                <label className="seasonpass__field">
                  <span className="seasonpass__field-text">
                    <span className="seasonpass__field-text-label">
                      Country
                    </span>
                  </span>

                  <Select
                    options={companyCountryOptions}
                    name="companyCountry"
                    value={companyCountryOptions.find(
                      ({ value }) => companyFields.country === value
                    )}
                    onChange={(value) =>
                      this.handleChange("companyFields", "country", value.value)
                    }
                    classNamePrefix="seasonpass__select"
                    className="seasonpass__select"
                  />
                </label>

                <label className="seasonpass__field">
                  <span className="seasonpass__field-text">
                    <span className="seasonpass__field-text-label">
                      Businesss Address
                    </span>
                  </span>
                  <input
                    className={"seasonpass__field-input"}
                    name="companyAddress"
                    onChange={(e) =>
                      this.handleChange(
                        "companyFields",
                        "address",
                        e.target.value
                      )
                    }
                    type="text"
                    value={companyFields.address}
                  />
                </label>

                <div className="seasonpass__grouped-fields">
                  <label className="seasonpass__field">
                    <span className="seasonpass__field-text">
                      <span className="seasonpass__field-text-label">City</span>
                    </span>
                    <input
                      className="seasonpass__field-input"
                      name="personalCity"
                      onChange={(e) =>
                        this.handleChange(
                          "companyFields",
                          "city",
                          e.target.value
                        )
                      }
                      value={companyFields.city}
                      type="text"
                    />
                  </label>

                  <label className="seasonpass__field">
                    <span className="seasonpass__field-text">
                      <span className="seasonpass__field-text-label">
                        Postal Code
                      </span>
                    </span>
                    <input
                      className="seasonpass__field-input"
                      name="personalPostalCode"
                      onChange={(e) =>
                        this.handleChange(
                          "companyFields",
                          "postalCode",
                          e.target.value
                        )
                      }
                      type="text"
                      value={companyFields.postalCode}
                    />
                  </label>
                </div>

                <div className="seasonpass__grouped-fields">
                  <label className="seasonpass__field">
                    <span className="seasonpass__field-text">
                      <span className="seasonpass__field-text-label">
                        Company Email
                      </span>
                    </span>
                    <input
                      className="seasonpass__field-input"
                      name="personalBuyerEmail"
                      onChange={(e) =>
                        this.handleChange(
                          "companyFields",
                          "email",
                          e.target.value
                        )
                      }
                      type="text"
                      value={companyFields.email}
                    />
                  </label>

                  <label className="seasonpass__field">
                    <span className="seasonpass__field-text">
                      <span className="seasonpass__field-text-label">
                        Vat Number
                      </span>
                    </span>
                    <input
                      className="seasonpass__field-input"
                      type="text"
                      name="vatNumber"
                      onChange={(e) =>
                        this.handleChange(
                          "companyFields",
                          "vatNumber",
                          e.target.value
                        )
                      }
                      value={companyFields.vatNumber}
                    />
                  </label>
                </div>
              </div>
            </div>

            <div className="partizan-season-pass__list-of-tickets">
              {seats.map((item, i) => (
                <div key={i} className="partizan-season-pass__ticket">
                  <span>{item.passType}</span>
                  <span>{item.area}</span>
                  <span>{item.row}</span>
                  <span>{item.seat}</span>
                  <span>{item.side}</span>
                </div>
              ))}
            </div>

            <div className="seasonpass__result-wrapper">
              <div>
                <div className="seasonpass__terms-and-conditions">
                  <CheckBox
                    checked={termsAndConditionsChecked}
                    id="terms-and-conditions-checkbox"
                    name="terms-and-conditions-checkbox"
                    onChange={this.setTermsAndConditions}
                  >
                    <span onClick={this.showTermsAndConditionsModal}>
                      I agree to the Terms and Conditions
                    </span>
                  </CheckBox>
                </div>
                <button
                  type="button"
                  onClick={this.handleSubmit}
                  className="btn-primary seasonpass__btn"
                  disabled={this.checkIsSubbmitDisabled()}
                >
                  Buy
                </button>
              </div>

              {renderTotal({
                currencyCode: transactionData.currencyCode,
                total: transactionData.paymentAmount,
                price,
                currentFee: processingFeeRate,
                otherFields: membershipCardsPrice > 0 ? otherFields : [],
              })}
            </div>

            <div className="seasonpass__bank-logos">
              {bankLogos.map(({ alt, src, link }) => (
                <div key={alt}>
                    <a target="_blank" rel="noopener noreferrer" href="{link}">
                      <img style="heigh:50px;" src={src} alt={alt} />
                    </a>
                </div>
          ))}
            </div>
          </form>
        </div>
      </Container>
    );
  }
}

export default withRouter(PartizanSeasonPass);
