import {TICKETS_IMPORT} from "../actionTypes/ticketImportActionsTypes";
import axios from "../../axios-instance";
import { toast } from 'react-toastify';
import { getHeaders } from "../../helpers/getHeaders";

export const nextStep = {type: TICKETS_IMPORT.NEXT_STEP};
export const prevStep = {type: TICKETS_IMPORT.PREV_STEP};
export const ticketsImportClearStore = {type: TICKETS_IMPORT.TICKETS_IMPORT_CLEAR_STORE};

const getProvidersStart = () => ({type: TICKETS_IMPORT.GET_PROVIDERS_START});
const getProvidersSuccess = providers => ({type: TICKETS_IMPORT.GET_PROVIDERS_SUCCESS, payload: providers});
const getProvidersFail = error => ({type: TICKETS_IMPORT.GET_PROVIDERS_FAIL, error});

export const getProviders = token =>  dispatch => {
    dispatch(getProvidersStart());

    return axios.get(`Provider`, {headers: getHeaders(token)})
        .then(response => {
            if (response.data.isSuccess) {
                return response.data.result;
            }
            throw new Error(response.data.errorMessage);
        })
        .then(result => {
            dispatch(getProvidersSuccess(result));
        })
        .catch(error => {
            dispatch(getProvidersFail(error));
        })
};

const getProvidersEventsStart = () => ({type: TICKETS_IMPORT.GET_PROVIDERS_EVENTS_START});
const getProvidersEventsSuccess = providersEvents => ({type: TICKETS_IMPORT.GET_PROVIDERS_EVENTS_SUCCESS, payload: providersEvents});
const getProvidersEventsFail = error => ({type: TICKETS_IMPORT.GET_PROVIDERS_EVENTS_FAIL, error});

export const getProvidersEvents = (authData, token) =>  dispatch => {
    dispatch(getProvidersEventsStart());

    return axios.get(`/Events/Tibba`, {
        headers: getHeaders(token),
        params: {...authData}
    })
        .then(response => {
            if (response.data.isSuccess) {
                return response.data.result;
            }
            throw new Error(response.data.errorMessage);
        })
        .then(result => {
            dispatch(getProvidersEventsSuccess(result));
            dispatch(nextStep);
        })
        .catch(error => {
            dispatch(getProvidersEventsFail(error));
            toast.success('Authorization error');
        })
};

export const selectProvidersEvent = (event, enterXlEventId) => ({
    type: TICKETS_IMPORT.SELECT_PROVIDERS_EVENT,
    payload: {
        ...event,
        enterXlEventId,
    }
});
export const selectProvidersTicketTemplate = (ticketTemplate, enterXlTicketTemplateId) => ({
    type: TICKETS_IMPORT.SELECT_PROVIDERS_TICKET_TEMPLATE,
    payload: {
        ...ticketTemplate,
        enterXlTicketTemplateId,
    }
});

const importTicketsStart = () => ({type: TICKETS_IMPORT.IMPORT_TICKETS_START});
const importTicketsSuccess = () => ({type: TICKETS_IMPORT.IMPORT_TICKETS_SUCCESS});
const importTicketsFail = () => ({type: TICKETS_IMPORT.IMPORT_TICKETS_FAIL});

export const importTickets = (dataForImport, authData, token) => dispatch => {
    dispatch(importTicketsStart());
    toast.info('Import started');
    return axios.put(
        `/Events/Tibba`, dataForImport, {
            headers: getHeaders(token),
            params: {...authData},
        })
        .then(response => {
            if (response.data.isSuccess) {
                return response.data.result;
            }
            throw new Error(response.data.errorMessage);
        })
        .then(result => {
            dispatch(importTicketsSuccess());
            toast.success(`${result.allTicketsCount} tickets was successfully imported.`);
            return true;
        })
        .catch(err => {
            dispatch(importTicketsFail());
            toast.success('Something went wrong.');
        })
};
