import React, { useState } from "react";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";

const MySwal = withReactContent(Swal);

export const ApproveTransactionModal = ({
  token,
  transactionId,
  approveTransaction,
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const [makeFiscalization, setMakeFiscalization] = useState(true);

  const changeFiscalizationHandler = () => {
    setMakeFiscalization((curr) => !curr);
  };

  const confirmHandler = () => {
    setIsLoading(true);
    approveTransaction(
      token,
      transactionId,
      makeFiscalization,
      closeModalHandler
    );
  };

  const closeModalHandler = () => {
    MySwal.close();
  };

  return isLoading ? (
    <>
      <h2 className="title">Please wait</h2>
      <div className="swal2-content">
        <div id="swal2-content">Processing approval…</div>
      </div>
    </>
  ) : (
    <>
      <h2 className="title">
        Are you sure you want to approve this transaction?
      </h2>
      <div className="swal2-content">
        <div id="swal2-content">It’s impossible to take step back then</div>
        <label className="swal2-checkbox">
          <input
            type="checkbox"
            checked={makeFiscalization}
            onChange={changeFiscalizationHandler}
          />
          <span className="swal2-label">Create and send a fiscal receipt</span>
        </label>
      </div>
      <div className="swal2-actions">
        <button
          className="swal2-cancel swal2-styled"
          onClick={closeModalHandler}
        >
          Cancel
        </button>
        <button className="swal2-confirm swal2-styled" onClick={confirmHandler}>
          Approve
        </button>
      </div>
    </>
  );
};
