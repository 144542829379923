import React from "react";

import "./ChooseTicketList.sass";
import CheckBox from "../../UI/Checkbox/CheckBox";

const ChooseTicketList = ({ items, checkTicket }) => (
  <div className="ticket-list">
    <h4 className="add-scan-team__title">Choose the tickets</h4>
    <div className="choose-tickets-list">
      {items.length ? (
        items.map((item, index) => (
          <div className="choose-tickets-list__item" key={item.title + index}>
            <strong>{item.title}</strong>
            <p>{item.date}</p>
            <ul>
              {item.list &&
                item.list.map((type) => (
                  <li key={type.id}>
                    <CheckBox
                      name={type.ticketName}
                      id={type.id}
                      checked={type.isScanning}
                      onChange={() => {
                        checkTicket(item.id, type.id);
                      }}
                    >
                      <div className="ticket-name">
                        {type.id} - {type.ticketName}
                      </div>
                    </CheckBox>
                  </li>
                ))}
            </ul>
          </div>
        ))
      ) : (
        <div className="empty-block">No current tickets</div>
      )}
    </div>
  </div>
);

export default ChooseTicketList;
