import React, { useLayoutEffect } from "react";
import { connect } from "react-redux";
import Select from "react-select";

import { DEFAULT_TEAMPLAE } from "../../../constants/canvas";
import {
  getTemplates,
  resetCanvasObjects,
  setCanvasWorkingHeight,
  setCanvasWorkingWidth,
  setCurrentTemplate,
  setScrollPosition,
  setZoom,
} from "../../../store/actions/canvasActions";
import { canvasSetCenter } from "../../Canvas/helpers";

import FieldLabel from "../../FieldLabel/FieldLabel";

const TemplateControl = ({
  currentTemplate,
  contextRef,
  canvasRef,
  windowSize,
  templates,
  isTemplatesLoading,
  setCurrentTemplate,
  setCanvasWorkingWidth,
  setCanvasWorkingHeight,
  setScrollPosition,
  setZoom,
  getTemplates,
  resetCanvasObjects,
}) => {
  const handleTemplate = (newOption) => {
    setCurrentTemplate({ value: newOption.value, label: newOption.label });
    if (!newOption.value) {
      resetCanvasObjects();
    }
    if (newOption.item) {
      const { item } = newOption;
      const { width, height } = item;

      setCanvasWorkingWidth(width);
      setCanvasWorkingHeight(height);

      canvasSetCenter({
        canvas: canvasRef.current,
        context: contextRef.current,
        canvasWorkingSize: { width, height },
        setScrollPosition,
        windowSize,
        setZoom,
      });
      return;
    }
  };
  const handleTemplateName = (e) => {
    const { value } = e.target;
    setCurrentTemplate({ ...currentTemplate, label: value });
  };

  useLayoutEffect(() => {
    getTemplates();
  }, []);

  return (
    <>
      <FieldLabel
        className="canvas-template-select"
        label="Template Name"
        isRequired={
          !currentTemplate.label ||
          currentTemplate.label === DEFAULT_TEAMPLAE.label
        }
      >
        <input
          type="text"
          value={
            currentTemplate.label === DEFAULT_TEAMPLAE.label
              ? ""
              : currentTemplate.label
          }
          onChange={handleTemplateName}
        />
      </FieldLabel>
      <FieldLabel className="canvas-template-select" label="Template">
        <Select
          isLoading={isTemplatesLoading}
          options={templates}
          value={currentTemplate}
          onChange={handleTemplate}
          classNamePrefix="custom-select"
          className="custom-select"
        />
      </FieldLabel>
    </>
  );
};

const mapStateToProps = ({
  canvas: { currentTemplate, templates, isTemplatesLoading },
}) => {
  return {
    currentTemplate,
    templates,
    isTemplatesLoading,
  };
};

const mapDispatchToProps = (dispatch) => ({
  getTemplates: () => dispatch(getTemplates()),
  setCurrentTemplate: (template) => dispatch(setCurrentTemplate(template)),
  setCanvasWorkingWidth: (width) => dispatch(setCanvasWorkingWidth(width)),
  setCanvasWorkingHeight: (height) => dispatch(setCanvasWorkingHeight(height)),
  setScrollPosition: (scrollPosition) =>
    dispatch(setScrollPosition(scrollPosition)),
  setZoom: (zoom) => dispatch(setZoom(zoom)),
  resetCanvasObjects: () => dispatch(resetCanvasObjects()),
});

export default connect(mapStateToProps, mapDispatchToProps)(TemplateControl);
