import React, { Component } from "react";
import { connect } from "react-redux";
import Canvas from "../../components/Canvas/Canvas";
import { canvasInit, canvasSetCenter } from "../../components/Canvas/helpers";
import CanvasActions from "../../components/CanvasActions/CanvasActions";
import CanvasSidebar from "../../components/CanvasSidebar/CanvasSidebar";
import CanvasZoom from "../../components/CanvasZoom/CanvasZoom";
import CavasSizesMenu from "../../components/CavasSizesMenu/CavasSizesMenu";
import { setScrollPosition, setZoom } from "../../store/actions/canvasActions";

import "./TemplateEditor.sass";

class TemplateEditor extends Component {
  canvasRef = React.createRef();
  contextRef = React.createRef();
  containerRef = React.createRef();
  state = {
    windowSize: { width: 0, height: 0 },
  };

  setRef = (canvas) => {
    if (canvas) {
      this.canvasRef.current = canvas;

      const context = canvas.getContext("2d", {
        willReadFrequently: true,
      });

      if (!context) return;

      this.contextRef.current = context;

      canvasInit({
        canvas,
        context,
        canvasWidth: this.props.canvasWorkingSize.width,
        canvasHeight: this.props.canvasWorkingSize.height,
      });
    }
  };

  // loadFonts = async () => {
  //   //TODO: need changes

  //   const SFProDisplayRegular = new FontFace(
  //     "SFProDisplayRegular",
  //     "url(/fonts/SFProDisplay/SF-Pro-Display-Regular.otf)"
  //   );

  //   await SFProDisplayRegular.load().then(function (font) {
  //     document.fonts.add(font);
  //   });

  //   const OpenSansRegular = new FontFace(
  //     "OpenSansRegular",
  //     "url(npm-font-open-sans/fonts/Regular/OpenSans-Regular.ttf)"
  //   );

  //   await OpenSansRegular.load().then(function (font) {
  //     document.fonts.add(font);
  //   });
  // };

  componentDidMount() {
    // this.loadFonts();
    const html = document.querySelector("html");

    if (this.containerRef.current) {
      const containerRef = this.containerRef.current;

      const windowSize = {
        width: containerRef.clientWidth + 60,
        height: containerRef.clientHeight,
      };

      this.setState({
        windowSize,
      });

      const { canvasWorkingSize, setScrollPosition, setZoom } = this.props;

      canvasSetCenter({
        canvas: this.canvasRef.current,
        context: this.contextRef.current,
        canvasWorkingSize,
        setScrollPosition,
        setZoom,
        windowSize,
      });
    }

    if (html) {
      html.style.overflow = "hidden";
    }
  }

  componentWillUnmount() {
    const html = document.querySelector("html");

    if (html) {
      html.style.overflow = "auto";
    }
  }

  render() {
    return (
      <div className="template-editor">
        <div className="template-editor__canvas-wrapper">
          <Canvas
            setRef={this.setRef}
            containerRef={this.containerRef}
            windowSize={this.state.windowSize}
            contextRef={this.contextRef}
            canvasRef={this.canvasRef}
          />
          <div className="template-editor__save">
            <CanvasZoom />
            <CanvasActions />
          </div>
        </div>
        <CavasSizesMenu
          canvasRef={this.canvasRef}
          contextRef={this.contextRef}
          windowSize={this.state.windowSize}
        />
        <CanvasSidebar
          contextRef={this.contextRef}
          canvasRef={this.canvasRef}
          windowSize={this.state.windowSize}
        />
      </div>
    );
  }
}

const mapStateToProps = ({ canvas }) => ({
  canvasWorkingSize: canvas.canvasWorkingSize,
  zoom: canvas.zoom,
});

const mapDispatchToProps = (dispatch) => ({
  setScrollPosition: (scrollPosition) =>
    dispatch(setScrollPosition(scrollPosition)),
  setZoom: (zoom) => dispatch(setZoom(zoom)),
});

export default connect(mapStateToProps, mapDispatchToProps)(TemplateEditor);
