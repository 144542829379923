import React, { useState } from "react";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";

import { SeatsDescriptionModal } from "../../../components/Transactions/SeatsDescriptionModal/SeatsDescriptionModal";
import { ConfirmRefundTicketsModal } from "./ConfirmRefundTicketsModal";

const MySwal = withReactContent(Swal);

export const RefundTicketsModal = ({ token, transaction }) => {
  const canRefundSeats = transaction.seats.filter((seat) => !seat.refund);

  const idsSeats = canRefundSeats.map((seat) => seat.id);
  const [checkedSeats, setCheckedSeats] = useState(idsSeats);

  const canRefund = checkedSeats.length > 0;

  const handleCheck = (e) => {
    const seatId = e.target.dataset.related;
    var updatedSeats = [...checkedSeats];

    if (e.target.checked) {
      updatedSeats = [...checkedSeats, seatId];
    } else {
      updatedSeats.splice(checkedSeats.indexOf(seatId), 1);
    }

    setCheckedSeats(updatedSeats);
  };

  const refundTicketsHandler = () => {
    const seats = canRefundSeats.filter((seat) =>
      checkedSeats.includes(seat.id)
    );

    MySwal.fire({
      title: "Are you sure you want to refund tickets?",
      html: <ConfirmRefundTicketsModal token={token} seats={seats} />,
      showConfirmButton: false,
      customClass: "confirm-refund-tickets-modal",
    });
  };

  const closeModalHandler = (e) => {
    MySwal.close();
  };

  return (
    <>
      <div className="label-block">
        <span className="swal2-label">Name</span>
        <span className="swal2-label">
          <b>{transaction.fullName}</b>
        </span>
      </div>
      <div className="label-block">
        <label className="swal2-label">Email</label>
        <label className="swal2-label">
          <b>{transaction.buyerEmail}</b>
        </label>
      </div>
      {canRefundSeats.length > 0 && (
        <div className="seats-list">
          <h2 className="title">Seats list</h2>
          <SeatsDescriptionModal
            seats={canRefundSeats}
            checkedSeats={checkedSeats}
            handleCheck={handleCheck}
          />
        </div>
      )}
      <div className="swal2-actions">
        <button
          className="swal2-cancel swal2-styled"
          onClick={closeModalHandler}
        >
          Cancel
        </button>
        <button
          className="swal2-confirm swal2-styled"
          disabled={!canRefund}
          onClick={refundTicketsHandler}
        >
          Refund
        </button>
      </div>
    </>
  );
};
