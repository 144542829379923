import React from 'react';

import Upload from '../../../containers/Upload/Upload';

import './FirstTab.sass';

const FirstTab = props =>
    <div className="first-tab">
        <div className="download">
            <form
                action={process.env.API_URL.substring(0, process.env.API_URL.length - 3) + "/FilesExamples/Tickets_template.csv"}
                method="GET">
                <button>Download template</button>
            </form>
        </div>

        <div className="choose-file">
            <p>Choose the file ( .csv)</p>

            <Upload onButtonEnable={props.onButtonEnable} />

        </div>
    </div>;

export default FirstTab;
