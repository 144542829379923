import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import {
  banPromoter,
  getPromoter,
  getPromoters,
  setPromoterScanbitCost,
  signInPromoterAsAdmin,
} from '../../store/actions/promotersActions';
import ROUTES from '../../constants/routes';

import ReactTable from 'react-table';
import 'react-table/react-table.css';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';

import Container from '../../components/UI/Container/Container';
import PageTitle from '../../components/UI/PageTitle/PageTitle';
import PromotersEmptyState from '../../components/Promoters/PromotersEmptyState/PromotersEmptyState';
import PromotersMobileTable from '../../components/Promoters/PromotersMobileTable';

import './Promoters.sass';
import Modal from '../../components/Modal/Modal';
import ScanbitsModal from '../../components/ScanbitsModal/ScanbitsModal';
import browserTabTitleOverride from '../../helpers/tabTitleOverride';
import NewLoader from '../../components/NewLoader/NewLoader';

const MySwal = withReactContent(Swal);

class Promoters extends Component {
  state = {
    searchPromoterValue: '',
    isModalActive: false,
    currentUserSelected: null,
  };

  componentDidMount() {
    browserTabTitleOverride();
    const user = localStorage.getItem('userCopy')
      ? JSON.parse(localStorage.getItem('userCopy'))
      : JSON.parse(localStorage.getItem('user'));
    this.getPromoters(user.token);
  }

  getPromoters = (userToken) => {
    const { searchPromoterValue } = this.state;
    const { getPromoters } = this.props;

    getPromoters(userToken, searchPromoterValue, 0, 0);
  };

  searchPromoter = (event) => {
    const { getPromoters } = this.props;
    const user = localStorage.getItem('userCopy')
      ? JSON.parse(localStorage.getItem('userCopy'))
      : JSON.parse(localStorage.getItem('user'));

    this.setState({ searchPromoterValue: event.target.value }, () => {
      getPromoters(user.token, this.state.searchPromoterValue, 0, 0);
    });
  };

  handleBanPromoter = (e, props) => {
    e.stopPropagation();

    const { banPromoter, getPromoters } = this.props;
    const user = localStorage.getItem('userCopy')
      ? JSON.parse(localStorage.getItem('userCopy'))
      : JSON.parse(localStorage.getItem('user'));
    const data = {
      banStatus: props.banStatus === 2 ? 1 : 2,
      scanBitPrice: props.scanBitPrice,
      currencyId: props.currencyId,
    };

    if (props.banStatus === 2) {
      MySwal.fire({
        title: 'Are you sure, you want to unblock this promoter?',
        showCancelButton: true,
        cancelButtonText: 'No',
        showConfirmButton: true,
        confirmButtonText: 'Yes',
        reverseButtons: true,
      }).then((result) => {
        if (result.value) {
          banPromoter(user.token, props.id, data).then((result) => {
            if (result.isSuccess) {
              getPromoters(user.token, this.state.searchPromoterValue, 0, 0);
            }
          });
        }
      });
    } else {
      MySwal.fire({
        title: 'Are you sure, you want to block this promoter?',
        showCancelButton: true,
        cancelButtonText: 'No',
        showConfirmButton: true,
        confirmButtonText: 'Yes',
        reverseButtons: true,
      }).then((result) => {
        if (result.value) {
          banPromoter(user.token, props.id, data).then((result) => {
            if (result.isSuccess) {
              getPromoters(user.token, this.state.searchPromoterValue, 0, 0);
            }
          });
        }
      });
    }
  };

    signInPromoterAsAdmin = (e, props) => {
      e.stopPropagation();

      const { token } = this.props.auth.user;
      const { id } = props;
      const { signInPromoterAsAdmin } = this.props;
      const userCopy = JSON.parse(localStorage.getItem('userCopy'));

      if (userCopy) {
        signInPromoterAsAdmin(userCopy.token, id).then((result) => {
          localStorage.setItem('user', JSON.stringify(result));
          window.location.href = ROUTES.EVENTS;
        });
      } else {
        signInPromoterAsAdmin(token, id).then((result) => {
          localStorage.setItem('userCopy', localStorage.getItem('user'));
          localStorage.setItem('user', JSON.stringify(result));

          window.location.href = ROUTES.EVENTS;
        });
      }
  };

  handleRowClick = (props) => {
    const { token } = this.props.auth.user;
    const userCopy = JSON.parse(localStorage.getItem('userCopy'));
    if (userCopy) {
       this.props.getPromoter(userCopy.token, props.original.id).then(() => {
         this.setState({
           isModalActive: true,
           currentUserSelected: props.original,
         });
       });
    } else {
      this.props.getPromoter(token, props.original.id).then(() => {
        this.setState({
        isModalActive: true,
        currentUserSelected: props.original,
      });
    })}
  };

  handleSubmit = (scanBitPrice) => {
    const { token } = this.props.auth.user;
    const { banStatus, id } = this.state.currentUserSelected;
    const userCopy = JSON.parse(localStorage.getItem('userCopy'));
    const data = {
      banStatus: banStatus,
      scanBitPrice,
      currencyId: 1,
    };

    if (userCopy) {
      this.props.setPromoterScanbitCost(userCopy.token, id, data).then((res) => {
        this.setState((state) => ({
          isModalActive: false,
          currentUserSelected: null,
        }));
      });
     } else {
      this.props.setPromoterScanbitCost(token, id, data).then((res) => {
        this.setState((state) => ({
          isModalActive: false,
          currentUserSelected: null,
        }));
      });
    }
  };

  handleCloseModal = () => {
    this.setState((state) => ({
      isModalActive: false,
      currentUserSelected: null,
    }));
  };

  render() {
    const columns = [
      {
        Header: 'Provider name',
        accessor: 'name',
        resizable: false,
      },
      {
        Header: 'Email',
        accessor: 'email',
        resizable: false,
      },
      {
        Header: 'Phone',
        accessor: 'phone',
        resizable: false,
      },
      {
        Header: 'Balance',
        accessor: 'scanbitsBalance',
        resizable: false,
      },
      {
        Header: 'Spent Scanbits',
        accessor: 'spentScanbits',
        resizable: false,
      },
      {
        Header: '',
        id: 'block',
        Cell: (props) => (
          <button
            className={`${props.original.banStatus === 2 ? 'btn-green' : 'btn-red'}`}
            onClick={(e) => {
              this.handleBanPromoter(e, props.original);
            }}>
            {props.original.banStatus === 2 ? 'Unblock' : 'Block'}
          </button>
        ),
        resizable: false,
      },
      {
        Header: '',
        id: 'sign-in',
        Cell: (props) => (
            <button
            className="btn-black"
            onClick={(e) => {
                this.signInPromoterAsAdmin(e, props.original);
            }}>
            Sign in
          </button>
        ),
        resizable: false,
    },
];

    const { promotersList, currentPromoter, error, loading } =
      this.props.promoters;
    const { isModalActive, currentUserSelected } = this.state;
    return (
      <>
        {isModalActive && !error && !loading ? (
          <Modal>
            <ScanbitsModal
              onSubmit={this.handleSubmit}
              userData={currentUserSelected}
              currentPromoter={currentPromoter}
              handleCloseModal={this.handleCloseModal}
            />
          </Modal>
        ) : null}
        <Container className="promoters">
          <PageTitle>Promoters</PageTitle>
          <input
            className="events__search"
            type="text"
            placeholder="Search"
            onChange={this.searchPromoter}
          />
            {promotersList.length
                ? (
            <div>
              <ReactTable
                data={promotersList}
                columns={columns}
                minRows={0}
                sortable={false}
                showPagination={false}
                defaultPageSize={promotersList.length}
                getTrProps={(state, rowInfo, column, instance) => ({
                  onClick: (e) => {
                    this.handleRowClick(rowInfo);
                  },
                  style: {
                    cursor: 'pointer',
                  },
                })}
              />
                <PromotersMobileTable promoters={promotersList} signInPromoterAsAdmin={this.signInPromoterAsAdmin} handleBanPromoter={this.handleBanPromoter} />
            </div>
            ) : (
              loading ? <NewLoader /> : <PromotersEmptyState />
            )}
        </Container>
      </>
    );
  }
}

const mapStateToProps = ({
  auth,
  promoters: {
    promotersList,
    promoterAuthInfo,
    currentPromoter,
    error,
    loading,
  },
}) => ({
  auth,
  promoters: {
    promotersList,
    promoterAuthInfo,
    currentPromoter,
    error,
    loading,
  },
});

const mapDispatchToProps = (dispatch) => ({
  getPromoters: (userToken, searchPromoterValue, skip, take) => {
    dispatch(getPromoters(userToken, searchPromoterValue, skip, take));
  },
  banPromoter: (userToken, promoterId, banStatus) =>
    dispatch(banPromoter(userToken, promoterId, banStatus)),
  signInPromoterAsAdmin: (userToken, promoterId) =>
    dispatch(signInPromoterAsAdmin(userToken, promoterId)),
  setPromoterScanbitCost: (userToken, promoterId, data) => {
    return dispatch(setPromoterScanbitCost(userToken, promoterId, data));
  },
  getPromoter: (userToken, promoterId) =>
    dispatch(getPromoter(userToken, promoterId)),
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(Promoters)
);
