import React from "react";
import { connect } from "react-redux";
import { canvasSetCenter } from "../Canvas/helpers";
import { useShowActionsMenu } from "../UI/ActionsMenu/hooks/useShowActionsMenu";
import { IconButton } from "../UI/IconButton/IconButton";
import {
  setCanvasWorkingHeight,
  setCanvasWorkingWidth,
  setScrollPosition,
  setZoom,
} from "../../store/actions/canvasActions";

import "./CavasSizesMenu.sass";

const CavasSizesMenu = ({
  canvasRef,
  contextRef,
  windowSize,
  setScrollPosition,
  setCanvasWorkingHeight,
  setCanvasWorkingWidth,
  setZoom,
  canvasWorkingSize,
}) => {
  const [showMenuActions] = useShowActionsMenu();

  const handleChange = ({ width, height }) => {
    setCanvasWorkingHeight(height);
    setCanvasWorkingWidth(width);

    canvasSetCenter({
      canvas: canvasRef.current,
      context: contextRef.current,
      canvasWorkingSize: { width, height },
      setScrollPosition,
      windowSize,
      setZoom,
    });
  };

  const menuItems = [
    // {
    //   text: "1000 x 300",
    //   data: {
    //     width: 1000,
    //     height: 300,
    //   },
    //   onClick: handleChange,
    // },
    {
      text: "1240 x 1754",
      data: {
        width: 1240,
        height: 1754,
      },
      onClick: handleChange,
    },
  ];

  const menuOptions = {
    items: menuItems,
    minMenuHeight: 35 * menuItems.length,
    offsetY: 7,
  };

  return (
    <div className="canvas-size-menu">
      <h4>{`${canvasWorkingSize.width} x ${canvasWorkingSize.height} px`}</h4>
      <IconButton
        className="canvas-size-menu__button"
        iconName="chevron-blue"
        onClick={showMenuActions(menuOptions)}
      />
    </div>
  );
};

const mapStateToProps = ({ canvas }) => {
  return { canvasWorkingSize: canvas.canvasWorkingSize };
};

const mapDispatchToProps = (dispatch) => ({
  setCanvasWorkingWidth: (width) => dispatch(setCanvasWorkingWidth(width)),
  setCanvasWorkingHeight: (height) => dispatch(setCanvasWorkingHeight(height)),
  setScrollPosition: (scrollPosition) =>
    dispatch(setScrollPosition(scrollPosition)),
  setZoom: (zoom) => dispatch(setZoom(zoom)),
});

export default connect(mapStateToProps, mapDispatchToProps)(CavasSizesMenu);
