import React from 'react';

import './MobileHeaderChart.sass';

const MobileHeaderChart = ({children}) => (
    <div className="mobile-header-chart">
        {children}
    </div>
);

export default MobileHeaderChart;
