import React, { useState } from 'react';
import withReactContent from "sweetalert2-react-content";
import Swal from "sweetalert2";

import CheckBox from '../../../../components/UI/Checkbox/CheckBox';
import { SelectedTickets } from '../../PartizanOpenSelling/SelectedTickets';

import { seasonPassPersonalInfoLangs } from '../../../../constants/seasonPassPersonalInfoLangs';

const MySwal = withReactContent(Swal);

export const BuySeasonPassModal = ({
    membershipCard,
    ticketTemplate,
    event,
    onConfirmBuySeasonPass,
    availablePoints,
    langCode }) => {
    const txt = seasonPassPersonalInfoLangs[langCode];
    const [seatLabel, setSeatLabel] = useState(getSeatLabel(membershipCard));
    const [cardSelected, setCardSelected] = useState(false);
    const [companyVatNumber, setCompanyVatNumber] = useState("");

    const nfcCardTicket = event.ticketTemplates.find(t => t.ticketTemplateId == process.env.REACT_APP_NEW_SEASON_NFC_CARD_TICKET_TEMPLATE_ID);
    const tickets = [entreeTicketToSeatsIointerface(membershipCard, ticketTemplate)];

    if (cardSelected) {
        if (nfcCardTicket) {
            tickets.push(entreeTicketToSeatsIointerface({}, nfcCardTicket));
        }
    }

    let totalPrice = tickets.reduce((a, v) => a + v.price, 0)

    let pointsToDiscount = (totalPrice / 2).toFixed(0);

    if (pointsToDiscount > availablePoints) {
        pointsToDiscount = availablePoints;
    }

    totalPrice = ((totalPrice - pointsToDiscount) * (1 + event.feeRate)).toFixed(2);

    const confirmHandler = () => {
        const seats = [{
            ticketName: ticketTemplate.name,
            area: membershipCard.area,
            side: membershipCard.side,
            row: membershipCard.row,
            seat: membershipCard.seat,
            ticketTemplateId: ticketTemplate.ticketTemplateId,
            amount: (ticketTemplate.price * (1 + event.feeRate)).toFixed(2),
        }];

        if (cardSelected) {
            seats.push(
                {
                    ticketName: nfcCardTicket.name,
                    ticketTemplateId: nfcCardTicket.ticketTemplateId,
                    area: membershipCard.area,
                    side: membershipCard.side,
                    row: membershipCard.row,
                    seat: membershipCard.seat,
                    amount: (nfcCardTicket.price * (1 + event.feeRate)).toFixed(2),
                })
        }

        MySwal.close();
        onConfirmBuySeasonPass(seats, membershipCard.customerName, totalPrice, pointsToDiscount, { companyVat: companyVatNumber });
    };

    const handleOnBuyNfcChange = () => {
        setCardSelected(!cardSelected);
    }

    const changeInputCompanyVatHandler = (e) => {
        setCompanyVatNumber(e.target.value);
    }

    return <>
        <section>

            <p><strong>{seatLabel}</strong></p>
            <p>{txt.BuySeasonPassModalTxt['NFCDescription']}</p>
            <CheckBox
                name="buyNfcCard"
                id="buy-nfc-card"
                checked={cardSelected}
                onChange={handleOnBuyNfcChange}>
                {txt.BuySeasonPassModalTxt['buyPhysicalNFCCard']}
            </CheckBox>

            <section>

                <SelectedTickets
                    hasSeats={true}
                    mixedTickets={true}
                    feeRate={event.feeRate}
                    tickets={tickets}
                    currency={'RSD'}
                    total={totalPrice}
                    allowEnterCouponCode={false}
                    autoHeight={true}
                    pointsForDiscount={pointsToDiscount}
                    langCode={langCode}
                />
                <input
                    type="text"
                    name="companyVatNumber"
                    placeholder={txt.BuySeasonPassModalTxt['vatPlaceholder']}
                    value={companyVatNumber}
                    onChange={changeInputCompanyVatHandler}
                />
            </section>

            <section className='swal2-actions'>

                <button className="swal2-cancel swal2-styled"
                    onClick={() => MySwal.close()} >
                    {seasonPassPersonalInfoLangs[langCode].generalTxt['cancel']}
                </button>
                <button className="swal2-confirm swal2-styled"
                    onClick={confirmHandler}
                >
                    {seasonPassPersonalInfoLangs[langCode].generalTxt['confirm']}
                </button>

            </section>

        </section>
    </>
}

function getSeatLabel(membershipCard) {
    return `${membershipCard.area} ${membershipCard.side === 'L' && 'Left'
        || membershipCard.side === 'R' && 'Right'
        || membershipCard.side === 'M' && 'Center'
        || ''
        } ${membershipCard.row} ${membershipCard.seat}`
}

function entreeTicketToSeatsIointerface(ticket, ticketTemplate) {
    const hasSeats = ticketTemplate.hasSeats;

    if (!hasSeats) {
        return {
            allowedToSell: 1,
            capacity: 1,
            categoryKey: "",
            count: 1,
            hasSeats: false,
            isAvailableToSale: true,
            name: ticketTemplate.name,
            price: ticketTemplate.price
        }
    }

    const hasSide = ticket.side === 'L' || ticket.side === 'R'
    const label = ticket.area + (hasSide && `-${ticket.side}`) + `-${ticket.row}-${ticket.seat}`

    return {
        category: {
            label: ticketTemplate.name,
        },
        id: ticket.id,
        label: '',
        labels: {
            displayedLabel: label,
            own: ticket.seat,
            parent: ticket.row,
            section: ticket.area + hasSide ? `-${ticket.side}` : '',
        },
        pricing: {
            formattedPrice: ticketTemplate.price,
            price: ticketTemplate.price,
        },
        price: ticketTemplate.price,
        seatId: label
    }
}