import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import ROUTES from '../../../constants/routes';
import { register } from '../../../store/actions/authActions';

import './Registration.sass';
import { history } from '../../../helpers/history';
import NewLoader from '../../../components/NewLoader/NewLoader';
import browserTabTitleOverride from '../../../helpers/tabTitleOverride';

class Registration extends Component {
  state = {
    isVisible: true,
    name: '',
    email: '',
    phone: '',
    password: '',
    confirmPassword: '',
    companyName: '',
    website: '',
    address: '',
    isAgree: false,
    errors: [],
    error: '',
  };

  componentDidMount() {
    browserTabTitleOverride();
  }

  componentDidUpdate(prevProps) {
    if (this.props.auth.error !== prevProps.auth.error && this.props.auth.error) {
      if (this.props.auth.error.response) {
        const { data } = this.props.auth.error.response;
        if (
          data.Phone &&
          data.Phone[0] === 'Phone field should contain only "+" and numbers.'
        ) {
          data.Phone = ['Phone field should contain one "+" and numbers.'];
        }
        this.setState({ errors: [data] }, () => {
          this.setState({
            error: Object.values(this.state.errors[0])[0][0],
          });
        });
      } else {
        this.setState({ error: this.props.auth.error.message });
      }
    }
  }

  toggleRegistrationScreen = () =>
    this.setState({ isVisible: !this.state.isVisible });

  inputChangeHandler = (event) => {
    const { name, value } = event.target;

    this.setState({ [name]: value });
  };

  isAgreeChangeHandler = () => {
    const { isAgree } = this.state;

    this.setState({ isAgree: !isAgree });
  };

  registrationHandler = () => {
    const {
      name,
      email,
      phone,
      password,
      confirmPassword,
      companyName,
      website,
      address,
    } = this.state;
    const { register } = this.props;

    const data = {
      name,
      email,
      phone,
      password,
      website,
      companyName,
      address,
    };

    if (password === confirmPassword) {
      register(data).then((result) => {
        if (result && result.id) {
          history.push(ROUTES.REGISTRATION_SUCCESS);
        }
      });
    } else {
      this.setState({ error: "Passwords don't match" });
    }
  };

  render() {
    const { isVisible, isAgree, error } = this.state;

    return (
      <div className="auth registration">
        {this.props.auth.loadingRegister ? <NewLoader isFullScreen /> : null}
        <div
          className={
            isVisible
              ? 'registration__contact-details visible'
              : 'registration__contact-details hidden'
          }>
          <img
            className="auth__logo"
            src="/images/img-logo.svg"
            alt="Entrance logo"
          />
          <div className="auth__title">Registration</div>
          <div className="auth__subtitle">Contact details</div>
          <form>
            {error && <p className="form-error">{error}</p>}
            <input
              type="text"
              name="name"
              placeholder="Name"
              minLength="3"
              maxLength="60"
              onChange={this.inputChangeHandler}
            />
            <input
              type="email"
              name="email"
              placeholder="Email"
              maxLength="320"
              onChange={this.inputChangeHandler}
            />
            <input
              type="tel"
              name="phone"
              placeholder="Phone"
              onChange={this.inputChangeHandler}
            />
            <input
              type="password"
              name="password"
              placeholder="Password"
              onChange={this.inputChangeHandler}
            />
            <input
              type="password"
              name="confirmPassword"
              placeholder="Confirm password"
              onChange={this.inputChangeHandler}
            />
          </form>
          <button
            className="button-next"
            onClick={this.toggleRegistrationScreen}
          />
        </div>
        <div
          className={
            isVisible
              ? 'registration__company-details hidden'
              : 'registration__company-details visible'
          }>
          <button
            className="button-prev"
            onClick={this.toggleRegistrationScreen}
          />
          <div className="auth__title">Registration</div>
          <div className="auth__subtitle">Company details</div>
          <form>
            {error && <p className="form-error xs-hidden">{error}</p>}
            <input
              type="text"
              name="companyName"
              placeholder="Company name"
              onChange={this.inputChangeHandler}
            />
            <input
              type="text"
              name="website"
              placeholder="Website"
              onChange={this.inputChangeHandler}
            />
            <input
              type="text"
              name="address"
              placeholder="Address"
              onChange={this.inputChangeHandler}
            />
          </form>
          <div className="accept-terms">
            <div className="checkbox">
              <input type="checkbox" id="javascript" />
              <label htmlFor="javascript" onClick={this.isAgreeChangeHandler}>
                I agree with{' '}
                {/* TODO: Maybe later <span> need change to <Link> or <Button> (Gleb Bigma) */}
                <span>Terms and Conditions</span>
              </label>
            </div>
          </div>
          <button
            type="button"
            className="btn-primary"
            onClick={
              !this.props.auth.loadingRegister
                ? this.registrationHandler
                : () => {}
            }
            disabled={!isAgree}>
            Registration
          </button>
        </div>
        <div className="registration__sign-in">
          Already a member? <Link to={ROUTES.LOGIN}>Sign in</Link>
        </div>
      </div>
    );
  }
}

Registration.propTypes = {};

const mapStateToProps = ({ auth }) => ({ auth });

const mapDispatchToProps = (dispatch) => ({
  register: (data) => {
    return dispatch(register(data));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(Registration);
