import React, { useState } from 'react';
import withReactContent from "sweetalert2-react-content";
import Swal from "sweetalert2";

import { seasonPassPersonalInfoLangs } from '../../../../constants/seasonPassPersonalInfoLangs';
import { SelectedTickets } from '../../PartizanOpenSelling/SelectedTickets';

const MySwal = withReactContent(Swal);

export const BuyAllSeasonPassesModal = ({
    membershipCards,
    event,
    selectedTicketTemplates,
    onConfirmBuySeasonPass,
    availablePoints,
    langCode }) => {

    const [companyName, setCompanyName] = useState(membershipCards[0].customerName);
    const [companyVat, setCompanyVat] = useState('');
    const [companyCity, setCompanyCity] = useState('');
    const [companyAddress, setCompanyAddress] = useState('');

    const txt = seasonPassPersonalInfoLangs[langCode];
    const nfcCardTicket = event.ticketTemplates.find(t => t.ticketTemplateId == process.env.REACT_APP_NEW_SEASON_NFC_CARD_TICKET_TEMPLATE_ID);
    membershipCards = membershipCards.filter(c => !!c.seat)
    const templates = selectedTicketTemplates.map(t => {
        return {
            name: `${t.possibleSeats.length} x ${t.name}`,
            price: t.price,
            count: t.possibleSeats.length
        }
    });

    templates.push({
        name: `${membershipCards.length} x ${nfcCardTicket.name}`,
        price: nfcCardTicket.price,
        count: membershipCards.length
    })

    let totalPrice = templates.reduce((a, i) => a + (i.price * i.count), 0);

    let pointsToDiscount = (totalPrice / 2).toFixed(0);

    if (pointsToDiscount > availablePoints) {
        pointsToDiscount = availablePoints;
    }

    totalPrice = ((totalPrice - pointsToDiscount)).toFixed(2);

    const confirmHandler = () => {
        const seats = membershipCards.flatMap(membershipCard => {
            const ticketTemplate = selectedTicketTemplates.find(t => t.possibleSeats.includes(getLabel(membershipCard)))

            return [{
                ticketName: ticketTemplate.name,
                area: membershipCard.area,
                side: membershipCard.side,
                row: membershipCard.row,
                seat: membershipCard.seat,
                ticketTemplateId: ticketTemplate.ticketTemplateId,
                amount: (ticketTemplate.price).toFixed(2),
            }, {
                ticketName: nfcCardTicket.name,
                area: membershipCard.area,
                side: membershipCard.side,
                row: membershipCard.row,
                seat: membershipCard.seat,
                ticketTemplateId: nfcCardTicket.ticketTemplateId,
                amount: (nfcCardTicket.price).toFixed(2),
            }]
        });



        MySwal.close();
        onConfirmBuySeasonPass(seats, '', totalPrice, pointsToDiscount, {
            companyName,
            companyCity,
            companyAddress,
            companyVat
        });
    };

    return <>
        <section>

            <p style={{ color: '#ababab', marginBottom: '24px' }} className='swal2-label'>{txt.BuyAllSeasonPassesModalTxt['modalDescription']}</p>

            <div>
                <span className='swal2-label'>{txt.BuyAllSeasonPassesModalTxt['companyNameLabel']}</span>
            </div>
            <input value={companyName}
                placeholder={txt.BuyAllSeasonPassesModalTxt['companyNamePlaceholder']}
                className="modal-input"
                onChange={e => setCompanyName(e.target.value)} />

            <div>
                <span className='swal2-label'>{txt.BuyAllSeasonPassesModalTxt['companyAddressLabel']}</span>
            </div>
            <input value={companyCity}
                placeholder={txt.BuyAllSeasonPassesModalTxt['companyCityPlaceholder']}
                className="modal-input"
                onChange={e => setCompanyCity(e.target.value)} />

            <input value={companyAddress}
                placeholder={txt.BuyAllSeasonPassesModalTxt['companyAddressPlaceholder']}
                className="modal-input"
                onChange={e => setCompanyAddress(e.target.value)} />

            <div>
                <span className='swal2-label'>{txt.BuyAllSeasonPassesModalTxt['companyVatLabel']}</span>
            </div>
            <input value={companyVat}
                placeholder={txt.BuyAllSeasonPassesModalTxt['companyVatPlaceholder']}
                className="modal-input"
                onChange={e => setCompanyVat(e.target.value)} />

            <SelectedTickets
                hasSeats={false}
                mixedTickets={false}
                feeRate={0} // disabled for invoices
                tickets={templates}
                currency={'RSD'}
                total={totalPrice}
                allowEnterCouponCode={false}
                autoHeight={true}
                pointsForDiscount={pointsToDiscount}
                langCode={langCode}
            />

            <section className='swal2-actions'>

                <button className="swal2-cancel swal2-styled"
                    onClick={() => MySwal.close()}>
                    {seasonPassPersonalInfoLangs[langCode].generalTxt['cancel']}
                </button>

                <button disabled={(!companyName || !companyVat || !companyCity || !companyAddress)} className="swal2-confirm swal2-styled"
                    onClick={confirmHandler}>
                    {txt.BuyAllSeasonPassesModalTxt['createInvoiceButton']}
                </button>

            </section>

        </section>
    </>
}

function getLabel(ticket) {
    if (!ticket.seat) {
        return '';
    }

    return ticket.area + (!!ticket.side ? `-${ticket.side}` : '') + `-${ticket.row}-${ticket.seat}`
}