import axios from "../axios-instance";
import fileToBase64 from "./fileToBase64";
import { getHeaders } from "./getHeaders";
import getImageElementFromUrl from "./getImageElementFromUrl";

export const getFooterImagePdfTemplate = async () => {
  const user = JSON.parse(localStorage.getItem("user"));
  const token = user.token;

  const { data: footerImage } = await axios.get("/PdfTemplate/FooterImage", {
    headers: getHeaders(token),
    responseType: "blob",
  });

  const imageInBase64 = await fileToBase64(footerImage);

  return await getImageElementFromUrl(imageInBase64);
};
