import React, { Component } from "react";
import { withRouter } from "react-router";

import moment from "moment";
import swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";

import axios from "../../axios-instance";

import Select from "react-select";

import CheckBox from "../../components/UI/Checkbox/CheckBox";
import Container from "../../components/UI/Container/Container";

import TermsAndConditionsModal from "./TermsAndConditionsModal/TermsAndConditionsModal";

import {
  bankLogos,
  numOfTiketsOption,
  price,
  resURL,
  signedKeys,
} from "./constants";

import SeasonPassLogo from "../../images/season-pass-logo.svg";
import ThanksForYourPurchase from "../../images/thanks-for-your-purchase.svg";
import PleaseWait from "../../images/please-wait-season-pass-modal.svg";

import { createMerchantSin } from "./helpers";

import "./SeasonPass.sass";
import ROUTES from "../../constants/routes";
import { showInfoModal, showErrorModal } from "../../modals/index";

const MySwal = withReactContent(swal);

const defaultState = {
  fields: {
    fullName: "",
    physicalAddress: "",
    city: "",
    postalCode: "",
    phoneNumber: "",
    buyerEmail: "",
    numberOfTickets: numOfTiketsOption[0].value,
  },
  transactionData: {
    orderId: "",
    paymentAmount: price,
    currencyCode: "RSD",
    //TODO: should check
    sessionValidity: "2023-08-28T14:26:53.000Z",
    resURL: resURL,
    lang: "en",
    merchantName: "WE ARE FANCEE d.o.o",
    signedKeys: signedKeys,
  },
  termsAndConditionsChecked: false,
  submittBlocked: false,
};

class BuyTickets extends Component {
  state = defaultState;

  formRef = React.createRef();

  setOrderDataToState = (data) => {
    const transactionData = {
      ...this.state.transactionData,
      orderId: data.id,
      paymentAmount: data.amount,
      currencyCode: data.currency,
      redirectURL: `${window.location.origin}${ROUTES.BUY_TICKETS.replace(
        ":id?",
        data.id
      )}?`,
    };

    const fields = {
      fullName: data.fullName,
      city: data.city,
      postalCode: data.postalCode,
      phoneNumber: data.phoneNumber,
      buyerEmail: data.buyerEmail,
      physicalAddress: data.physicalAddress,
      numberOfTickets: data.numberOfTickets,
    };

    transactionData.merchantSig = createMerchantSin(
      transactionData,
      transactionData.signedKeys
    );

    this.setState({
      fileUrl: data.fileUrl,
      transactionData,
      fields,
    });
  };

  showPleaseWaitModal = () => {
    MySwal.fire({
      imageUrl: PleaseWait,
      title: "Please wait",
      text: "You will be redirected to the payment page",
      allowEscapeKey: false,
      customClass: "seasonpass__modal",
      allowOutsideClick: false,
      allowEnterKey: false,
      showConfirmButton: false,
    });
  };

  showTermsAndConditionsModal = (ev) => {
    ev.preventDefault();
    MySwal.fire({
      html: <TermsAndConditionsModal />,
      width: "64em",
      customClass: "seasonpass__modal",
    });
  };

  handleOrderStatuses = (data) => {
    switch (data.paymentStatus) {
      //New
      case 0:
        this.setState({ submittBlocked: false });
        break;
      //Success
      case 1:
        showInfoModal({
          image: ThanksForYourPurchase,
          title: "Thanks for Your Purchase!",
          text: `We will send all the details to your inbox (${data.buyerEmail}) and look forward to meeting you`,
          showConfirmButton: true,
          confirmButtonText: "Buy More",
          onConfirm: (result) => {
            this.resetFields();
          },
          additionalText: `(Payment Status: ${
            data.additionalPaymentStatus
          }, Payment Date: ${moment(data.paymentDate).format(
            "DD MMMM YYYY HH:mm"
          )}, Transaction Id: ${data.transactionId})`,
        });

        this.setState({ submittBlocked: false });
        break;
      //Failed
      case 2:
        showErrorModal({
          additionalText: `(Payment Status: ${
            data.additionalPaymentStatus
          }, Payment Date: ${moment(data.paymentDate).format(
            "DD MMMM YYYY HH:mm"
          )}, Transaction Id: ${data.transactionId})`,
        });
        this.setState({ submittBlocked: false });
        break;
      //Cancelled
      case 3:
        this.setState({ submittBlocked: false });
        break;
      default:
        this.setState({ submittBlocked: true });
        break;
    }
  };

  getOrderData = async () => {
    try {
      const orderId = this.props.match.params.id;

      if (orderId) {
        const { data } = await axios.get(
          `Payment/orders/${this.props.match.params.id}`
        );
        if (data) {
          this.handleOrderStatuses(data);
          this.setOrderDataToState(data);
        }
      }
    } catch (e) {
      showErrorModal();
      console.log(e);
    }
  };

  setTermsAndConditions = () => {
    const { termsAndConditionsChecked } = this.state;
    this.setState({ termsAndConditionsChecked: !termsAndConditionsChecked });
  };

  handleChange = (name, value) => {
    if (name) {
      this.setState({ fields: { ...this.state.fields, [name]: value } });
    }
  };

  resetFields = () => {
    this.props.history.push(ROUTES.BUY_TICKETS.replace("/:id", ""));
    this.setState(defaultState);
  };

  handleNumOfTickets = (value) => {
    this.setState({
      fields: {
        ...this.state.fields,
        numberOfTickets: value,
      },
      transactionData: {
        ...this.state.transactionData,
        paymentAmount: value * price,
      },
    });
  };

  checkIsSubbmitDisabled = () => {
    const {
      submittBlocked,
      fields: { city, physicalAddress, postalCode, ...otherField },
      termsAndConditionsChecked,
    } = this.state;

    let isSubbmitDisabled =
      !Object.keys(otherField).every((key) => !!otherField[key]) ||
      submittBlocked ||
      !termsAndConditionsChecked;

    return isSubbmitDisabled;
  };

  componentDidMount() {
    this.getOrderData();
  }

  addOrderIdToUrl = (id) => {
    this.props.history.push({
      pathname: ROUTES.BUY_TICKETS.replace(":id?", id),
    });
  };

  sendOrderData = async () => {
    const { fields, transactionData } = this.state;
    const body = {
      order: {
        ...fields,
        amount: transactionData.paymentAmount,
        currency: transactionData.currencyCode,
      },
    };

    const { data: id } = await axios.post("/Order/createForFunZone", body);
    this.addOrderIdToUrl(id);
  };

  handleSubmit = async () => {
    try {
      this.showPleaseWaitModal();
      await this.sendOrderData();
      await this.getOrderData();
      this.formRef.current.submit();
    } catch (e) {
      console.log(e);
      showErrorModal();
    }
  };

  render() {
    const {
      fields: {
        city,
        buyerEmail,
        fullName,
        physicalAddress,
        phoneNumber,
        postalCode,
        numberOfTickets,
      },
      transactionData,
      termsAndConditionsChecked,
    } = this.state;

    return (
      <Container className="seasonpass">
        <div className="seasonpass__wrapper">
          <div className="seasonpass__header-logo-wrapper">
            <img src={SeasonPassLogo} alt="Logo" />
          </div>
          <div className="seasonpass__header">
            <h2 className="seasonpass__header-title">Buy Tickets</h2>
          </div>
          <form
            ref={this.formRef}
            action={process.env.REACT_APP_PAYMENT_LINK}
            method="post"
            className="seasonpass__form"
          >
            {Object.keys(transactionData).map((key) => (
              <input
                name={key}
                key={key}
                type="hidden"
                value={transactionData[key]}
              />
            ))}
            <label className="seasonpass__field">
              <span className="seasonpass__field-text">
                <span className="seasonpass__field-text-label">Full Name</span>
                {!fullName && (
                  <span className="seasonpass__field-text-required">
                    Required Field
                  </span>
                )}
              </span>
              <input
                className={`seasonpass__field-input ${!"disabled"}`}
                name="fullName"
                onChange={(e) => this.handleChange("fullName", e.target.value)}
                type="text"
                value={fullName}
              />
            </label>

            <div className="seasonpass__grouped-fields">
              <label className="seasonpass__field">
                <span className="seasonpass__field-text">
                  <span className="seasonpass__field-text-label">
                    Phone Number
                  </span>
                  {!phoneNumber && (
                    <span className="seasonpass__field-text-required">
                      Required Field
                    </span>
                  )}
                </span>
                <input
                  className="seasonpass__field-input"
                  type="text"
                  name="phoneNumber"
                  onChange={(e) =>
                    this.handleChange("phoneNumber", e.target.value)
                  }
                  value={phoneNumber}
                />
              </label>

              <label className="seasonpass__field">
                <span className="seasonpass__field-text">
                  <span className="seasonpass__field-text-label">Email</span>
                  {!buyerEmail && (
                    <span className="seasonpass__field-text-required">
                      Required Field
                    </span>
                  )}
                </span>
                <input
                  className="seasonpass__field-input"
                  name="buyerEmail"
                  onChange={(e) =>
                    this.handleChange("buyerEmail", e.target.value)
                  }
                  type="text"
                  value={buyerEmail}
                />
              </label>
            </div>

            <label className="seasonpass__field">
              <span className="seasonpass__field-text">
                <span className="seasonpass__field-text-label">
                  Street and House Number
                </span>
              </span>
              <input
                className={`seasonpass__field-input ${!"disabled"}`}
                name="physicalAddress"
                onChange={(e) =>
                  this.handleChange("physicalAddress", e.target.value)
                }
                type="text"
                value={physicalAddress}
              />
            </label>

            <div className="seasonpass__grouped-fields">
              <label className="seasonpass__field">
                <span className="seasonpass__field-text">
                  <span className="seasonpass__field-text-label">City</span>
                </span>
                <input
                  className="seasonpass__field-input"
                  name="city"
                  onChange={(e) => this.handleChange("city", e.target.value)}
                  value={city}
                  type="text"
                />
              </label>

              <label className="seasonpass__field">
                <span className="seasonpass__field-text">
                  <span className="seasonpass__field-text-label">
                    Postal Code
                  </span>
                </span>
                <input
                  className="seasonpass__field-input"
                  name="postalCode"
                  onChange={(e) =>
                    this.handleChange("postalCode", e.target.value)
                  }
                  type="text"
                  value={postalCode}
                />
              </label>
            </div>

            <label className="seasonpass__field">
              <span className="seasonpass__field-text">
                <span className="seasonpass__field-text-label">
                  Number of Tickets
                </span>
                {!numberOfTickets && (
                  <span className="seasonpass__field-text-required">
                    Required Field
                  </span>
                )}
              </span>

              <Select
                options={numOfTiketsOption}
                name="numberOfTickets"
                value={numOfTiketsOption.find(
                  ({ value }) => numberOfTickets === value
                )}
                onChange={(value) => this.handleNumOfTickets(value.value)}
                classNamePrefix="seasonpass__select"
                className="seasonpass__select"
              />
            </label>

            <div className="seasonpass__result-wrapper">
              <div>
                <div className="seasonpass__terms-and-conditions ">
                  <CheckBox
                    checked={termsAndConditionsChecked}
                    id="terms-and-conditions-checkbox"
                    name="terms-and-conditions-checkbox"
                    onChange={this.setTermsAndConditions}
                  >
                    <span onClick={this.showTermsAndConditionsModal}>
                      I agree to the Terms and Conditions
                    </span>
                  </CheckBox>
                </div>
                <button
                  type="button"
                  onClick={this.handleSubmit}
                  className="btn-primary seasonpass__btn"
                  disabled={this.checkIsSubbmitDisabled()}
                >
                  Buy
                </button>
              </div>

              <div>
                <span className="seasonpass__total-text">Total:</span>{" "}
                <span>
                  {transactionData.paymentAmount} {transactionData.currencyCode}
                </span>
              </div>
            </div>
            <div className="seasonpass__bank-logos">
              {bankLogos.map(({ alt, src, link }) => (
                <div key={alt}>
                  <a target="_blank" rel="noopener noreferrer" href="{link}">
                    <img src={src} alt={alt} />
                  </a>
                </div>
              ))}
            </div>
          </form>
        </div>
      </Container>
    );
  }
}

export default withRouter(BuyTickets);
