import React from 'react';
import ReactTable from 'react-table';

import './FourthTab.sass';
import 'react-table/react-table.css';




const FourthTab = props => {

    const columns = [
        {
            Header: 'BAR-code',
            accessor: 'barCode',
        },
        {
            Header: 'Phone',
            accessor: 'phone',
        },
        {
            Header: 'Email',
            accessor: 'email',
        },
        {
            Header: 'Name',
            accessor: 'name',
        },
        {
            Header: 'Gender',
            accessor: 'gender',
        },
        {
            Header: 'Age',
            accessor: 'age',
        },
        {
            Header: 'Address',
            accessor: 'address',
        },
    ];

    return <div className="fourth-tab">
        <div className="input-block">
            <div className="promoter-name">{props.inputValue}</div>
        </div>

        <ReactTable
            data={props.isLineIgnore ? props.tableData.slice(1) : props.tableData}
            columns={columns}
            showPagination={false}
            minRows={0}
            sortable={false}
            getTrProps={(state, rowInfo) => ({
                onClick: (e) => {console.log(rowInfo)},
            })}
        />

    </div>;
}

export default FourthTab;
