import React from "react";
import { SeatsioSeatingChart } from "@seatsio/seatsio-react";

// import Icon from "../../../../components/UI/Icon/Icon";
import { SelectedTickets } from "../SelectedTickets";
import NewLoader from "../../../../components/NewLoader/NewLoader";
import { TicketsPicker } from "../TicketsPicker";

import "../PartizanOpenSelling.sass";

export const SelectTickets = ({
  tickets,
  setCouponHandler,
  coupon,
  total,
  ticketTemplates,
  onObjectSelected,
  currency,
  pricing,
  onObjectDeselected,
  feeRate,
  loading,
  workspaceKey,
  eventSeatsId,
  event,
  isOffers,
  maxSelectedObjects = 4,
  hasSeats,
}) => {
  const renderSelectSection = () => {
    switch (hasSeats) {
      case false:
        return (
          <TicketsPicker
            onObjectSelected={onObjectSelected}
            onObjectDeselected={onObjectDeselected}
            ticketTemplates={ticketTemplates}
            currency={currency}
          />
        );
      case true:
        return (
          <SeatsioSeatingChart
            maxSelectedObjects={maxSelectedObjects}
            pricing={pricing}
            session="continue"
            priceFormatter={(price) => `${price} ${currency}`}
            onObjectSelected={onObjectSelected}
            onObjectDeselected={onObjectDeselected}
            workspaceKey={workspaceKey}
            event={eventSeatsId}
            region="eu"
          />
        );
      default:
        return null;
    }
  };

  if (isOffers) {
    return (
      <div className="partizan-open-selling__step-info">
        {loading ? (
          <div style={{ height: "550px" }}>
            <NewLoader />
          </div>
        ) : (
          <SelectedTickets
            hasSeats={hasSeats}
            feeRate={feeRate}
            tickets={tickets}
            currency={currency}
            total={total}
            event={event}
            setCouponHandler={setCouponHandler}
            coupon={coupon}
          />
        )}
      </div>
    );
  }

  return (
    <div className="partizan-open-selling__step partizan-open-selling__select-tickets">
      <div className="partizan-open-selling__step-hero">
        <h4 className="partizan-open-selling__text-bold">
          {hasSeats ? "Choose Your Seat On Map" : "Tickets"}
        </h4>
        <div
          className={`partizan-open-selling__select-section ${
            hasSeats ? "partizan-open-selling__select-section_no-scroll" : ""
          }`}
        >
          {renderSelectSection()}
        </div>
      </div>
      <div className="partizan-open-selling__step-info">
        <SelectedTickets
          hasSeats={hasSeats}
          feeRate={feeRate}
          tickets={tickets}
          currency={currency}
          total={total}
          event={event}
          setCouponHandler={setCouponHandler}
          coupon={coupon}
        />
      </div>
    </div>
  );
};
