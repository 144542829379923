import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import withRouter from "react-router/es/withRouter";
import { Redirect, Route, Router, Switch } from "react-router-dom";

import { refreshToken } from "./store/actions/authActions";
import { history } from "./helpers/history";
import ROUTES from "./constants/routes";
import NotAuthorizedLayout from "./components/Layouts/NotAuthorizedLayout";
import AuthorizedLayout from "./components/Layouts/AuthorizedLayout";
import ROLES from "./constants/roles";
import Page404 from "./components/Page404/Page404";
import SeasonPass from "./containers/SeasonPass/SeasonPass";
import OnlineSeller from "./containers/SeasonPass/OnlineSeller";
import BuyTickets from "./containers/SeasonPass/BuyTickets";
import ApproveRequest from "./containers/SeasonPass/ApproveRequest";
import Orders from "./containers/SeasonPass/Orders/Orders";
import PartizanSeasonPass from "./containers/SeasonPass/PartizanSeasonPass/PartizanSeasonPass";
import PartizanOnlineSeller from "./containers/SeasonPass/PartizanOnlineSeller/PartizanOnlineSeller";
import PartizanOpenSelling from "./containers/SeasonPass/PartizanOpenSelling/PartizanOpenSelling";
import SeasonPassMember from "./containers/SeasonPass/SeasonPassMember/SeasonPassMember";
import Lovefest from "./containers/SeasonPass/PartizanOpenSelling/Lovefest";

import { ToastContainer, Slide } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import "./style/App.sass";
import TestIframe from "./containers/TestIframe";
import ResellingRequest from "./containers/ResellingRequest/ResellingRequest";
import SellingEventsList from "./containers/SellingEventsList/SellingEventsList";
import SatsScan from "./containers/SatsScan/SatsScan";
import SatsSells from "./containers/SatsSells/SatsSells";
import SeasonPassPersonalInfo from "./containers/SeasonPass/SeasonPassPersonalInfo/SeasonPassPersonalInfo";

class App extends Component {
  componentDidMount() {
    if (localStorage.getItem("user")) {
      const data = {
        refreshToken: this.props.auth.user.refreshToken,
      };
      this.props.refreshToken(data);
    }
  }

  render() {
    const { loading, user } = this.props.auth;

    if (loading) {
      return null;
    }

    let routes = null;

    if (user.role === ROLES.PROMOTER) {
      routes = (
        <Fragment>
          <Switch>
            <Route path={ROUTES.FAQ} component={AuthorizedLayout} />
            <Route path={ROUTES.ADD_EVENT} component={AuthorizedLayout} />
            <Route path={ROUTES.EVENTS} component={AuthorizedLayout} />
            <Route path={ROUTES.EVENT} exact component={AuthorizedLayout} />
            <Route
              path={ROUTES.EVENT_TEMPLATE_EDITOR}
              exact
              component={AuthorizedLayout}
            />
            <Route path={ROUTES.SCANTEAMS} exact component={AuthorizedLayout} />
            <Route
              path={ROUTES.SCANTEAMS_ADD}
              exact
              component={AuthorizedLayout}
            />
            <Route
              path={ROUTES.SCANTEAMS_VIEW}
              exact
              component={AuthorizedLayout}
            />
            <Route path={ROUTES.TICKET_DETAIL} component={AuthorizedLayout} />
            <Route path={ROUTES.PAYMENTS} component={AuthorizedLayout} />
            <Route
              path={ROUTES.ADD_TICKET}
              exact
              component={AuthorizedLayout}
            />
            <Route
              path={ROUTES.ADD_TICKET}
              exact
              component={AuthorizedLayout}
            />
            <Route path={ROUTES.STATS} exact component={AuthorizedLayout} />
            <Route
              path={ROUTES.TRANSACTIONS}
              exact
              component={AuthorizedLayout}
            />
            <Route
              path={ROUTES.MEMBERSHIP_MANAGMENT}
              exact
              component={AuthorizedLayout}
            />
            <Route path={ROUTES.PROMOTERS} component={AuthorizedLayout} />
            {/* <Route path={ROUTES.DASHBOARD} component={AuthorizedLayout} /> */}
            <Route path={ROUTES.STATISTICS} component={AuthorizedLayout} />
            <Route path={ROUTES.STATS_SCANNING} component={AuthorizedLayout} />
            <Route path={ROUTES.STATS_SELLING} component={AuthorizedLayout} />
            <Route path={ROUTES.SELL} component={AuthorizedLayout} />
            <Route
              path={ROUTES.UPLOAD_AVAILABLE_SEATS}
              component={AuthorizedLayout}
            />

            <Redirect from="/" exact to={ROUTES.EVENTS} />

            <Route path="/" component={Page404} />
          </Switch>
        </Fragment>
      );
    }

    if (user.role === ROLES.ADMIN) {
      routes = (
        <Switch>
          <Route path={ROUTES.PROMOTERS} component={AuthorizedLayout} />

          <Redirect from="/" exact to={ROUTES.PROMOTERS} />

          <Route path="/" component={Page404} />
        </Switch>
      );
    }

    return (
      <Fragment>
        <ToastContainer
          position="top-center"
          transition={Slide}
          autoClose={5000}
          pauseOnHover={false}
          closeButton={false}
          closeOnClick={false}
          hideProgressBar
          draggable
        />
        <Router history={history}>
          <Switch>
            <Route path={ROUTES.REGISTRATION} component={NotAuthorizedLayout} />
            <Route
              path={ROUTES.REGISTRATION_SUCCESS}
              component={NotAuthorizedLayout}
            />
            <Route path={ROUTES.LOGIN} component={NotAuthorizedLayout} />
            <Route
              path={ROUTES.RECOVER_PASSWORD}
              component={NotAuthorizedLayout}
            />
            <Route path={ROUTES.NEW_PASSWORD} component={NotAuthorizedLayout} />
            <Route path={ROUTES.SEASON_PASS} component={SeasonPass} />
            <Route path={ROUTES.ONLINE_SELLER} component={OnlineSeller} />
            <Route path={ROUTES.BUY_TICKETS} component={BuyTickets} />
            <Route path={ROUTES.APPROVE_REQUESR} component={ApproveRequest} />
            <Route path={ROUTES.ORDERS} component={Orders} />
            <Route
              path={ROUTES.PARTIZAN_SEASON_PASS}
              component={PartizanSeasonPass}
            />
            <Route
              path={ROUTES.PARTIZAN_ONLINE_SELLER}
              component={PartizanOnlineSeller}
            />
            <Route path={ROUTES.LOVEFEST} component={Lovefest} />
            <Route
              path={ROUTES.EVENTS_LIST_TO_SELLING}
              component={SellingEventsList}
            />
            <Route path={ROUTES.SELLING} component={PartizanOpenSelling} />
            <Route
              path={ROUTES.SELLING_OFFERS}
              component={PartizanOpenSelling}
            />
            <Route
              path={ROUTES.SEASON_PASS_MEMBER}
              component={SeasonPassMember}
            />
            <Route path={ROUTES.MEMBER} component={SeasonPassPersonalInfo} />
            <Route path={"/test-iframe"} component={TestIframe} />
            <Route
              path={ROUTES.RESELLING_REQUEST}
              component={ResellingRequest}
            />
            {!user.role && (
              <Switch>
                <Route
                  path={ROUTES.STATS_SCANNING}
                  exact
                  component={SatsScan}
                />
                <Route
                  path={ROUTES.STATS_SELLING}
                  exact
                  component={SatsSells}
                />
              </Switch>
            )}
            {routes}
            {Object.keys(user).length ? routes : <Redirect to="/login" />}
          </Switch>
        </Router>
      </Fragment>
    );
  }
}

const mapStateToProps = ({ auth }) => ({ auth });

const mapDispatchToProps = (dispatch) => ({
  refreshToken: (data) => {
    dispatch(refreshToken(data));
  },
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(App));
