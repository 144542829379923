import * as actionTypes from '../actionTypes/barCodesTypes';
import axios from '../../axios-instance';
import { getHeaders } from "../../helpers/getHeaders";

const getProvidersStart = () => ({type: actionTypes.GET_PROVIDERS_START});
const getProvidersSuccess = providers => ({type: actionTypes.GET_PROVIDERS_SUCCESS, payload: providers});
const getProvidersFail = error => ({type: actionTypes.GET_PROVIDERS_FAIL, error});

export const getProviders = (userToken, searchProviderValue, skip, take) => dispatch => {
    dispatch(getProvidersStart());
    axios.get(`/Provider?search=${searchProviderValue}&skip=${skip}&take=${take}`, {headers: getHeaders(userToken)})
        .then(response => {
            if (response.data.isSuccess) {
                return response.data.result;
            }
            throw new Error(response.data.errorMessage);
        })
        .then(result => {
            dispatch(getProvidersSuccess(result));
        })
        .catch(err => {
            dispatch(getProvidersFail(err));
        })
};

const getProviderConfigStart = () => ({ type: actionTypes.GET_PROVIDER_CONFIG_START });
const getProviderConfigSuccess = (providerConfig) => ({ type: actionTypes.GET_PROVIDER_CONFIG_SUCCESS, payload: providerConfig });
const getProviderConfigFail = (error) => ({ type: actionTypes.GET_PROVIDER_CONFIG_FAIL, error });

export const getProviderConfig = (userToken, providerId) => dispatch => {
    dispatch(getProviderConfigStart());
    return axios.get(`/Provider/${providerId}`, { headers: getHeaders(userToken) })
        .then(response => {
            if (response.data.isSuccess) {
                return response.data.result;
            }
            throw new Error(response.data.errorMessage);
        })
        .then(result => {
            dispatch(getProviderConfigSuccess(result));
            return result;
        })
        .catch(err => {
            dispatch(getProviderConfigFail(err));
        })
};

const uploadTicketsTableStart = () => ({type: actionTypes.UPLOAD_TICKETS_TABLE_START});
const uploadTicketsTableSuccess = table => ({type: actionTypes.UPLOAD_TICKETS_TABLE_SUCCESS, payload: table});
const uploadTicketsTableFail = error => ({type: actionTypes.UPLOAD_TICKETS_TABLE_FAIL, error});

export const uploadTicketsTable = (userToken, file) => dispatch => {
    dispatch(uploadTicketsTableStart());
    const data = new FormData();
    data.append('file', file[0]);
    return axios.post('/Tickets/UploadTicketsTable', data, {headers: {
            'Authorization': `Bearer ${userToken}`,
            'Content-Type': 'multipart/form-data',
            'Accept': 'text/plain'
    }})
        .then(response => {
            if (response.data.isSuccess) {
                return response.data;
            }
            throw response.data;
        })
        .then(result => {
            dispatch(uploadTicketsTableSuccess(result));
            return result;
        })
        .catch(err => {
            dispatch(uploadTicketsTableFail(err));
            return err;
        })
};


const uploadBarcodeStart = () => ({type: actionTypes.UPLOAD_BARDCODES_START});
const uploadBarcodeSuccess = () => ({type: actionTypes.UPLOAD_BARDCODES_SUCCESS});
const uploadBarcodeFail = () => ({type: actionTypes.UPLOAD_BARDCODES_FAIL});

export const uploadBarcodes = (data, token) => dispatch => {
    dispatch(uploadBarcodeStart());
    return axios.post(`/Tickets/ProcessTicketTable`, data, {
            headers: getHeaders(token),
            params: { needSaveConfigurations: true }
        })
        .then(response => {
            if (response.data.isSuccess) {
                return response.data;
            }
            throw new Error(response.data.errorMessage);
        })
        .then(result => {
            dispatch(uploadBarcodeSuccess(result));
            return result;
        })
        .catch(err => {
            dispatch(uploadBarcodeFail(err));
            return {isSuccess: false}
        })
};
