    import ROUTES from "../constants/routes";


    const browserTabTitleOverride = () => {
        const titleForStatisticsPage = ROUTES.STATS.split('/')[ROUTES.STATS.split('/').length - 1]
        const isStatisticsPage = window.location.pathname
            .split('/').includes(titleForStatisticsPage);
        const isRecoverPage = window.location.pathname.split('/')
            .includes(ROUTES.RECOVER_PASSWORD.split('/')[1]);
        const isFaqPage = window.location.pathname.split('/')
            .includes(ROUTES.FAQ.split('/')[1]);
        const mainPart = document.title.split('|')[0];

        const secondaryPart = isStatisticsPage ? titleForStatisticsPage :
        (isRecoverPage ? 'recover' : window.location.pathname.split('/')[1]);
        const finalTitle = `${mainPart} | ${isFaqPage ? secondaryPart.toUpperCase()
            : secondaryPart[0].toUpperCase() + secondaryPart.slice(1)}`;

        return (document.title = document.title !== finalTitle && finalTitle);
    }

    export default browserTabTitleOverride;