import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import ReactTable from "react-table";
import { withRouter } from "react-router";
import ROUTES from "../../constants/routes";
import { getEvents } from "../../store/actions/eventsActions";

import Container from "../../components/UI/Container/Container";
import PageTitle from "../../components/UI/PageTitle/PageTitle";
import EventsMobileTable from "./EventsMobileTable";
import "react-table/react-table.css";
import moment from "moment";

import "./Events.sass";
import NewLoader from "../../components/NewLoader/NewLoader";
import browserTabTitleOverride from "../../helpers/tabTitleOverride";
import TicketsImportContainer from "../../components/TicketsImport/TicketsImportContainer";
import debounce from "lodash.debounce";

class Events extends Component {
  state = {
    searchEventValue: "",
    isPassedEvents: false,
    isNonexistentEvent: false,
  };

  componentDidMount() {
    browserTabTitleOverride();
    const user = JSON.parse(localStorage.getItem("user"));
    const isPassedEvents = JSON.parse(localStorage.getItem("isPassedEvents"));
    if (isPassedEvents) {
      this.setState({ isPassedEvents });
    }
    this.getEvents(user.token, isPassedEvents);
  }

  goToAddEventPage = () => {
    this.props.history.push(ROUTES.ADD_EVENT);
  };

  goToEventPage = (id) => {
    this.props.history.push({
      pathname: ROUTES.EVENT.replace(":id", id),
    });
  };

  getEvents = (userToken, isPassed) => {
    const { isPassedEvents, searchEventValue } = this.state;
    const { getEvents } = this.props;

    getEvents(userToken, isPassed || isPassedEvents, searchEventValue, 0, 0);
  };

  searchEvent = debounce((event) => {
    let { isPassedEvents } = this.state;
    const { getEvents } = this.props;
    const user = JSON.parse(localStorage.getItem("user"));

    this.setState(
      { searchEventValue: event.target.value.trim().replace(/  +/g, " ") },
      () => {
        getEvents(
          user.token,
          isPassedEvents,
          this.state.searchEventValue,
          0,
          0
        ).then((result) => {
          if (result && !result.length) {
            this.setState({ isNonexistentEvent: true });
          }
        });
      }
    );
  }, 450);

  isPassedEvents = (e) => {
    let { isPassedEvents, searchEventValue } = this.state;
    const { getEvents } = this.props;
    const user = JSON.parse(localStorage.getItem("user"));
    localStorage.setItem("isPassedEvents", !isPassedEvents);
    this.setState({ isPassedEvents: !isPassedEvents }, () => {
      getEvents(user.token, this.state.isPassedEvents, searchEventValue, 0, 0);
    });
  };

  toggleModal = () => {
    this.setState({
      showModal: !this.state.showModal,
    });
  };

  render() {
    const { showModal } = this.state;

    const columns = [
      {
        Header: "Event name",
        accessor: "name",
        resizable: false,
        width: 400,
      },
      {
        Header: "Scanned tickets",
        accessor: "scannedTickets",
        resizable: false,
      },
      {
        Header: "All tickets",
        accessor: "allTickets",
        resizable: false,
      },
      {
        Header: "Start",
        id: "startDate",
        accessor: (event) =>
          moment(event.startDate).format("DD MMMM YYYY HH:mm"),
        resizable: false,
      },
      {
        Header: "End",
        id: "endDate",
        accessor: (event) => moment(event.endDate).format("DD MMMM YYYY HH:mm"),
        resizable: false,
      },
    ];
    const { eventsList, loading } = this.props.events;
    const { isPassedEvents } = this.state;
    let emptyStateText = "There are no events";

    if (this.state.isNonexistentEvent) {
      emptyStateText = "Sorry, no results were found";
    }

    return (
      <Container className="events">
        <PageTitle>List of events</PageTitle>

        <input
          className="events__search"
          type="text"
          placeholder="Search"
          onChange={(e) => {
            e.persist();
            this.searchEvent(e);
          }}
        />

        <div className="checkbox">
          <input
            type="checkbox"
            id="passed-events"
            checked={isPassedEvents}
            onClick={this.isPassedEvents}
          />
          <label htmlFor="passed-events">Passed events</label>
          {loading ? (
            <NewLoader />
          ) : eventsList.length ? (
            <Fragment>
              <ReactTable
                data={eventsList}
                columns={columns}
                showPagination={false}
                minRows={0}
                sortable={false}
                defaultPageSize={eventsList.length}
                getTrProps={(state, rowInfo) => ({
                  onClick: (e) => {
                    this.goToEventPage(rowInfo.original.id, e);
                  },
                })}
              />
              <EventsMobileTable
                events={eventsList}
                goToEvent={this.goToEventPage}
              />
            </Fragment>
          ) : (
            <div className="events__empty-state">
              <PageTitle>{emptyStateText}</PageTitle>
              <img src="/images/img-empty-events.svg" alt="Empty events" />
            </div>
          )}
        </div>
        <div className="page-footer">
          <button
            type="button"
            className="btn-primary"
            onClick={this.goToAddEventPage}
          >
            Add event
          </button>
        </div>
      </Container>
    );
  }
}

const mapStateToProps = ({ auth, events }) => ({ auth, events });

const mapDispatchToProps = (dispatch) => ({
  getEvents: (userToken, isPassedEvents, searchEventValue, skip, take) =>
    dispatch(
      getEvents(userToken, isPassedEvents, searchEventValue, skip, take)
    ),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Events));
