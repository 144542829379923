import React, { Component } from "react";

import ReactTable from "react-table";

import {
  getTicketsGeneration,
  loadActionResult,
  getTicketGeneration,
} from "../../../store/actions/ticketGenerationActions";

import "../../SeasonPass/SeasonPassInfoModal/SeasonPassInfoModal.sass";
import "./GuestModal.sass";

import SeasonPassLogo from "../../../images/season-pass-logo.svg";
import NewLoader from "../../../components/NewLoader/NewLoader";

class GuestModal extends Component {
  state = {
    data: [],
    loading: true,
  };

  checkStatusInterval = null;

  getData = async () => {
    try {
      this.setState({ loading: true });

      const { ticketTemplateIds, generationTitle } = this.props;
      const user = JSON.parse(localStorage.getItem("user"));
      const resultData = [];

      for (const id of ticketTemplateIds) {
        const { data } = await getTicketsGeneration(user.token, id);
        const item = data.find((item) => item.resultTag === generationTitle);

        if (item) {
          resultData.push(item);
        }
      }

      this.setState({ loading: false, data: resultData });

      const allCompleted = resultData.every(
        (item) => item.jobStatus === "Completed"
      );

      if (allCompleted) {
        clearInterval(this.checkStatusInterval);
      }
    } catch (e) {
      console.log(e);
    }
  };

  checkStatusAndRefreshData = async (id) => {
    try {
      const user = JSON.parse(localStorage.getItem("user"));
      const { data } = await getTicketGeneration(user.token, id);

      const newData = this.state.data.map((oldItem) =>
        oldItem.id === id ? data : oldItem
      );

      this.setState({ data: newData });
    } catch (e) {
      console.log(e);
    }
  };

  componentDidMount() {
    this.getData();

    this.checkStatusInterval = setInterval(() => {
      const notCompletedItems = this.state.data.filter(
        (item) => item.jobStatus !== "Completed"
      );

      notCompletedItems.forEach((item) => {
        this.checkStatusAndRefreshData(item.id);
      });

      const allCompleted = this.state.data.every(
        (item) => item.jobStatus === "Completed"
      );

      if (allCompleted) {
        clearInterval(this.checkStatusInterval);
      }
    }, 10000);
  }

  componentWillUnmount() {
    clearInterval(this.checkStatusInterval);
  }

  renderActionCell = ({ original: rowData }) => {
    if (rowData.changesRequested && !rowData.originalOrderId) return null;

    const ticket = this.props.ticketTemplates.find(
      ({ ticketTemplateId }) => ticketTemplateId === rowData.ticketTemplateId
    );

    const fileName = ticket
      ? ticket.name.replaceAll(" ", "_") + "_" + rowData.resultTag
      : rowData.resultTag;

    const startButton = (
      <button
        className="btn-primary"
        title="Start"
        onClick={() => this.actionHandler("start", rowData.id)}
      >
        Start
      </button>
    );

    const loadResult = (
      <button
        className="btn-primary"
        title="Load Result"
        onClick={() => loadActionResult(rowData.id, fileName)}
      >
        Load Result
      </button>
    );

    switch (rowData.jobStatus) {
      case "Completed":
        return loadResult;
      case "NotCalled":
      case "Error":
        return startButton;
      default:
        return null;
    }
  };

  render() {
    const { data, loading } = this.state;

    const columns = [
      {
        Header: "Generation Title",
        accessor: "resultTag",
      },
      {
        Header: "Action",
        accessor: "action",
        width: 130,
        Cell: this.renderActionCell,
      },
    ];

    return (
      <div className="season-pass-info-modal guest-modal">
        <div className="season-pass-info-modal__header">
        </div>
        <div className="season-pass-info-modal__body">
          <h3>{this.props.title}</h3>
          {loading ? (
            <NewLoader />
          ) : (
            <ReactTable
              resizable={false}
              showPagination={false}
              sortable={false}
              data={data}
              columns={columns}
              defaultPageSize={data.length}
            />
          )}
        </div>
      </div>
    );
  }
}

export default GuestModal;
