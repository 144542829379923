import * as actionTypes from "../actionTypes/ticketGenerationTypes";
import axios from "../../axios-instance";
import { getHeaders } from "../../helpers/getHeaders";
import { toast } from "react-toastify";
import { loadFile } from "../../helpers/loadFile";

const getActionsStart = () => ({ type: actionTypes.GET_ACTIONS });
const getActionsSuccess = (actions) => ({
  type: actionTypes.GET_ACTIONS_SUCCESS,
  payload: actions,
});
const getActionsFail = (error) => ({
  type: actionTypes.GET_ACTIONS_FAIL,
  error,
});

export const loadActionResult = async (id, name) => {
  try {
    const user = JSON.parse(localStorage.getItem("user"));

    const res = await axios.get(
      `/TicketGeneration/LoadGenerationResult?generationActionId=${id}`,
      {
        headers: getHeaders(user.token),
        responseType: "arraybuffer",
      }
    );

    if (res.data) {
      const fileName = name ? name + ".zip" : "SponsorshipTickets.zip";
      loadFile(res.data, fileName, { type: "application/zip" });
    }
  } catch (e) {
    toast.error("Something went wrong. Try later");
    console.log(e);
  }
};
export const getTicketGeneration = (userToken, generationActionId) =>
  axios.get(`/TicketGeneration/Action/${generationActionId}`, {
    headers: getHeaders(userToken),
  });

export const getTicketsGeneration = (userToken, ticketTemplateId) =>
  axios.get(`/TicketGeneration/Action?ticketTemplateId=${ticketTemplateId}`, {
    headers: getHeaders(userToken),
  });

export const getActions =
  (userToken, ticketTemplateId, shouldStartLoading = true) =>
  (dispatch) => {
    if (shouldStartLoading) dispatch(getActionsStart());
    return getTicketsGeneration(userToken, ticketTemplateId)
      .then((result) => {
        dispatch(getActionsSuccess(result.data));
      })
      .catch((err) => {
        dispatch(getActionsFail(err));
      });
  };

export const actionHandler =
  (userToken, generationActionId, type) => (dispatch) => {
    let url = "";
    let actionType = "";
    let httpMethod = "POST";

    switch (type) {
      case "delete":
        actionType = actionTypes.DELETE_ACTION;
        url = `/TicketGeneration/${generationActionId}`;
        httpMethod = "DELETE";
        break;
      case "clean":
        actionType = actionTypes.DELETE_ACTION;
        url = `/TicketGeneration/ClearResults/${generationActionId}`;
        httpMethod = "DELETE";
        break;
      case "start":
        actionType = actionTypes.START_ACTION;
        url = `/TicketGeneration/GeneratePdfTickets?generationActionId=${generationActionId}`;
        break;
      case "stop":
        actionType = actionTypes.STOP_ACTION;
        url = `/TicketGeneration/StopGeneration?generationActionId=${generationActionId}`;
        break;
      default:
        break;
    }

    dispatch({
      type: actionType,
    });

    return axios({
      method: httpMethod,
      url,
      data: {},
      headers: getHeaders(userToken),
    })
      .then(() => {
        dispatch({
          type: actionType + "_SUCCESS",
        });
      })
      .catch((err) => {
        dispatch({
          type: actionType + "_FAIL",
          payload: err,
        });
      });
  };
