import React, { Component } from "react";
import { withRouter } from "react-router";

import AsyncSelect from "react-select/lib/Async";
import queryString from "query-string";

import swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";

import axios from "../../axios-instance";

import Container from "../../components/UI/Container/Container";

import SeasonPassLogo from "../../images/season-pass-logo.svg";
import Warning from "../../images/warning.svg";
import ApproveRequestSvg from "../../images/approve-request-modal-pic.svg";

import { handleSelectPlace, renderTotal, getTotal } from "./helpers";
import { showInfoModal, showErrorModal } from "../../modals";

import "./SeasonPass.sass";
import "./ApproveRequest.sass";

const MySwal = withReactContent(swal);

class ApproveRequest extends Component {
  state = {
    fields: {
      fullName: "",
      phoneNumber: "",
      buyerEmail: "",
      passType: "",
      area: "",
      row: "",
      seat: "",
      side: "",
    },
    transactionData: {
      orderId: "",
      paymentAmount: "",
      currencyCode: "",
      supervisorId: "",
    },
    initialFields: {
      fields: {
        fullName: "",
        phoneNumber: "",
        buyerEmail: "",
        passType: "",
        area: "",
        row: "",
        seat: "",
        side: "",
      },
      transactionData: {
        orderId: "",
        paymentAmount: "",
        currencyCode: "",
        supervisorId: "",
      },
    },
    total: "",
  };

  formRef = React.createRef();

  setOrderDataToState = (data, supervisorId) => {
    const transactionData = {
      orderId: data.id,
      paymentAmount: data.amount,
      currencyCode: data.currency,
      supervisorId,
    };

    const fields = {
      fullName: data.fullName,
      phoneNumber: data.phoneNumber,
      buyerEmail: data.buyerEmail,
      area: data.area,
      seat: data.seat,
      row: data.row,
      passType: data.passType,
      side: data.side,
    };

    this.setState({
      fields,
      transactionData,
      initialFields: { fields, transactionData },
      total: getTotal(data.amount),
    });
  };

  showApproveRequestModal = () =>
    showInfoModal({
      image: ApproveRequestSvg,
      title: "The Request is Approved",
      text: "Changes will be incorporated into the system",
    });

  showConfirmationModal = () => {
    MySwal.fire({
      imageUrl: Warning,
      title: "Confirm Approval of the Request",
      text: "Please note that this is an irreversible action",
      cancelButtonText: "Cancel",
      confirmButtonText: "Approve",
      allowEscapeKey: false,
      customClass: "seasonpass__modal",
      allowOutsideClick: false,
      allowEnterKey: false,
      showConfirmButton: true,
      showCancelButton: true,
      reverseButtons: true,
    }).then((result) => {
      if (result.value) {
        this.handleApproveRequest();
      }
    });
  };

  handleOrderStatuses = (data) => {
    if (data.changesApproved) {
      this.showApproveRequestModal();
    }
  };

  getOrderData = async (id, supervisorId) => {
    try {
      const { data } = await axios.get(`Payment/orders/${id}`);
      if (data) {
        this.handleOrderStatuses(data);
        this.setOrderDataToState(data, supervisorId);
      }
    } catch (e) {
      showErrorModal();
      console.log(e);
    }
  };

  handleChange = (name, value) => {
    if (name) {
      if (name === "passType") {
        this.setState({
          fields: {
            ...this.state.fields,
            [name]: value.value,
          },
          transactionData: {
            ...this.state.transactionData,
            paymentAmount: value.amount,
          },
          total: getTotal(value.amount),
        });
        return;
      }
      this.setState({ fields: { ...this.state.fields, [name]: value } });
    }
  };

  checkIsSubbmitDisabled = () => {
    const { fields } = this.state;

    let isSubbmitDisabled = !Object.keys(fields).every((key) => !!fields[key]);

    return isSubbmitDisabled;
  };

  componentDidMount() {
    const id = this.props.match.params.id;
    const search = queryString.parse(this.props.location.search);

    if (!id || !search.supervisorId) {
      showErrorModal({
        showConfirmButton: false,
      });
      return;
    }
    this.getOrderData(id, search.supervisorId);
  }

  isSeatOccupied = async () => {
    try {
      const { area, passType, row, side, seat } = this.state.fields;

      const queryData = { area, passType, row, side, seat };
      const query = queryString.stringify(queryData, { encode: false });

      const { data } = await axios.get(`/Order/checkSeasonPassStatus?${query}`);

      if (data) {
        showErrorModal({
          title: "This place has already been purchased",
        });
      }

      return data;
    } catch (e) {
      console.log(e);
    }
  };

  checkIsSelectDisabled = (name) => {
    const {
      fields: { area, row, passType, side },
    } = this.state;

    switch (name) {
      case "area":
        return !passType;
      case "row":
        return !passType || !area;
      case "side":
        return !passType || !area || !row;
      case "seat":
        return !passType || !area || !row || !side;
      default:
        return false;
    }
  };

  handleApproveRequest = async () => {
    try {
      const isSeatOccupied = await this.isSeatOccupied();

      if (isSeatOccupied) {
        return;
      }

      const {
        fields,
        transactionData: { orderId, supervisorId, paymentAmount },
      } = this.state;

      const body = {
        ...fields,
        amount: paymentAmount,
      };

      await axios.put(
        `Order/${orderId}/request-changes/approve?supervisorAccessToken=${supervisorId}`,
        body
      );
      this.showApproveRequestModal();
    } catch (e) {
      console.log(e);
      showErrorModal();
    }
  };

  getInfoFieldText = (name) => {
    const value = this.state.fields[name];
    const initialValue = this.state.initialFields.fields[name];

    switch (true) {
      case !value:
        return (
          <span className="seasonpass__field-text-required">
            Required Field
          </span>
        );
      case value !== initialValue:
        return (
          <span className="seasonpass__field-text-required">Field Changed</span>
        );
      default:
        return null;
    }
  };

  render() {
    const {
      fields: {
        area,
        buyerEmail,
        fullName,
        passType,
        phoneNumber,
        row,
        seat,
        side,
      },
      transactionData,
      initialFields: { fields: initialFields },
      total,
    } = this.state;

    return (
      <Container className="seasonpass approve-request">
        <div className="seasonpass__wrapper">
          <div className="seasonpass__header-logo-wrapper">
            <img src={SeasonPassLogo} alt="Logo" />
          </div>
          <div className="seasonpass__header">
            <h2 className="seasonpass__header-title">Buy Season Pass</h2>
          </div>
          <form ref={this.formRef} method="post" className="seasonpass__form">
            <label className="seasonpass__field">
              <span className="seasonpass__field-text">
                <span className="seasonpass__field-text-label">Full Name</span>
                {this.getInfoFieldText("fullName")}
              </span>
              <span className="approve-request__initial-value-text">
                {initialFields.fullName}
              </span>
              <input
                className={"seasonpass__field-input"}
                name="fullName"
                onChange={(e) => this.handleChange("fullName", e.target.value)}
                type="text"
                value={fullName}
              />
            </label>

            <div className="seasonpass__grouped-fields">
              <label className="seasonpass__field">
                <span className="seasonpass__field-text">
                  <span className="seasonpass__field-text-label">
                    Phone Number
                  </span>
                  {this.getInfoFieldText("phoneNumber")}
                </span>
                <span className="approve-request__initial-value-text">
                  {initialFields.phoneNumber}
                </span>
                <input
                  className="seasonpass__field-input"
                  type="text"
                  name="phoneNumber"
                  onChange={(e) =>
                    this.handleChange("phoneNumber", e.target.value)
                  }
                  value={phoneNumber}
                />
              </label>

              <label className="seasonpass__field">
                <span className="seasonpass__field-text">
                  <span className="seasonpass__field-text-label">
                    Buyer Email
                  </span>
                  {this.getInfoFieldText("buyerEmail")}
                </span>
                <span className="approve-request__initial-value-text">
                  {initialFields.buyerEmail}
                </span>
                <input
                  className="seasonpass__field-input"
                  name="buyerEmail"
                  onChange={(e) =>
                    this.handleChange("buyerEmail", e.target.value)
                  }
                  type="text"
                  value={buyerEmail}
                />
              </label>
            </div>

            <label className="seasonpass__field">
              <span className="seasonpass__field-text">
                <span className="seasonpass__field-text-label">
                  Season Pass Type
                </span>
                {this.getInfoFieldText("passType")}
              </span>
              <span className="approve-request__initial-value-text">
                {initialFields.passType}
              </span>
              <AsyncSelect
                cacheOptions
                defaultOptions
                loadOptions={handleSelectPlace("passType")}
                name="passType"
                value={{ label: passType, value: passType }}
                isDisabled={this.checkIsSelectDisabled("passType")}
                onChange={(value) => this.handleChange("passType", value)}
                classNamePrefix="seasonpass__select"
                className="seasonpass__select"
              />
            </label>

            <div className="seasonpass__grouped-fields seasonpass__grouped-fields_selects">
              <label className="seasonpass__field">
                <span className="seasonpass__field-text">
                  <span className="seasonpass__field-text-label">Area</span>
                  {this.getInfoFieldText("area")}
                </span>
                <span className="approve-request__initial-value-text">
                  {initialFields.area}
                </span>
                <AsyncSelect
                  cacheOptions
                  defaultOptions
                  loadOptions={handleSelectPlace("area")}
                  value={{ label: area, value: area }}
                  name="area"
                  isDisabled={this.checkIsSelectDisabled("area")}
                  onChange={(value) => this.handleChange("area", value.value)}
                  classNamePrefix="seasonpass__select"
                  className="seasonpass__select"
                />
              </label>

              <label className="seasonpass__field">
                <span className="seasonpass__field-text">
                  <span className="seasonpass__field-text-label">Row</span>
                  {this.getInfoFieldText("row")}
                </span>
                <span className="approve-request__initial-value-text">
                  {initialFields.row}
                </span>
                <AsyncSelect
                  cacheOptions
                  defaultOptions
                  value={{ label: row, value: row }}
                  loadOptions={handleSelectPlace("row")}
                  isDisabled={this.checkIsSelectDisabled("row")}
                  onChange={(value) => this.handleChange("row", value.value)}
                  classNamePrefix="seasonpass__select"
                  className="seasonpass__select"
                />
              </label>

              <label className="seasonpass__field">
                <span className="seasonpass__field-text">
                  <span className="seasonpass__field-text-label">Side</span>
                  {this.getInfoFieldText("side")}
                </span>
                <span className="approve-request__initial-value-text">
                  {initialFields.side}
                </span>
                <AsyncSelect
                  cacheOptions
                  defaultOptions
                  loadOptions={handleSelectPlace("side")}
                  value={{ label: side, value: side }}
                  isDisabled={this.checkIsSelectDisabled("side")}
                  onChange={(value) => this.handleChange("side", value.value)}
                  classNamePrefix="seasonpass__select"
                  className="seasonpass__select"
                />
              </label>

              <label className="seasonpass__field">
                <span className="seasonpass__field-text">
                  <span className="seasonpass__field-text-label">Seat</span>
                  {this.getInfoFieldText("seat")}
                </span>
                <span className="approve-request__initial-value-text">
                  {initialFields.seat}
                </span>
                <AsyncSelect
                  cacheOptions
                  defaultOptions
                  loadOptions={handleSelectPlace("seat")}
                  value={{ label: seat, value: seat }}
                  isDisabled={this.checkIsSelectDisabled("seat")}
                  onChange={(value) => this.handleChange("seat", value.value)}
                  classNamePrefix="seasonpass__select"
                  className="seasonpass__select"
                />
              </label>
            </div>

            <div className="seasonpass__result-wrapper">
              <button
                type="button"
                className="btn-primary seasonpass__btn seasonpass__btn_request-changes"
                disabled={this.checkIsSubbmitDisabled()}
                onClick={this.showConfirmationModal}
              >
                Approve
              </button>

              {renderTotal({
                currencyCode: transactionData.currencyCode,
                price: transactionData.paymentAmount,
                total,
              })}
            </div>
          </form>
        </div>
      </Container>
    );
  }
}

export default withRouter(ApproveRequest);
