import React, { Component } from "react";
import { withRouter } from "react-router";

import swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";

import axios from "../../../axios-instance";

import Container from "../../../components/UI/Container/Container";

import SellerInfoModal from "../SellerInfoModal/SellerInfoModal";
import { showErrorModal } from "../../../modals";

import SeasonPassLogo from "../../../images/season-pass-logo.svg";
import Warning from "../../../images/warning.svg";
import PleaseWait from "../../../images/please-wait-season-pass-modal.svg";

import "../SeasonPass.sass";
import "./PartizanOnlineSeller.sass";

const MySwal = withReactContent(swal);

class PartizanOnlineSeller extends Component {
  state = {
    fields: {
      fullName: "",
      phoneNumber: "",
      buyerEmail: "",
      currency: "RSD",
      seats: [
        {
          passType: "",
          area: "",
          row: "",
          seat: "",
          side: "",
          amount: "",
          membershipCardPrice: "0",
        },
      ],
    },
    submittBlocked: false,
  };

  formRef = React.createRef();

  showSellerInfoModal = () => {
    MySwal.fire({
      html: (
        <SellerInfoModal
          onClose={() => {
            MySwal.close();
          }}
        />
      ),
      allowEscapeKey: false,
      customClass: "seasonpass__modal",
      allowOutsideClick: false,
      allowEnterKey: false,
      showConfirmButton: false,
    });
  };

  showConfirmationModal = () => {
    MySwal.fire({
      imageUrl: Warning,
      title: "Confirm Sending the Request",
      text: "Please note that this is an irreversible action",
      cancelButtonText: "Cancel",
      confirmButtonText: "Confirm",
      allowEscapeKey: false,
      customClass: "seasonpass__modal",
      allowOutsideClick: false,
      allowEnterKey: false,
      showConfirmButton: true,
      showCancelButton: true,
      reverseButtons: true,
    }).then((result) => {
      if (result.value) {
        this.handleSubmit();
      }
    });
  };

  showPleaseWaitModal = () => {
    MySwal.fire({
      imageUrl: PleaseWait,
      title: "Please wait",
      allowEscapeKey: false,
      customClass: "seasonpass__modal",
      allowOutsideClick: false,
      allowEnterKey: false,
      showConfirmButton: false,
    });
  };

  handleChange = (name, value) => {
    if (name) {
      this.setState({
        fields: { ...this.state.fields, [name]: value },
      });
    }
  };

  handleSeatsFieldsChange = (name, index, value) => {
    const seats = [...this.state.fields.seats];

    seats[index][name] = value;

    this.setState({
      fields: { ...this.state.fields, seats },
    });
  };

  handleSeatsActiion = (type, index) => {
    switch (type) {
      case "add":
        this.setState({
          fields: {
            ...this.state.fields,
            seats: [
              ...this.state.fields.seats,
              {
                passType: "",
                area: "",
                row: "",
                seat: "",
                side: "",
                amount: "",
                membershipCardPrice: "0",
              },
            ],
          },
        });
        break;
      case "remove":
        const seats = this.state.fields.seats;

        this.setState({
          fields: {
            ...this.state.fields,
            seats: seats.slice(0, index).concat(seats.slice(index + 1)),
          },
        });
        break;
      default:
        break;
    }
  };

  checkIsSubbmitDisabled = () => {
    const {
      fields: { seats, ...otherFields },
      submittBlocked,
    } = this.state;

    let isRequiredFieldEmpty = !Object.keys(otherFields).every(
      (key) => !!otherFields[key]
    );

    let isSeatsFieldsEmpty = !seats.every((data) =>
      Object.keys(data).every((key) => !!data[key])
    );

    return isSeatsFieldsEmpty || isRequiredFieldEmpty || submittBlocked;
  };

  getSellerInfoFromLocalStorage = () =>
    JSON.parse(localStorage.getItem("sellerInfo"));

  isSellerInfoFieldEnpty = () => {
    const sellerInfo = this.getSellerInfoFromLocalStorage();

    if (!sellerInfo) return true;

    return !sellerInfo.sellerPin || !sellerInfo.sellerName;
  };

  componentDidMount() {
    const isSellerInfoFieldEnpty = this.isSellerInfoFieldEnpty();

    if (isSellerInfoFieldEnpty) {
      this.showSellerInfoModal();
    }
  }

  handleSubmit = async () => {
    const isSellerInfoFieldEnpty = this.isSellerInfoFieldEnpty();

    if (isSellerInfoFieldEnpty) {
      this.showSellerInfoModal();
      return;
    }

    try {
      this.showPleaseWaitModal();
      const { fields } = this.state;
      const sellerInfo = this.getSellerInfoFromLocalStorage();

      const body = {
        order: {
          ...fields,
          sellerPin: sellerInfo.sellerPin,
          sellerName: sellerInfo.sellerName,
          processingFeeRate: "3.1",
        },
        seats: fields.seats,
      };

      await axios.post("Order/createVip", body);
      window.location.reload();
    } catch (e) {
      showErrorModal();
      console.log(e);
    }
  };

  render() {
    const {
      fields: { buyerEmail, fullName, phoneNumber, seats },
    } = this.state;

    return (
      <Container className="seasonpass partizan-online-seller">
        <div className="seasonpass__wrapper">
          <div className="seasonpass__header-logo-wrapper">
            <img src={SeasonPassLogo} alt="Logo" />
          </div>
          <div className="seasonpass__header">
            <h2 className="seasonpass__header-title">Buy Season Pass</h2>
          </div>

          <form ref={this.formRef} className="seasonpass__form">
            <label className="seasonpass__field">
              <span className="seasonpass__field-text">
                <span className="seasonpass__field-text-label">Full Name</span>
                {!fullName && (
                  <span className="seasonpass__field-text-required">
                    Required Field
                  </span>
                )}
              </span>
              <input
                className={"seasonpass__field-input"}
                name="fullName"
                onChange={(e) => this.handleChange("fullName", e.target.value)}
                type="text"
                value={fullName}
              />
            </label>

            <div className="seasonpass__grouped-fields">
              <label className="seasonpass__field">
                <span className="seasonpass__field-text">
                  <span className="seasonpass__field-text-label">
                    Phone Number
                  </span>
                  {!phoneNumber && (
                    <span className="seasonpass__field-text-required">
                      Required Field
                    </span>
                  )}
                </span>
                <input
                  className="seasonpass__field-input"
                  type="text"
                  name="phoneNumber"
                  onChange={(e) =>
                    this.handleChange("phoneNumber", e.target.value)
                  }
                  value={phoneNumber}
                />
              </label>

              <label className="seasonpass__field">
                <span className="seasonpass__field-text">
                  <span className="seasonpass__field-text-label">
                    Buyer Email
                  </span>
                  {!buyerEmail && (
                    <span className="seasonpass__field-text-required">
                      Required Field
                    </span>
                  )}
                </span>
                <input
                  className="seasonpass__field-input"
                  name="buyerEmail"
                  onChange={(e) =>
                    this.handleChange("buyerEmail", e.target.value)
                  }
                  type="text"
                  value={buyerEmail}
                />
              </label>
            </div>

            <div className="partizan-online-seller__seat-wrapper">
              {seats.map(
                (
                  {
                    passType,
                    area,
                    row,
                    seat,
                    side,
                    amount,
                    membershipCardPrice,
                  },
                  index
                ) => (
                  <div key={index} className="partizan-online-seller__seat">
                    <div className="seasonpass__grouped-fields">
                      <label className="seasonpass__field">
                        <span className="seasonpass__field-text">
                          <span className="seasonpass__field-text-label">
                            Pass Type
                          </span>
                          {!passType && (
                            <span className="seasonpass__field-text-required">
                              Required Field
                            </span>
                          )}
                        </span>
                        <input
                          className="seasonpass__field-input"
                          type="text"
                          name={"passType" + index}
                          onChange={(e) =>
                            this.handleSeatsFieldsChange(
                              "passType",
                              index,
                              e.target.value
                            )
                          }
                          value={passType}
                        />
                      </label>

                      <label className="seasonpass__field">
                        <span className="seasonpass__field-text">
                          <span className="seasonpass__field-text-label">
                            Price
                          </span>
                          {!amount && (
                            <span className="seasonpass__field-text-required">
                              Required Field
                            </span>
                          )}
                        </span>
                        <input
                          className="seasonpass__field-input"
                          type="text"
                          name={"amount" + index}
                          onChange={(e) =>
                            this.handleSeatsFieldsChange(
                              "amount",
                              index,
                              e.target.value
                            )
                          }
                          value={amount}
                        />
                      </label>

                      <label className="seasonpass__field">
                        <span className="seasonpass__field-text">
                          <span className="seasonpass__field-text-label">
                            Membership Price
                          </span>
                          {!membershipCardPrice && (
                            <span className="seasonpass__field-text-required">
                              Required Field
                            </span>
                          )}
                        </span>
                        <input
                          className="seasonpass__field-input"
                          type="text"
                          name={"membershipCardPrice" + index}
                          onChange={(e) =>
                            this.handleSeatsFieldsChange(
                              "membershipCardPrice",
                              index,
                              e.target.value
                            )
                          }
                          value={membershipCardPrice}
                        />
                      </label>
                    </div>

                    <div className="seasonpass__grouped-fields">
                      <label className="seasonpass__field">
                        <span className="seasonpass__field-text">
                          <span className="seasonpass__field-text-label">
                            Area
                          </span>
                          {!area && (
                            <span className="seasonpass__field-text-required">
                              Required Field
                            </span>
                          )}
                        </span>
                        <input
                          className="seasonpass__field-input"
                          type="text"
                          name={"area" + index}
                          onChange={(e) =>
                            this.handleSeatsFieldsChange(
                              "area",
                              index,
                              e.target.value
                            )
                          }
                          value={area}
                        />
                      </label>

                      <label className="seasonpass__field">
                        <span className="seasonpass__field-text">
                          <span className="seasonpass__field-text-label">
                            Row
                          </span>
                          {!row && (
                            <span className="seasonpass__field-text-required">
                              Required Field
                            </span>
                          )}
                        </span>
                        <input
                          className="seasonpass__field-input"
                          type="text"
                          name={"row" + index}
                          onChange={(e) =>
                            this.handleSeatsFieldsChange(
                              "row",
                              index,
                              e.target.value
                            )
                          }
                          value={row}
                        />
                      </label>

                      <label className="seasonpass__field">
                        <span className="seasonpass__field-text">
                          <span className="seasonpass__field-text-label">
                            Side
                          </span>
                          {!side && (
                            <span className="seasonpass__field-text-required">
                              Required Field
                            </span>
                          )}
                        </span>
                        <input
                          className="seasonpass__field-input"
                          type="text"
                          name={"side" + index}
                          onChange={(e) =>
                            this.handleSeatsFieldsChange(
                              "side",
                              index,
                              e.target.value
                            )
                          }
                          value={side}
                        />
                      </label>

                      <label className="seasonpass__field">
                        <span className="seasonpass__field-text">
                          <span className="seasonpass__field-text-label">
                            Seat
                          </span>
                          {!seat && (
                            <span className="seasonpass__field-text-required">
                              Required Field
                            </span>
                          )}
                        </span>
                        <input
                          className="seasonpass__field-input"
                          type="text"
                          name={"seat" + index}
                          onChange={(e) =>
                            this.handleSeatsFieldsChange(
                              "seat",
                              index,
                              e.target.value
                            )
                          }
                          value={seat}
                        />
                      </label>
                    </div>

                    {seats.length > 1 && (
                      <button
                        type="button"
                        onClick={() => this.handleSeatsActiion("remove", index)}
                        className="btn-secondary partizan-online-seller__ticket-btn"
                      >
                        Remove Ticket
                      </button>
                    )}
                  </div>
                )
              )}
              <button
                type="button"
                onClick={() => this.handleSeatsActiion("add")}
                className="btn-primary seasonpass__btn partizan-online-seller__ticket-btn"
              >
                Add Ticket
              </button>
            </div>

            <div className="seasonpass__result-wrapper">
              <button
                type="button"
                onClick={this.showConfirmationModal}
                className="btn-primary seasonpass__btn"
                disabled={this.checkIsSubbmitDisabled()}
              >
                Send
              </button>
            </div>
          </form>
        </div>
      </Container>
    );
  }
}

export default withRouter(PartizanOnlineSeller);
