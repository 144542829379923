import React from "react";
import Swal from "sweetalert2";
import { toast } from "react-toastify";
import withReactContent from "sweetalert2-react-content";

import axios from "../../../axios-instance";
import { getSeatDescription } from "../../../helpers/getSeatDescription";
import { getHeaders } from "../../../helpers/getHeaders";

const MySwal = withReactContent(Swal);

export const ConfirmRefundTicketsModal = ({ token, seats }) => {
  const refundTicketsHandler = () => {
    const idsSeats = seats.map((seat) => seat.id);

    axios
      .post(`/ManageTransactions/Refund`, idsSeats, {
        headers: getHeaders(token),
      })
      .then((response) => {
        if (response.status === 200) {
          if (response.data) {
            toast.success("Tickets successfully refunded");
            MySwal.close();
          } else {
            toast.error("Tickets not refunded");
          }
          return;
        }

        throw new Error(response.data.errorMessage);
      })
      .catch((err) => {
        toast.error("Tickets not refunded");
      });
  };

  const closeModalHandler = (e) => {
    MySwal.close();
  };

  return (
    <>
      <div className="seats-list">
        <span>Tickets that will be refunded:</span>
        {seats.map((seat) => (
          <span key={seat.id}>{getSeatDescription(seat)}</span>
        ))}
      </div>
      <div className="swal2-actions">
        <button
          className="swal2-cancel swal2-styled"
          onClick={closeModalHandler}
        >
          Cancel
        </button>
        <button
          className="swal2-confirm swal2-styled"
          onClick={refundTicketsHandler}
        >
          Refund
        </button>
      </div>
    </>
  );
};
