import * as actionTypes from '../actionTypes/scanteamsTypes';
import axios from '../../axios-instance';
import { toast } from 'react-toastify';
import { getHeaders } from "../../helpers/getHeaders";

const getScanteamsStart = () => ({ type: actionTypes.GET_SCANTEAMS_START });
const getScanteamsSuccess = (scanteams) => ({
  type: actionTypes.GET_SCANTEAMS_SUCCESS,
  payload: scanteams,
});
const getScanteamsFail = (error) => ({
  type: actionTypes.GET_SCANTEAMS_FAIL,
  error,
});

export const getScanteams = (userToken, skip, take) => (dispatch) => {
  dispatch(getScanteamsStart());
  axios
    .get('/Team', {
      headers: getHeaders(userToken),
      params: { skip, take },
    })
    .then((response) => {
      if (response.data.isSuccess) {
        return response.data.result;
      }
      throw new Error(response.data.errorMessage);
    })
    .then((result) => {
      dispatch(getScanteamsSuccess(result));
    })
    .catch((err) => {
      dispatch(getScanteamsFail(err));
    });
};

const getScanteamStart = () => ({ type: actionTypes.GET_SCANTEAM_START });
const getScanteamSuccess = (scanteams) => ({
  type: actionTypes.GET_SCANTEAM_SUCCESS,
  payload: scanteams,
});
const getScanteamFail = (error) => ({
  type: actionTypes.GET_SCANTEAM_FAIL,
  error,
});

export const getScanteam = (userToken, id) => (dispatch) => {
  dispatch(getScanteamStart());
  return axios
    .get(`/Team/${id}`, {
      headers: getHeaders(userToken),
    })
    .then((response) => {
      if (response.data.isSuccess) {
        return response.data.result;
      }
      throw new Error(response.data.errorMessage);
    })
    .then((result) => {
      dispatch(getScanteamSuccess(result));
      return result;
    })
    .catch((err) => {
      dispatch(getScanteamFail(err));
    });
};

const deleteScanTeamStart = () => ({ type: actionTypes.DELETE_SCANTEAM_START });
const deleteScanTeamSuccess = () => ({
  type: actionTypes.DELETE_SCANTEAM_SUCCESS,
});
const deleteScanTeamFail = () => ({ type: actionTypes.DELETE_SCANTEAM_FAIL });

export const deleteScanTeam = (token, teamId) => (dispatch) => {
  dispatch(deleteScanTeamStart());
  return axios
    .delete(`/Team/${teamId}`, { headers: getHeaders(token) })
    .then((response) => {
      if (response.data.isSuccess) {
        return response.data;
      }
      throw new Error(response.data.errorMessage);
    })
    .then((result) => {
      dispatch(deleteScanTeamSuccess(result));
      toast.success('Your scanteam was successfully deleted.');
      return result;
    })
    .catch((err) => {
      dispatch(deleteScanTeamFail(err));
    });
};

const getAllEventsStart = () => ({ type: actionTypes.GET_ALL_EVENTS_START });
const getAllEventsSuccess = (scanteams) => ({
  type: actionTypes.GET_ALL_EVENTS_SUCCESS,
  payload: scanteams,
});
const getAllEventsFail = (error) => ({
  type: actionTypes.GET_ALL_EVENTS_FAIL,
  error,
});

export const getAllEvents =
  (token, teamId = 0, skip = 0, take = 0) =>
  (dispatch) => {
    dispatch(getAllEventsStart());
    return axios
      .get('/Team/Events', {
        headers: getHeaders(token),
        params: { skip, take, teamId },
      })
      .then((response) => {
        if (response.data.isSuccess) {
          return response.data.result;
        }
        throw new Error(response.data.errorMessage);
      })
      .then((result) => {
        dispatch(getAllEventsSuccess(result));
        return result;
      })
      .catch((err) => {
        dispatch(getAllEventsFail(err));
      });
  };

const createScanteamStart = () => ({ type: actionTypes.CREATE_SCANTEAM_START });
const createScanteamSuccess = () => ({
  type: actionTypes.CREATE_SCANTEAM_SUCCESS,
});
const createScanteamFail = () => ({ type: actionTypes.CREATE_SCANTEAM_FAIL });

export const createScanteam = (token, data) => (dispatch) => {
  dispatch(createScanteamStart());
  return axios
    .post('/Team', data, { headers: getHeaders(token) })
    .then((response) => {
      if (response.data.isSuccess) {
        return response.data.result;
      }
      throw new Error(response.data.errorMessage);
    })
    .then((result) => {
      dispatch(createScanteamSuccess());
      toast.success('Your scanteam was successfully created.');
      return result;
    })
    .catch((err) => {
      dispatch(createScanteamFail());
    });
};

const updateScanteamStart = () => ({ type: actionTypes.UPDATE_SCANTEAM_START });
const updateScanteamSuccess = () => ({
  type: actionTypes.UPDATE_SCANTEAM_SUCCESS,
});
const updateScanteamFail = () => ({ type: actionTypes.UPDATE_SCANTEAM_FAIL });

export const updateScanteam = (token, data, id) => (dispatch) => {
  dispatch(updateScanteamStart());
  return axios
    .put('/Team/' + id, data, { headers: getHeaders(token) })
    .then((response) => {
      if (response.data.isSuccess) {
        return response.data.result;
      }
      throw new Error(response.data.errorMessage);
    })
    .then((result) => {
      dispatch(updateScanteamSuccess());
      toast.success('Your scanteam was successfully edited.');
      return true;
    })
    .catch((err) => {
      dispatch(updateScanteamFail(err));
    });
};

const refreshAccessStart = () => ({ type: actionTypes.REFRESH_ACCESS_START });
const refreshAccessSuccess = (refreshAccessScanteam) => ({
  type: actionTypes.REFRESH_ACCESS_SUCCESS,
  payload: refreshAccessScanteam,
});
const refreshAccessFail = () => ({ type: actionTypes.REFRESH_ACCESS_FAIL });

export const refreshAccess = (userToken, scanteamId) => (dispatch) => {
  dispatch(refreshAccessStart());
  return axios
    .put(
      `/Team/RefreshAccess/${scanteamId}`,
      {},
      { headers: getHeaders(userToken) }
    )
    .then((response) => {
      if (response.data.isSuccess) {
        return response.data.result;
      }
      throw new Error(response.data.errorMessage);
    })
    .then((result) => {
      dispatch(refreshAccessSuccess());
      return result;
    })
    .catch((err) => {
      dispatch(refreshAccessFail(err));
    });
};

const getUserInfoSettingsStart = () => ({
  type: actionTypes.GET_USER_INFO_SETTINGS_START,
});
const getUserInfoSettingsSuccess = (userSettings) => ({
  type: actionTypes.GET_USER_INFO_SETTINGS_SUCCESS,
  payload: userSettings,
});
const getUserInfoSettingsFail = () => ({
  type: actionTypes.GET_USER_INFO_SETTINGS_FAIL,
});

export const getUserInfoSettings = (userToken, scanteamId) => (dispatch) => {
  dispatch(getUserInfoSettingsStart());

  return axios
    .get(`team/${scanteamId}/configurations`, {
      headers: getHeaders(userToken),
    })
    .then((response) => {
      if (response.data.isSuccess) {
        dispatch(getUserInfoSettingsSuccess(response.data.result));
        return response.data.result;
      }
      throw new Error(response.data.errorMesage);
    })
    .catch((err) => {
      dispatch(getUserInfoSettingsFail(err));
    });
};

const createUserInfoSettingsStart = () => ({
  type: actionTypes.CREATE_USER_INFO_SETTINGS_START,
});
const createUserInfoSettingsSuccess = (userSettings) => ({
  type: actionTypes.CREATE_USER_INFO_SETTINGS_SUCCESS,
  payload: userSettings,
});
const createUserInfoSettingsFail = () => ({
  type: actionTypes.CREATE_USER_INFO_SETTINGS_FAIL,
});

export const createUserInfoSettings =
  (userToken, data, scanteamId) => (dispatch) => {
    dispatch(createUserInfoSettingsStart());
    if (typeof data === 'string') {
      return axios
        .post(
          `team/${scanteamId}/configurations`,
          { title: data },
          { headers: getHeaders(userToken) }
        )
        .then((response) => {
          if (response.data.isSuccess) {
            toast.success('User field has been added');
            dispatch(createUserInfoSettingsSuccess(response.data.result));
            return response.data.result;
          }
          throw new Error(response.data.errorMesage);
        })
        .catch((err) => {
          dispatch(createUserInfoSettingsFail(err));
        });
    } else {
      return Promise.allSettled(
        data.map((field) =>
          axios.post(
            `team/${scanteamId}/configurations`,
            { title: field.title },
            { headers: getHeaders(userToken) }
          )
        )
      ).then((result) => {
        const successResult = result.filter(
          (item) => item.status === 'fulfilled'
        );
        const failedResult = result.filter(
          (item) => item.status === 'rejected'
        );
        successResult.length === result.length &&
          toast.success(
            `User field${successResult.length > 1 ? 's' : ''} has been added`
          );
        failedResult.length === result.length &&
          toast.error(
            `User field${failedResult.length > 1 ? 's' : ''} has not been added`
          );
        failedResult.length && failedResult.length !== result.length &&
          toast.warn(
            `Some user field${
              failedResult.length > 1 ? 's' : ''
            } has not been added`
          );

        successResult.map((item) =>
          dispatch(createUserInfoSettingsSuccess(item.value.data.result))
        );
        failedResult && dispatch(createUserInfoSettingsFail());
      });
    }
  };

const deleteUserInfoSettingsStart = () => ({
  type: actionTypes.DELETE_USER_INFO_SETTINGS_START,
});
const deleteUserInfoSettingsSuccess = (deletedUserSetting) => ({
  type: actionTypes.DELETE_USER_INFO_SETTINGS_SUCCESS,
  payload: deletedUserSetting,
});
const deleteUserInfoSettingsFail = () => ({
  type: actionTypes.DELETE_USER_INFO_SETTINGS_FAIL,
});

export const deleteUserInfoSettings =
  (userToken, teamConfigurationId, scanteamId) => (dispatch) => {
    dispatch(deleteUserInfoSettingsStart());

    return axios
      .delete(`team/${scanteamId}/configurations/${teamConfigurationId}`, {
        headers: getHeaders(userToken),
      })
      .then((response) => {
        if (response.data.isSuccess) {
          toast.success('User field has been deleted');
          dispatch(deleteUserInfoSettingsSuccess(response.data.result));
          return response.data.result;
        }
        throw new Error(response.data.errorMesage);
      })
      .catch((err) => {
        dispatch(deleteUserInfoSettingsFail(err));
      });
  };
