import React, { Component } from "react";
import { withRouter } from "react-router";
import swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import queryString from "query-string";
import moment from "moment";
import axios from "../../axios-instance";

import Container from "../../components/UI/Container/Container";
import Icon from "../../components/UI/Icon/Icon";
import { Stepper } from "../../components/Stepper/Stepper";
import GuestModal from "./GuestModal/GuestModal";

import {
  FillFields,
  SelectTickets,
} from "../SeasonPass/PartizanOpenSelling/Steps";
import { ChoosePaymentMethod } from "./Steps/ChoosePaymentMethod";

import ROUTES from "../../constants/routes";
import { bankLogos, fee } from "../SeasonPass/constants";
import { currencies } from "../../constants/currencies";

import { getTotal } from "../SeasonPass/helpers";
import { getHeaders } from "../../helpers/getHeaders";
import { filterUnavailableTicketTemplates } from "../../helpers/filterUnavailableTicketTemplates";
import {
  mapSeat,
  mapSeatIo,
} from "../SeasonPass/PartizanOpenSelling/PartizanOpenSelling";
import { showInfoModal, showErrorModal } from "../../modals";

import PleaseWait from "../../images/please-wait-season-pass-modal.svg";
import ThanksForYourPurchase from "../../images/thanks-for-your-purchase.svg";
import PartizanOpenSellingLogo from "../../images/partizan-open-selling-logo.svg";

import "../SeasonPass/PartizanOpenSelling/PartizanOpenSelling.sass";
import "./Sell.sass";
import { isValidEmail } from "../../helpers/validators";

const MySwal = withReactContent(swal);

const initialState = {
  fields: {
    fullName: "",
    physicalAddress: "",
    city: "",
    postalCode: "",
    phoneNumber: "",
    buyerEmail: "",
    birthday: null,
    gender: "",
    emailTemplate: null,
    generationTitle: "",
    companyName: "",
    paymentType: "",
    companyVatNumber: "",
  },
  tickets: [],
  transactionData: {
    orderId: "",
    paymentAmount: "",
    currencyCode: "RSD",
  },
  eventData: {
    ticketTemplates: [],
  },
  step: 1,
  // UI color for page
  themeColor: "#6071B5",
  hasSeats: undefined,
  feeRate: fee,
  loading: true,
  submittBlocked: false,
};

class Sell extends Component {
  state = initialState;

  formRef = React.createRef();

  handleaChangeStep = (nextStep) => {
    this.setState({ step: nextStep });
  };

  setOrderDataToState = (data) => {
    const fields = {
      ...this.state.fields,
      fullName: data.fullName,
      physicalAddress: data.physicalAddress,
      city: data.city,
      postalCode: data.postalCode,
      phoneNumber: data.phoneNumber,
      buyerEmail: data.buyerEmail,
      birthday: data.birthday ? new Date(data.birthday) : null,
      gender: data.gender,
      companyName: data.companyName,
      companyVatNumber: data.companyVatNumber,
    };

    this.setState({
      fields,
    });
  };

  componentWillUnmount() {
    MySwal.close();
  }

  showPleaseWaitModal = () => {
    MySwal.fire({
      imageUrl: PleaseWait,
      title: "Please wait",
      text: "You will be redirected to the payment page",
      allowEscapeKey: false,
      customClass: "seasonpass__modal partizan-info-modal",
      allowOutsideClick: false,
      allowEnterKey: false,
      showConfirmButton: false,
    });
  };

  getOrderData = async () => {
    try {
      const { data } = await axios.get(
        `Transaction/${this.props.match.params.id}`
      );
      if (data) {
        this.handleOrderStatuses(data);
        this.setOrderDataToState(data);
      }
    } catch (e) {
      showErrorModal({
        text: `It seems to have failed to complete the payment, please try again`,
        showConfirmButton: false,
        customClass:
          "seasonpass__modal_info seasonpass__modal partizan-info-modal",
      });
      console.log(e);
    }
  };

  setPrice = () => {
    const { tickets, hasSeats } = this.state;
    let price = 0;
    if (!hasSeats) {
      price = tickets.reduce((accumulator, ticket) => {
        return accumulator + ticket.price * ticket.count;
      }, 0);
    } else {
      price = tickets.reduce((accumulator, ticket) => {
        return accumulator + +ticket.pricing.price;
      }, 0);
    }
    this.setTransactionData({
      paymentAmount: price,
    });
  };

  handleOrderStatuses = (data) => {
    const link = data.ticketResultGuid ? (
      <a
        className="sell__info-modal-link"
        target="__blank"
        href={`${process.env.API_URL}/Transaction/Ticket/${data.ticketResultGuid}?usedCached=true`}
      >
        Generated Pdfs
      </a>
    ) : (
      ""
    );

    const additionalText = data.ticketResultGuid
      ? `(Payment Status: ${
          data.additionalPaymentStatus
        }, Payment Date: ${moment(data.paymentDate).format(
          "DD MMMM YYYY HH:mm"
        )}, Transaction Id: ${data.transactionId})`
      : "";

    showInfoModal({
      onConfirm: (result) => {
        if (result) {
          const eventId = this.props.match.params.event_id;
          this.props.history.push({
            pathname: ROUTES.SELL.replace(":event_id", eventId).replace(
              ":id?",
              ""
            ),
          });
          window.location.reload();
        }
      },
      link,
      image: ThanksForYourPurchase,
      title: "Thanks for Your Purchase!",
      text: `We will send all the details to your inbox (${data.buyerEmail}) and look forward to meeting you`,
      customClass:
        "seasonpass__modal_info seasonpass__modal partizan-info-modal",
      confirmButtonText: "Buy More",
      showConfirmButton: true,
      additionalText,
    });
  };

  setTransactionData = (data) => {
    const paymentAmount = data.paymentAmount;

    const orderId = data.orderId || this.state.transactionData.orderId || "";

    const transactionData = {
      ...this.state.transactionData,
      ...data,
      orderId,
    };

    if (paymentAmount) {
      transactionData.paymentAmount = getTotal(
        paymentAmount,
        this.state.feeRate
      );
    }

    this.setState({
      transactionData,
    });
  };

  showGuestModal = (ticketTemplateIds, generationTitle, ticketTemplates) => {
    MySwal.fire({
      html: (
        <GuestModal
          title={"Thanks for Your Purchase!"}
          ticketTemplateIds={ticketTemplateIds}
          generationTitle={generationTitle}
          ticketTemplates={ticketTemplates || []}
        />
      ),
      width: "900px",
      customClass:
        "seasonpass__modal_info seasonpass__modal partizan-info-modal",
      allowEscapeKey: false,
      padding: "0px 0px 48px 0px",
      allowOutsideClick: false,
      allowEnterKey: false,
      confirmButtonText: "Buy More",
      showConfirmButton: true,
    }).then(() => {
      const eventId = this.props.match.params.event_id;
      this.props.history.push({
        pathname: ROUTES.SELL.replace(":event_id", eventId).replace(":id?", ""),
      });
      window.location.reload();
    });
  };

  checkGuestParams = () => {
    const { ticketTemplateIds, generationTitle } = queryString.parse(
      this.props.location.search
    );
    const { eventData } = this.state;

    if (!ticketTemplateIds || !generationTitle) {
      return false;
    }

    const parsedTicketTemplateIds = atob(ticketTemplateIds);
    const parsedGenerationTitle = atob(generationTitle);

    this.showGuestModal(
      parsedTicketTemplateIds.split(","),
      parsedGenerationTitle,
      eventData.ticketTemplates
    );

    return true;
  };

  guestCreate = (seats = []) => {
    const { generationTitle } = this.state.fields;
    const eventId = this.props.match.params.event_id;

    const uniqueIds = new Set();

    const ticketTemplateIds = seats
      .filter((item) => {
        if (!uniqueIds.has(item.ticketTemplateId)) {
          uniqueIds.add(item.ticketTemplateId);
          return true;
        }
        return false;
      })
      .map((item) => item.ticketTemplateId)
      .join(",");

    const params = {
      ticketTemplateIds: btoa(ticketTemplateIds),
      generationTitle: btoa(generationTitle),
    };

    const queryParams = queryString.stringify(params, { encode: false });

    this.props.history.push({
      pathname: `${ROUTES.SELL.replace(":event_id", eventId).replace(
        ":id?",
        ""
      )}?${queryParams}`,
    });

    this.checkGuestParams();
  };

  createOrder = async () => {
    const {
      fields: {
        paymentType,
        birthday,
        emailTemplate,
        generationTitle,
        ...otherFields
      },
      transactionData,
      tickets,
      eventData,
      hasSeats,
    } = this.state;

    let seats = hasSeats
      ? tickets.map((ticket) => mapSeatIo({ ...ticket, eventData }))
      : tickets.reduce(mapSeat, []);

    const eventId = this.props.match.params.event_id;

    const body = {
      transaction: {
        ...otherFields,
        currency: transactionData.currencyCode,
        amount: transactionData.paymentAmount,
        birthday: birthday ? birthday : undefined,
        seats,
        paymentType: paymentType,
      },
    };

    const user = JSON.parse(localStorage.getItem("user"));

    const params = {
      emailTemplateName: emailTemplate ? emailTemplate.value : undefined,
      generationTitle,
    };

    const queryParams = queryString.stringify(params, { encode: false });

    const { data: id } = await axios.post(
      `ManageTransactions/CreateAndSell?${queryParams}`,
      body,
      {
        headers: getHeaders(user.token),
      }
    );

    if (paymentType === 4) {
      this.guestCreate(seats);
      return;
    }

    this.setTransactionData({
      orderId: id,
    });

    this.props.history.push({
      pathname: ROUTES.SELL.replace(":event_id", eventId).replace(":id?", id),
    });
  };

  handleSubmit = async () => {
    try {
      this.showPleaseWaitModal();
      await this.createOrder();
      if (this.state.fields.paymentType !== 4) window.location.reload();
    } catch (e) {
      console.log(e);
      showErrorModal({
        text: `It seems to have failed to complete the payment, please try again`,
        customClass:
          "seasonpass__modal_info seasonpass__modal partizan-info-modal",
      });
    }
  };

  handleChange = (name, value) => {
    if (name) {
      this.setState({ fields: { ...this.state.fields, [name]: value } });
    }
  };

  checkActionButtonDisabled = () => {
    const {
      step,
      fields: { buyerEmail, paymentType, emailTemplate, generationTitle },
      submittBlocked,
      tickets,
      loading,
    } = this.state;

    if (buyerEmail && !isValidEmail(buyerEmail)) return true;

    if (submittBlocked || loading) return true;

    switch (step) {
      case 1:
        return !tickets.length;
      case 3:
        if (paymentType === 1) {
          return !emailTemplate || !emailTemplate.value || !paymentType;
        }
        if (paymentType === 4) {
          return !generationTitle || !paymentType;
        }
        return !paymentType;
      case 2:
        return !buyerEmail;
      default:
        return false;
    }
  };

  onObjectSelected = (obj) => {
    const { hasSeats } = this.state;
    if (!hasSeats) {
      let findedTicket = this.state.tickets.find(
        ({ ticketTemplateId }) => ticketTemplateId === obj.ticketTemplateId
      );

      if (findedTicket) {
        this.setState(
          {
            tickets: this.state.tickets.map((ticket) =>
              ticket.ticketTemplateId === obj.ticketTemplateId ? obj : ticket
            ),
          },
          () => this.setPrice()
        );
        return;
      }
    }
    if (hasSeats && !obj.pricing) {
      return;
    }
    const tickets = [...this.state.tickets, obj];

    this.setState({ tickets }, () => this.setPrice());
  };

  onObjectDeselected = (obj) => {
    const { hasSeats } = this.state;
    if (!hasSeats) {
      this.setState(
        {
          tickets: this.state.tickets.filter(
            (item) => item.ticketTemplateId !== obj.ticketTemplateId
          ),
        },
        () => this.setPrice()
      );
      return;
    }

    this.setState(
      {
        tickets: this.state.tickets.filter((item) => item.id !== obj.id),
      },
      () => this.setPrice()
    );
  };

  getEventData = async () => {
    try {
      const eventId = this.props.match.params.event_id;
      if (!eventId) throw Error();

      document.documentElement.style.setProperty(
        "--partizan-theme",
        this.state.themeColor
      );

      const { data } = await axios.get(`Transaction/Event/${eventId}`);

      data.ticketTemplates = filterUnavailableTicketTemplates(
        data.ticketTemplates
      );

      this.setState({
        transactionData: {
          ...this.state.transactionData,
          currencyCode: currencies.find(
            ({ value }) => value === data.currencyId
          ).label,
        },
        hasSeats: data.ticketTemplates.length
          ? data.ticketTemplates[0].hasSeats
          : undefined,
        feeRate: data.feeRate || fee,
        eventData: data,
      });
    } catch (e) {
      showErrorModal({
        text: `It seems to have failed to complete the payment, please try again`,
        showConfirmButton: false,
        customClass:
          "seasonpass__modal_info seasonpass__modal partizan-info-modal",
      });
      console.log(e);
    }
  };

  callAPIs = async () => {
    const orderId = this.props.match.params.id;
    this.setState({ loading: true });

    await this.getEventData();

    if (this.checkGuestParams()) {
      return;
    }

    if (orderId) await this.getOrderData();

    this.setState({ loading: false });
  };

  componentDidMount() {
    this.callAPIs();
  }

  render() {
    const {
      step,
      fields,
      tickets,
      transactionData,
      eventData,
      loading,
      hasSeats,
      feeRate,
    } = this.state;

    const time = moment(eventData.startDate).format("DD.MM.YYYY HH:mm");

    const pricing = eventData.ticketTemplates.map(({ categoryKey, price }) => ({
      category: categoryKey,
      price,
    }));

    const prefixLabel = eventData.prefixLabel || "";

    const location = eventData.place || "";

    const steps = [
      {
        title: "Select Tickets",
        component: (
          <SelectTickets
            maxSelectedObjects={100}
            feeRate={feeRate}
            tickets={tickets}
            ticketTemplates={eventData.ticketTemplates}
            pricing={pricing}
            hasSeats={hasSeats}
            workspaceKey={eventData.hideSeatsIoWorkspaceId}
            eventSeatsId={eventData.hideSeatsIoEventId}
            event={eventData}
            loading={loading}
            currency={transactionData.currencyCode}
            onObjectSelected={this.onObjectSelected}
            onObjectDeselected={this.onObjectDeselected}
            total={transactionData.paymentAmount}
          />
        ),
      },
      {
        title: "Fill in the info",
        component: (
          <FillFields
            fields={fields}
            requiredFields={["buyerEmail"]}
            shouldCheckCheckboxes={false}
            handleChange={this.handleChange}
            showTermsAndConditionsModal={this.showTermsAndConditionsModal}
            handleChangeCheckbox={this.handleChangeCheckbox}
          />
        ),
      },
      {
        title: "Buy tickets",
        component: (
          <ChoosePaymentMethod
            feeRate={feeRate}
            fields={fields}
            hasSeats={hasSeats}
            currency={transactionData.currencyCode}
            email={fields.buyerEmail}
            tickets={tickets}
            total={transactionData.paymentAmount}
            paymentType={fields.paymentType}
            handleChange={this.handleChange}
            allowEnterCouponCode={false}
          />
        ),
      },
    ];

    const headerComponent = (
      <div>
        <h2 className="partizan-open-selling__stepper-header-event-name">
          {prefixLabel}
        </h2>
        <h2 className="partizan-open-selling__stepper-header-title">
          {eventData.eventName}
        </h2>
        <h5 className="partizan-open-selling__stepper-header-subtitle">
          Supported by Fancee tickets
        </h5>
        <div className="partizan-open-selling__stepper-header-info">
          <div className="partizan-open-selling__stepper-header-info-location">
            <Icon name={"map-pin-solid"} />
            <p>{location}</p>
          </div>
          <p className="partizan-open-selling__stepper-header-info-time">
            {time}
          </p>
        </div>
      </div>
    );

    const actionComponent = (
      <div className="partizan-open-selling__stepper-action">
        <div className="partizan-open-selling__stepper-action-btns">
          <button
            className="btn-secondary"
            type="button"
            disabled={step === 1}
            onClick={() => this.handleaChangeStep(step - 1)}
          >
            Previous
          </button>
          {steps.length === step && (
            <button
              className="btn-primary"
              type="button"
              disabled={this.checkActionButtonDisabled()}
              onClick={this.handleSubmit}
            >
              Create
            </button>
          )}
          {steps.length !== step && (
            <button
              className="btn-primary"
              type="button"
              disabled={this.checkActionButtonDisabled()}
              onClick={() => this.handleaChangeStep(step + 1)}
            >
              Next
            </button>
          )}
        </div>
        <div className="seasonpass__bank-logos">
          {bankLogos.map(({ alt, src, link }) => (
            <div key={alt}>
              <a target="_blank" rel="noopener noreferrer" href={link}>
                <img style={{ height: 30 }} src={src} alt={link} />
              </a>
            </div>
          ))}
        </div>
      </div>
    );

    return (
      <Container className="partizan-open-selling sell">
        <form ref={this.formRef} className="partizan-open-selling__wrapper">
          <div className="partizan-open-selling__logo seasonpass__header-logo-wrapper">
            <img src={PartizanOpenSellingLogo} alt="Logo" />
            <h2>Fancee Tickets</h2>
          </div>
          <Stepper
            headerBgStyle={{
              backgroundImage: `url(${eventData.imageUrl})`,
            }}
            headerWrapperClass="partizan-open-selling__stepper-header"
            headerComponent={headerComponent}
            actionComponent={actionComponent}
            currentStep={step}
            steps={steps}
          />
        </form>
      </Container>
    );
  }
}

export default withRouter(Sell);
