import React from 'react';

import './SeasonPassPersonalInfo.sass';
import styles from './SeasonPassPersonalInfo.module.sass';
import Icon from '../../../components/UI/Icon/Icon';

import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import { EditMembershipCardInfoModal } from './Modals/EditMembershipCardInfo';
import { seasonPassPersonalInfoLangs } from '../../../constants/seasonPassPersonalInfoLangs';
import { BuySeasonPassModal } from './Modals/BuySeasonPassModal';
import { StatusLabel } from '../../../components/Transactions/StatusLabel/StatusLabel';

import axios from "../../../axios-instance";
import { paymentStatuses } from '../../../constants/paymentStatuses';
import NewLoader from '../../../components/NewLoader/NewLoader';

const MySwal = withReactContent(Swal);

export const MembershipCard = ({ onSave, membershipCard, currencyLabel, onConfirmBuySeasonPass, availablePoints, eventInfo, langCode }) => {
    const txt = seasonPassPersonalInfoLangs[langCode];

    const hasSeat = membershipCard.seat != null;
    const isLoading = ('loading' in membershipCard)
    const allowEdit = !membershipCard.hasSeasonPass && !isLoading;

    const showEditMembershipCardInfo = () => {
        if (!allowEdit) {
            return;
        }

        MySwal.fire({
            title: txt.EditMembershipCardInfoModalTxt['title'],
            html: (
                <EditMembershipCardInfoModal
                    membershipCard={membershipCard}
                    editMembeshipCardInfo={onSave}
                    eventInfo={eventInfo}
                    langCode={langCode} />
            ),
            showConfirmButton: false,
            customClass: "public-user-modal",
        });
    };

    const showBySeasonPassModal = () => {
        if (membershipCard.hasSeasonPass) {
            return;
        }

        const requests = [];

        const getSeatLabel = (ticket) => {
            return ticket.area + (!!ticket.side ? `-${ticket.side}` : '') + `-${ticket.row}-${ticket.seat}`
        }

        requests.push(axios.get(`TicketTemplate/${process.env.REACT_APP_NEW_SEASON_EVENT_ID}/${getSeatLabel(membershipCard)}?hasSide=${!!membershipCard.side}`),
            axios.get(`Transaction/Event/BySlug/${process.env.REACT_APP_NEW_SEASON_EVENT_SLUG}`));

        Promise.all(requests)
            .then((responses) => {
                MySwal.fire({
                    title: txt.BuySeasonPassModalTxt['title'],
                    html: (
                        <BuySeasonPassModal
                            membershipCard={membershipCard}
                            langCode={langCode}
                            ticketTemplate={responses[0].data}
                            availablePoints={availablePoints}
                            onConfirmBuySeasonPass={onConfirmBuySeasonPass}
                            event={responses[1].data}
                        />
                    ),
                    showConfirmButton: false,
                    customClass: "public-user-modal",
                });
            })
    }


    return <>
        <li className={styles['membership-card']}>

            <div className={styles['membership-card-edit'] + (allowEdit ? (' ' + styles['active']) : '')}
                onClick={showEditMembershipCardInfo}>

                <div className={styles['ticket-and-price']}>

                    <p className="partizan-open-selling__text-normal-small">{membershipCard.ticketName}</p>

                    <p className={styles['price-label'] + " partizan-open-selling__text-bold"}>{membershipCard.ticketPrice} {currencyLabel}</p>

                </div>

                {membershipCard.customerName &&
                    <p>{txt.SeasonPassListTxt['for']} {membershipCard.customerName}</p>
                }

                <div className={styles['footer']}>

                    {hasSeat &&
                        <p className={styles['seat-label']}><strong>{membershipCard.area} {
                            membershipCard.side === 'L' && 'Left'
                            || membershipCard.side === 'R' && 'Right'
                            || membershipCard.side === 'M' && 'Center'
                            || ''
                        } {membershipCard.row} {membershipCard.seat}</strong></p>}

                    {membershipCard.hasNFCCard &&
                        <StatusLabel className={styles['edit-icon']}
                            text='+ NFC Card'
                            statusValue={paymentStatuses[5].value} />}

                    {allowEdit &&
                        <Icon className={styles['edit-icon']} name='edit' />}

                </div>

                {isLoading &&
                    <NewLoader className={styles['loader']} />
                }

            </div>

            {membershipCard.hasSeasonPass ? <p className={styles['season-pass-buyed-label']}>{txt.SeasonPassListTxt['seasonPassPurchased']}</p>
                :
                <button disabled={(hasSeat && membershipCard.customerName) ? undefined : true} className='btn-primary' onClick={showBySeasonPassModal}>{txt.SeasonPassListTxt['buySeasonPass']}</button>
            }
        </li>
    </>
}

export const MembershipCardContainer = ({ onSave, membershipCards, currencyLabel, onConfirmBuySeasonPass, availablePoints, eventInfo, langCode }) => {
    return <>
        <ul className={styles['membership-cards-container']}>

            {membershipCards.map(m =>
                <MembershipCard
                    key={m.id}
                    onSave={onSave}
                    membershipCard={m}
                    currencyLabel={currencyLabel}
                    onConfirmBuySeasonPass={onConfirmBuySeasonPass}
                    availablePoints={availablePoints}
                    eventInfo={eventInfo}
                    langCode={langCode} />)}

        </ul>
    </>
}